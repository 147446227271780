import { Component, OnInit } from '@angular/core'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'
import { B2CConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { TranslateService } from '@ngx-translate/core'
@Component({
  selector: 'customer-portal-app-footer-new',
  templateUrl: './footer-new.component.html',
  styleUrls: ['./footer-new.component.scss']
})
export class FooterNewComponent implements OnInit {
  constructor(
    private readonly translateService: TranslateService,
    public transifexService: TransifexService,
    private readonly remoteConfigService: EfRemoteConfigurationService<B2CConfig>
  ) {
    this.countryName = this.remoteConfigService.get('country.name')
    this.language = this.translateService.getDefaultLang()
    this.defaultLanguage = this.remoteConfigService.get('b2c.defaultLanguage')
  }

  public countryName
  private readonly logo = 'web/assets/images/img_logo_white.svg'
  public logoUrl = ''
  public language
  public defaultLanguage

  ngOnInit(): void {
    this.logoUrl = `${location.origin}/${this.logo}`
  }

  getImpressumLink(): void {
    const links = {
      de: this.remoteConfigService.get('footerLinks.impressumLinks.de'),
      it: this.remoteConfigService.get('footerLinks.impressumLinks.it'),
      fr: this.remoteConfigService.get('footerLinks.impressumLinks.fr')
    }
    return this.getLink(links, this.language, this.defaultLanguage)
  }

  getDataProtectionLink(): void {
    const links = {
      de: this.remoteConfigService.get('footerLinks.dataProtectionLinks.de'),
      it: this.remoteConfigService.get('footerLinks.dataProtectionLinks.it'),
      fr: this.remoteConfigService.get('footerLinks.dataProtectionLinks.fr')
    }
    return this.getLink(links, this.language, this.defaultLanguage)
  }

  getLink(linkObj, primaryLang, defaultLanguage) {
    if (
      this.checkIfExists(primaryLang) &&
      this.checkIfExists(linkObj[primaryLang.toLowerCase()])
    ) {
      return linkObj[primaryLang.toLowerCase()]
    }
    return linkObj[defaultLanguage.toLowerCase()] ?? ''
  }

  checkIfExists(text: string): boolean {
    if (
      text &&
      text !== null &&
      text !== undefined &&
      text !== '' &&
      text !== ' '
    ) {
      return true
    }
    return false
  }

  openUrl() {
    const url: string = this.remoteConfigService.get('home.url')
    window.open(url, '_blank')
  }
}
