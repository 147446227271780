import { Inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Environment } from '../environments'
import { ENVIRONMENT } from '../injection-tokens'
import { JsonApiResponse, JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { JsonApiRequest, JsonApiRequestData } from '../models/jsonApiRequest.model'
import { com, hr } from 'efd-cdb_backend-interfaces-ts'

type CdbDealerMinDTO = com.mocira.inside.cdb.client.dto.CdbDealerMinDTO
type CdbDealerGroupCntDTO = hr.emilfreydigital.cdb.dto.CdbDealerGroupCntDTO
type DealerGroupCntExtendedDTO = hr.emilfreydigital.cdb.dto.CdbDealerGroupCntExtendedDTO
type CdbDealerGroupDTO = hr.emilfreydigital.cdb.dto.CdbDealerGroupDTO
type SubdealerGroupDTO = hr.emilfreydigital.cdb.dto.CdbSubdealerGroupDTO

@Injectable({ providedIn: 'root' })

export class DealerGroupsService {
  private readonly apiUrl: string

  constructor (
    private readonly http: HttpClient,
    @Inject(ENVIRONMENT) private readonly environment: Environment
  ) {
    this.apiUrl = this.environment.baseUrl
  }

  getDealerGroups (params, groupType?: string): Observable<JsonApiResponseList<CdbDealerGroupCntDTO>> {
    let parameters = new HttpParams()
    if (groupType !== null) parameters = parameters.append('filter[type]', groupType)
    if (params.size) parameters = parameters.append('page[size]', params.size)
    if (params.number) parameters = parameters.append('page[number]', params.number)
    if (params.search) parameters = parameters.append('search', params.search)
    if (params.sort) parameters = parameters.append('sort', params.sort)

    return this.http.get<JsonApiResponseList<CdbDealerGroupCntDTO>>(
      `${this.apiUrl}/cdb/dealer-groups`, { params: parameters }
    )
  }

  createDealerGroup (data: CdbDealerGroupDTO): Observable<JsonApiResponseList<CdbDealerGroupDTO>> {
    const requestData = new JsonApiRequest(new JsonApiRequestData(data))

    return this.http.post<JsonApiResponseList<CdbDealerGroupDTO>>(
      `${this.apiUrl}/cdb/dealer-groups`, requestData
    )
  }

  updateDealerGroup (data: CdbDealerGroupDTO): Observable<JsonApiResponseList<CdbDealerGroupDTO>> {
    const requestData = new JsonApiRequest(new JsonApiRequestData(data))

    return this.http.put<JsonApiResponseList<CdbDealerGroupDTO>>(
      `${this.apiUrl}/cdb/dealer-groups`, requestData
    )
  }

  updateDealerGroupSubdealers (data: SubdealerGroupDTO): Observable<JsonApiResponseList<SubdealerGroupDTO>> {
    const requestData = new JsonApiRequest(new JsonApiRequestData(data))

    return this.http.post<JsonApiResponseList<SubdealerGroupDTO>>(
      `${this.apiUrl}/cdb/dealer-groups/subdealers`, requestData
    )
  }

  getDealersListByGroupId (
    groupId: number,
    params,
    onlyMainDealers?: boolean,
    mainDealerId?: number,
    omitEnabledDealersOnly = false
  ): Observable<JsonApiResponseList<CdbDealerMinDTO>> {
    let parameters = new HttpParams()
    // parameters = parameters.append('filter[externalId][IsNotNULL]', '') // only dealers with externalId

    if (mainDealerId !== undefined) {
      parameters = parameters.append('filter[relationType]', 'filiale')
      parameters = parameters.append('filter[type]', 'system')
      parameters = parameters.append('filter[relationPrimaryDealerId][EQ]', mainDealerId)
    }
    parameters = parameters.append('filter[type][IsNULL]', '') // only dealerships

    if (omitEnabledDealersOnly === false) {
      parameters = parameters.append('filter[enabled]', true) // only enabled dealers
    }

    if (onlyMainDealers !== undefined && onlyMainDealers === true) parameters = parameters.append('filter[relationDealerId][IsNULL]', '')
    if (groupId !== null) parameters = parameters.append('filter[groupId][IN]', groupId)
    if (params?.size) parameters = parameters.append('page[size]', params.size)
    if (params?.number) parameters = parameters.append('page[number]', params.number)
    if (params?.search) parameters = parameters.append('search', params.search)
    if (params?.sort) parameters = parameters.append('sort', params.sort)
    if (params?.externalIdNotNull) parameters = parameters.append('filter[externalId][IsNotNULL]', '')

    return this.http.get<JsonApiResponseList<CdbDealerMinDTO>>(
      `${this.apiUrl}/cdb/dealer`, { params: parameters }
    )
  }

  getDealerGroupById (groupId: number): Observable<CdbDealerGroupDTO> {
    return this.http.get<JsonApiResponse<CdbDealerGroupDTO>>(
      `${this.apiUrl}/cdb/dealer-groups/${groupId}`
    ).pipe(map(res => res?.data?.attributes))
  }

  getDealerGroupDetailsById (groupId: number): Observable<DealerGroupCntExtendedDTO> {
    return this.http.get<JsonApiResponse<DealerGroupCntExtendedDTO>>(
      `${this.apiUrl}/cdb/dealer-groups/details/${groupId}`
    ).pipe(map((res: JsonApiResponse<DealerGroupCntExtendedDTO>) => res?.data?.attributes))
  }

  deleteDealerGroupById (groupId: number): Observable<CdbDealerGroupDTO> {
    return this.http.delete<CdbDealerGroupDTO>(
      `${this.apiUrl}/cdb/dealer-groups/${groupId}`
    )
  }
}
