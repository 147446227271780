import { Injectable } from '@angular/core'
import { ReplaySubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  constructor() {}

  public title$: ReplaySubject<string> = new ReplaySubject(1)
  public title: string

  public setTitle(title: string) {
    this.title = title
    this.title$.next(title)
  }
}
