import { Component, OnInit, Input } from '@angular/core'
import { CptSharedDataService } from '../../services/kc-shared-data.service'
import { interval, Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { SharedService } from '../../services/shared.service'

@Component({
  selector: 'customer-portal-lib-jumbo',
  templateUrl: './jumbo.component.html',
  styleUrls: ['./jumbo.component.scss']
})
export class JumboComponent implements OnInit {
  private readonly sub: Subscription
  currentLang: string

  constructor(
    private readonly sharedData: CptSharedDataService,
    private readonly translate: TranslateService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>,
    private readonly sharedService: SharedService
  ) {
    this.sub = interval(10000).subscribe(() => {
      this.changeText()
    })
    this.country = this.remoteConfigService.get('country.code') ?? ''
  }

  private readonly background_de = 'assets/images/background_login.jpg'
  public backgroundUrl = ''
  public country: string

  public selectedIndex = 0

  @Input('texts')
  public tabTexts: string[]

  ngOnInit(): void {
    this.setBackground()
    this.selectedIndex = 0
    this.translate.onLangChange.subscribe(() => {
      this.setBackground()
    })
  }

  setBackground(): void {
    this.currentLang = this.sharedService.currentLanguage()
    let asset
    if (this.country === 'ch') {
      asset =
        this.currentLang === 'de'
          ? 'assets/images/landing_page_de.jpg'
          : this.currentLang === 'it'
          ? 'assets/images/landing_page_it.jpg'
          : 'assets/images/landing_page_fr.jpg'
    } else {
      asset = this.background_de
    }
    this.backgroundUrl = `url('${this.sharedData.getResourcesPath()}/${asset}')`
  }

  public change(index: number): void {
    this.selectedIndex = index
  }

  public changeText(): void {
    if (this.selectedIndex < 2) {
      this.selectedIndex = this.selectedIndex + 1
    } else {
      this.selectedIndex = 0
    }
    return null
  }
}
