import { Injectable } from '@angular/core'
import { HttpHeaders } from '@angular/common/http'
import { JsonApiResponse } from '@inside-hub-app/ef-ng-json-api'
import { Observable } from 'rxjs'
import { com } from 'efd-cdb_backend-interfaces-ts'
import { BaseService } from './base.service'

type CdbDocumentDTO = com.mocira.inside.cdb.client.dto.CdbDocumentDTO

@Injectable({
  providedIn: 'root'
})
export class MediaManagementService extends BaseService {
  getImages (parameters: any): Observable<JsonApiResponse<CdbDocumentDTO>> {
    const config = {
      params: parameters
    }

    return this.http.get<JsonApiResponse<CdbDocumentDTO>>(
      `${this.apiUrl}/cdb/document/list`,
      config
    )
  }

  savePicture (imageData: any, forceSaveImage?: boolean) {
    let config
    if (forceSaveImage) config = { params: { force: true } }

    const formData: FormData = new FormData()
    formData.append('file', imageData.file, imageData.documentName)
    formData.append('documentName', imageData.documentName)
    formData.append('documentType', imageData.documentType)
    formData.append('access_read', 'public')
    if (imageData.dealerId) {
      formData.append('dealerId', imageData.dealerId)
    }
    if (imageData.userId) {
      formData.append('userId', imageData.userId)
    }
    if (imageData.brandId) {
      formData.append('brandId', imageData.brandId)
    }

    return this.http.post<JsonApiResponse<CdbDocumentDTO>>(
      `${this.apiUrl}/cdb/document/upload`,
      formData,
      config
    )
  }

  deletePicture (imageId: number): Observable<JsonApiResponse<CdbDocumentDTO>> {
    return this.http.delete<JsonApiResponse<CdbDocumentDTO>>(
      `${this.apiUrl}/cdb/document/${imageId}`
    )
  }

  getImageView (url: string) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'text/plain, */*',
      'Cache-Control': 'no-cache'
    })

    return this.http.get(`${this.apiUrl}/public/v1/content/${url}`, {
      headers,
      responseType: 'blob'
    })
  }

  setDealerLogoPicture (dealerId: number, body) {
    return this.http.post(
      `${this.apiUrl}/cdb/dealer/${dealerId}/profile/media`,
      body
    )
  }
}
