import { Injectable } from '@angular/core'
import { NGXLogger } from 'ngx-logger'
import { BehaviorSubject, Observable } from 'rxjs'
import { Params, Router } from '@angular/router'

interface Breadcrumb {
  label: string
  title: string
  label$?: Observable<string>
  url?: string
  disabled: boolean
  show: boolean
  useLocationBack?: boolean
  params?: Params
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsConfigurationService {
  private breadcrumbsSubject = new BehaviorSubject([])

  constructor (private logger: NGXLogger, private router: Router) {}

  getBreadcrumbs () {
    return this.breadcrumbsSubject.asObservable()
  }

  setBreadcrumbs (breadcrumbs: Breadcrumb[]) {
    this.breadcrumbsSubject.next(breadcrumbs)
  }
}
