import { Injectable } from '@angular/core'
import { BaseService } from './base.service'
import { Observable } from 'rxjs'
import { JsonApiResponse, JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { JsonApiRequest, JsonApiRequestData } from '../models/jsonApiRequest.model'
import type { com } from 'efd-cdb_backend-interfaces-ts'
import { HttpParams } from '@angular/common/http'

type CdbDealerTextDTO = com.mocira.inside.cdb.rest.dto.CdbDealerTextDTO
type CdbCommentDTO = com.mocira.inside.cdb.client.dto.CdbCommentDTO
type CdbDealerTermsAndConditionsDTO =
  com.mocira.inside.cdb.rest.dto.CdbDealerTermsAndConditionsDTO

export interface DocumentData {
  data: Data
  file?: File
  document?: { url: string }
  access_read?: string
  tag?: string
  brandId?: number
}

interface Data {
  type: string
  language: string
  title: string
  dealerId: number
}

enum FormDataTypes {
  DATA = 'data',
  FILE = 'file',
  ACCESS_READ = 'access_read',
  TAG = 'tag',
  BRAND_ID = 'brandId'
}

@Injectable({
  providedIn: 'root'
})

export class DealerTextsService extends BaseService {
  getDealerTexts (
    type?: string,
    dealerId?: number,
    language?: string,
    dealerGroupName?: string,
    brandId?: number
  ): Observable<JsonApiResponseList<CdbDealerTextDTO>> {
    let params = new HttpParams()
    if (type) type.split(',').forEach(item => { params = params.append('filter[type]', String(item)) })
    if (dealerId) params = params.append('filter[dealer.id]', String(dealerId))
    if (dealerGroupName) params = params.append('filter[group.name]', String(dealerGroupName))
    if (language) params = params.append('filter[language]', String(language))
    if (brandId) params = params.append('filter[brand.id]', brandId)

    return this.http.get<JsonApiResponseList<CdbDealerTextDTO>>(
      `${this.apiUrl}/cdb/dealer-texts`, { params }
    )
  }

  addDealerText (dealerText: Partial<CdbDealerTextDTO>): Observable<JsonApiResponse<CdbDealerTextDTO>> {
    const data = new JsonApiRequest(new JsonApiRequestData(dealerText))
    return this.http.post<JsonApiResponse<CdbDealerTextDTO>>(
      `${this.apiUrl}/cdb/dealer-texts`,
      data
    )
  }

  updateDealerText (dealerText: Partial<CdbDealerTextDTO>): Observable<JsonApiResponse<CdbDealerTextDTO>> {
    const data = new JsonApiRequest(
      new JsonApiRequestData(dealerText, dealerText.id)
    )
    return this.http.put<JsonApiResponse<CdbDealerTextDTO>>(
      `${this.apiUrl}/cdb/dealer-texts`,
      data
    )
  }

  deleteDealerText (dealerTextId: number): Observable<JsonApiResponse<CdbDealerTextDTO>> {
    return this.http.delete<JsonApiResponse<CdbDealerTextDTO>>(
      `${this.apiUrl}/cdb/dealer-texts/${dealerTextId}`
    )
  }

  addDealerTextv2 (documentData: DocumentData): Observable<JsonApiResponse<CdbDealerTextDTO>> {
    // const headers = new HttpHeaders({ 'Content-type': 'multipart/form-data' })
    const formData = new FormData()
    formData.append(FormDataTypes.DATA, JSON.stringify(documentData.data))
    formData.append(FormDataTypes.FILE, documentData.file)
    formData.append(FormDataTypes.ACCESS_READ, 'public')
    if (documentData.tag) {
      formData.append(FormDataTypes.TAG, documentData.tag)
    }
    documentData?.brandId && formData.append(FormDataTypes.BRAND_ID, String(documentData.brandId))

    return this.http.post<JsonApiResponse<CdbDealerTextDTO>>(`${this.apiUrl}/cdb/dealer-texts/v2`, formData)
  }

  updateDealerTextv2 (documentData): Observable<JsonApiResponse<CdbDealerTextDTO>> {
    const formData = new FormData()
    formData.append(FormDataTypes.DATA, JSON.stringify(documentData.data))
    formData.append(FormDataTypes.ACCESS_READ, 'public')
    if (documentData.tag) {
      formData.append(FormDataTypes.TAG, documentData.tag)
    }
    documentData?.brandId && formData.append(FormDataTypes.BRAND_ID, String(documentData.brandId))

    return this.http.put<JsonApiResponse<CdbDealerTextDTO>>(`${this.apiUrl}/cdb/dealer-texts/v2`, formData)
  }

  // get comments list
  getComments(parameters): Observable<JsonApiResponseList<CdbCommentDTO>> {
    const config = { params: parameters }

    return this.http.get<JsonApiResponseList<CdbCommentDTO>>(
      `${this.apiUrl}/cdb/comments/list`,
      config
    )
  }

  // create comment
  createComment(data) {
    const wrappedData = JsonApiRequest.of(data)
    return this.http.post(`${this.apiUrl}/cdb/comments/`, wrappedData)
  }

  // update document
  updateComment(data) {
    const wrappedData = JsonApiRequest.of(data)
    wrappedData.data.id = data.id
    return this.http.put(`${this.apiUrl}/cdb/comments/`, wrappedData)
  }

  // delete comment
  deleteComment(commentId: number) {
    return this.http.delete(`${this.apiUrl}/cdb/comments/${commentId}`)
  }

  getDealerTermsAndConditionsByDealerId (
    dealerId: number
  ): Observable<JsonApiResponse<CdbDealerTermsAndConditionsDTO>> {
    return this.http.get<JsonApiResponse<CdbDealerTermsAndConditionsDTO>>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/terms-and-conditions`
    )
  }

  updateTermsAndConditions (dealerText: CdbDealerTextDTO) {
    const object = new JsonApiRequest(new JsonApiRequestData(dealerText))
    return this.http.put(`${this.apiUrl}/cdb/dealer-texts`, object)
  }

  addTermsAndConditions (
    dealerId: number,
    data: any
  ): Observable<JsonApiResponse<CdbDealerTermsAndConditionsDTO>> {
    const object = new JsonApiRequest(new JsonApiRequestData(data))
    return this.http.post<JsonApiRequest<CdbDealerTermsAndConditionsDTO>>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/terms-and-conditions`,
      object
    )
  }

  deleteTermsAndConditions (termsId: number) {
    return this.http.delete(`${this.apiUrl}/cdb/dealer-texts/${termsId}`)
  }
}
