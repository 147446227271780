export enum DqMetadata {
  DEALER = 'DEALER',
  USER = 'USER',
  TOLERABLE = 'TOLERABLE',
  INTOLERABLE = 'INTOLERABLE',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  PROFILE = 'PROFILE',
  EMAIL = 'EMAIL',
  INVALID_PERSONAL_EMAIL = 'INVALID_PERSONAL_EMAIL',
  DUPLICATE_EMAIL = 'DUPLICATE_EMAIL',
  PROFILE_DATA = 'PROFILE_DATA'
}

export interface DataQualityCheckFilters {
  entityId: number
  entityType: string
  primaryReason?: string
  secondaryReason?: string
  severity?: string
  requiredAction?: string
  actionPerformed: boolean
}
