import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { DqMetadata } from '../models/data-quality.model'
import { AuthService } from '../services/auth.service'
import { PpDataQualityCheckService } from '../services/pp-data-quality-check.service'

@Injectable({
  providedIn: 'root'
})
export class DataQualityGuard  {
  constructor (protected authService: AuthService, protected router: Router, private dataQualityService: PpDataQualityCheckService) {}

  async canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const dqMetadata = await this.dataQualityService.getDqMetadataByRequiredAction(DqMetadata.UPDATE_PROFILE).toPromise()
    if (dqMetadata?.length) {
      return this.router.navigate(['/login/data-quality-check-profile'])
    }
    return true
  }
}
