import { type EfDatatableSortEvent } from './efDatatable.model'

export interface CsiParameters {
  dealerId?: number
  brandId?: number | number[]
  documentName?: string
  search?: string
  pageNumber?: number
  size?: number
  sort?: EfDatatableSortEvent
  uploadDateFrom?: string
  uploadDateTo?: string
  year?: string
  quarter?: string
}

export interface CsiFilters {
  dealerId?: number
  brandId?: number | number[]
  documentName?: string
  uploadDateFrom?: string
  uploadDateTo?: string
  year?: string
  quarter?: string
}
