import type { com } from 'efd-cdb_backend-interfaces-ts'

export type CdbUserLocationDTO = com.mocira.inside.cdb.client.dto.CdbUserLocationDTO
export type CdbUserLocationInfoDTO = com.mocira.inside.cdb.client.dto.CdbUserLocationInfoDTO
export type CdbUserLocationCandidateDTO = com.mocira.inside.cdb.client.dto.CdbUserLocationCandidateDTO
export type CdbBrandCodeDTO = com.mocira.inside.cdb.client.dto.CdbBrandCodeDTO

export type UserLocationCandidatesParams = {
  search?: string
  maxResults?: number
}

// Because of annotation Access.WriteOnly on BE, DTO is not generated with all fields
export type ExtCdbUserLocationDTO = CdbUserLocationDTO & { brands?: CdbBrandCodeDTO[] }
