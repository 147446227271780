import { Component } from '@angular/core'
import { NavigationService } from '../../../services/navigation.service'
import { NGXLogger } from 'ngx-logger'
@Component({
  selector: 'customer-portal-app-navbar-apple',
  templateUrl: './navbar-apple.component.html'
})
export class NavbarAppleComponent {
  backClicked = false
  forwardClicked = false
  constructor (
    public readonly navigationService: NavigationService,
    private readonly logger: NGXLogger
  ) {}

  backIsDisabled (): boolean {
    return !this.navigationService.hasBack() || this.backClicked === true
  }

  forwardIsDisabled (): boolean {
    return !this.navigationService.hasForward() || this.forwardClicked === true
  }

  onClickB (): void {
    if (!this.backClicked) {
      this.backClicked = true
      setTimeout(() => {
        this.backClicked = false
      }, 200)
      this.navigationService.back()
    }
  }

  onClickF (): void {
    if (!this.forwardClicked) {
      this.forwardClicked = true
      setTimeout(() => {
        this.forwardClicked = false
      }, 200)
      this.navigationService.forward()
    }
  }
}
