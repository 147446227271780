import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { RegisterService } from '@inside-hub-app/customer-portal-b2c-client'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { NGXLogger } from 'ngx-logger'

@Component({
  selector: 'customer-portal-app-register-sent-view',
  templateUrl: './register-sent-view.component.html'
})
export class RegisterSentViewComponent implements OnInit {
  constructor(
    private readonly registerService: RegisterService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly keycloak: KeycloakService,
    private readonly logger: NGXLogger,
    private readonly translate: TranslateService
  ) {
    this.route.queryParams.subscribe(value => {
      translate.use(value.lang)
    })
  }

  public authenticated = true
  public email: string
  public resendDisabled: boolean

  ngOnInit(): void {
    this.email = this.route.snapshot.params.email
    this.authenticated = this.keycloak.isLoggedIn()
  }

  public resend() {
    // enable after 1 minute
    this.resendDisabled = true
    setTimeout(() => {
      this.resendDisabled = false
    }, 60 * 1000)
  }

  public login() {
    this.keycloak.login().catch(e => {
      console.error(e)
    })
  }
}
