import { Injectable } from '@angular/core'
import { BaseService } from './base.service'

import { Observable } from 'rxjs'
import { com } from 'efd-cdb_backend-interfaces-ts'
import { DealerAttribute, DealerAttributeSave, DealerAttributeUpdate } from '../models/dealer-attributes.model'

type CdbCategorisedAttributeDTO =
  com.mocira.inside.cdb.client.dto.CdbCategorisedAttributeDTO

@Injectable({
  providedIn: 'root'
})
export class DealerAttributesService extends BaseService {
  getDealerAttributes (
    dealerId: number,
    category?: string
  ): Observable<DealerAttribute[]> {
    const params = {}
    if (category) params['category]'] = category
    const config = { params }

    return this.http.get<DealerAttribute[]>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/attributes`,
      config
    )
  }

  getDealerAttribute (
    dealerId: number,
    name: string
  ): Observable<DealerAttribute> {
    return this.http.get<DealerAttribute>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/attributes/${name}`
    )
  }

  updateDealerAttribute(dealerId: number, dealerAttribute: DealerAttribute) {
    return this.http.put<DealerAttribute>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/attributes`,
      dealerAttribute
    )
  }

  /* DEALER ATTRIBUTES UPDATED */
  getDealerAttributesNew (
    dealerId: number,
    category?: string
  ): Observable<CdbCategorisedAttributeDTO[]> {
    const params = {}
    if (category) params['category]'] = category
    const config = { params }

    return this.http.get<CdbCategorisedAttributeDTO[]>(
      `${this.apiUrl}/cdb/attributes/dealer/${dealerId}`,
      config
    )
  }

  getDealerBrandAttributes (
    dealerBrandid: number,
    category?: string
  ): Observable<CdbCategorisedAttributeDTO[]> {
    const params = {}
    if (category) params['category]'] = category
    const config = { params }

    return this.http.get<CdbCategorisedAttributeDTO[]>(
      `${this.apiUrl}/cdb/attributes/dealer-brand/${dealerBrandid}`,
      config
    )
  }

  saveDealerAttribute (
    attribute: DealerAttributeSave
  ): Observable<DealerAttributeSave> {
    return this.http.post<DealerAttributeSave>(
      `${this.apiUrl}/cdb/attributes/dealer`,
      attribute
    )
  }

  saveDealerBrandAttribute (
    attribute: DealerAttributeSave
  ): Observable<DealerAttributeSave> {
    return this.http.post<DealerAttributeSave>(
      `${this.apiUrl}/cdb/attributes/dealer-brand`,
      attribute
    )
  }

  updateDealerAttributeNew (
    attribute: DealerAttributeUpdate
  ): Observable<DealerAttributeUpdate> {
    return this.http.put<DealerAttributeUpdate>(
      `${this.apiUrl}/cdb/attributes/dealer`,
      attribute
    )
  }

  updateDealerBrandAttribute (
    attribute: DealerAttributeUpdate
  ): Observable<DealerAttributeUpdate> {
    return this.http.put<DealerAttributeUpdate>(
      `${this.apiUrl}/cdb/attributes/dealer-brand`,
      attribute
    )
  }

  deleteDealerAttribute (attributeId: number) {
    return this.http.delete(
      `${this.apiUrl}/cdb/attributes/${attributeId}/dealer`
    )
  }

  deleteDealerBrandAttribute (attributeId: number) {
    return this.http.delete(
      `${this.apiUrl}/cdb/attributes/${attributeId}/dealer-brand`
    )
  }
}
