import { Component, type OnInit } from '@angular/core'
import {
  type RegistrationSource,
  SourceService
} from '../../../services/source.service'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { type B2CConfig } from '@inside-hub-app/customer-portal-config'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'
@Component({
  selector: 'customer-portal-revolution-app-error-view',
  templateUrl: './revolution-error-view.component.html'
})
export class RevolutionErrorViewComponent implements OnInit {
  message = ''
  code = ''
  transifexKey
  transifexStr
  public registrationSource: RegistrationSource = 'WEB'
  redirectUrl
  constructor (
    public sourceService: SourceService,
    private readonly transifexService: TransifexService,
    private readonly remoteConfigService: EfRemoteConfigurationService<B2CConfig>
  ) {}

  ngOnInit (): void {
    this.code = window.history.state.code
    this.message = window.history.state.message
    this.sourceService.source$.subscribe(source => {
      this.registrationSource = source
    })

    switch (this.code) {
      case '1007':
        this.transifexStr = 'Your account has already been verified. Please log in.'
        this.transifexKey = 'customerPortal.b2c.error.code.1007'
        break
      case '1001':
        this.transifexStr = 'Verification not pending or expired!'
        this.transifexKey = 'customerPortal.b2c.error.code.1001'
        break
      case '500':
        this.transifexStr = 'An error has occurred. Please try again later.'
        this.transifexKey = 'shared.general-error-message'
        break
      default:
        this.transifexStr = 'The requested link is invalid or expired!'
        this.transifexKey = 'customerPortal.b2c.error.code.undefiend'
        break
    }

    this.setRedirectUrl()
  }

  setRedirectUrl (): void {
    if (this.registrationSource === '100_WEB') {
      const lang = this.transifexService.getCurrentLocale()?.split('_')?.[0]
      let url = this.remoteConfigService.get('100WEB.url')
      url = url + (lang ?? 'de')
      this.redirectUrl = url
    } else {
      const url = this.remoteConfigService.get('webRedirectLink')
      if (url != null && url !== '') {
        this.redirectUrl = url
      }
    }
  }

  redirectButtonClicked (): void {
    window.location.href = this.redirectUrl
  }
}
