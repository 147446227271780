import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { B2CModuleConfig } from '../b2c.module'
import { hr as cpt } from 'efd-b2c-backend-interfaces-ts'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'

export type ResetPasswordVerifyResponse =
  cpt.emilfreydigital.b2c_auth_server.rest.common.dto.VerificationDTO

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {
  constructor(
    private readonly config: B2CModuleConfig,
    private readonly http: HttpClient,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('b2c.baseUrl')
    this.apiKey = this.config.apiKey
    this.fallbackApiKey = this.remoteConfigService.get('b2c.apiKey')
  }

  private readonly apiUrl
  private readonly apiKey
  private readonly fallbackApiKey

  public verify(
    verificationId: string,
    lang: string = null
  ): Observable<ResetPasswordVerifyResponse> {
    return this.http.get<ResetPasswordVerifyResponse>(
      `${this.apiUrl}/reset-password/verify/${verificationId}?lang=${lang}`
    )
  }
}
