<div class="footer">
  <div class="container">
    <div class="logo-div">
      <a routerLink="/summary" class="logo">
        <span class="country">
          <img
            alt="ef logo"
            class="logo-img"
            [src]="logoUrl"
            (click)="openUrl()"
          />
          {{ countryName.toLowerCase() | sharedTranslate }}
        </span>
      </a>
    </div>

    <div class="links">
      <a [href]="getImpressumLink()" target="_blank" class="link">
        {{ 'impressum' | sharedTranslate }}
      </a>
      <a [href]="getDataProtectionLink()" target="_blank" class="link">
        {{ 'data-protection' | sharedTranslate }}
      </a>
    </div>
  </div>
</div>
