import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import {
  KeycloakAuthGuard,
  KeycloakService
} from '@emilfreydigital/keycloak-angular'
import { Location, PlatformLocation } from '@angular/common'
import { NGXLogger } from 'ngx-logger'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard  {
  constructor(
    protected router: Router,
    protected keycloakAngular: KeycloakService,
    protected logger: NGXLogger,
    protected location: Location,
    protected platformLocation: PlatformLocation
  ) {
    super(router, keycloakAngular)
  }

  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      if (!this.authenticated) {
        this.keycloakAngular
          .login({
            redirectUri:
              window.location.origin +
              this.location.prepareExternalUrl(state.url)
          })
          .catch(e => console.error(e))
        return resolve(false)
      }

      /*
      const requiredRoles: string[] = route.data.roles;
      if (!requiredRoles || requiredRoles.length === 0) {
        return resolve(true);
      } else {
        if (!this.roles || this.roles.length === 0) {
          resolve(false);
        }
        resolve(requiredRoles.every(role => this.roles.indexOf(role) > -1));
      } */

      resolve(true)
    })
  }
}
