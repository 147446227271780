import { CdbBrandWagesDTO } from '../services/dealer-aftersales-wages.service'
import { com } from 'efd-cdb_backend-interfaces-ts'

type CdbBrandMinDTO = com.mocira.inside.cdb.client.dto.CdbBrandMinDTO

export interface DealerAfterSalesWagesDialogData {
  brandWages?: CdbBrandWagesDTO
  possibleWageTypes: string[]
  brand?: CdbBrandMinDTO
}
