<div class="p-4 lg:p-10 flex-1 flex flex-col">
  <div class="welcome flex flex-col gap-6 py-6">
    <div class="font-efSans-secondary font-semibold text-sm uppercase">
      {{ 'customer-portal' | sharedTranslate }}
    </div>
    <div class="font-canelablack-primary text-4xl md:text-5xl">
      <T
        str="Complete your profile"
        key="customerPortal.b2c.personalize-new.title"
        tags="cpt-auth, 3.1"
      ></T>
    </div>
    <div
      class="text-mat-ef-revolution-primary-500 font-efSans text-xl font-light"
    >
      <T
        str="We ask you for additional details that will help us to provide a better and more personalized service"
        key="customerPortal.b2c.personalize-new.text"
        tags="cpt-auth, 3.1"
      ></T>
    </div>
  </div>

  <div>
    <!--   -->
    <div class="grid" *ngIf="showSuggestions">
      <mat-divider></mat-divider>
      <div class="py-4">
        <p class="font-efSans text-mat-ef-revolution-primary-500">
          {{ 'address-suggestion' | sharedTranslate }}
        </p>
        <p class="font-efSans text-mat-ef-revolution-primary-500">
          {{ 'address-validation-failed' | sharedTranslate }}
        </p>
      </div>
      <div
        class="flex justify-between items-center border rounded p-4"
        *ngIf="!addressHints"
      >
        <div>
          <span
            class="font-efSans"
            [ngClass]="
              addSug
                ? 'text-mat-ef-revolution-primary-500'
                : 'text-mat-ef-revolution-secondary-500'
            "
            >{{ addSug ? addSug : this.form.get('street').value ?? '' }}</span
          >&nbsp;
          <span
            class="font-efSans"
            [ngClass]="
              addNumberSug || addNumberSugForce
                ? 'text-mat-ef-revolution-primary-500'
                : 'text-mat-ef-revolution-secondary-500'
            "
            >{{
              addNumberSug
                ? addNumberSug
                : this.form.get('streetNumber').value ?? ''
            }}</span
          >
          <span
            class="font-efSans"
            [ngClass]="
              addOptSug
                ? 'text-mat-ef-revolution-primary-500'
                : 'text-mat-ef-revolution-secondary-500'
            "
            *ngIf="this.form.get('streetAdditional').value || addOptSug"
            ><br />{{
              addOptSug
                ? addOptSug
                : this.form.get('streetAdditional').value ?? ''
            }}</span
          >
          <br />
          <span
            class="font-efSans"
            [ngClass]="
              zipSug
                ? 'text-mat-ef-revolution-primary-500'
                : 'text-mat-ef-revolution-secondary-500'
            "
            >{{ zipSug ? zipSug : this.form.get('zip').value ?? '' }}</span
          >&nbsp;
          <span
            class="font-efSans"
            [ngClass]="
              citySug
                ? 'text-mat-ef-revolution-primary-500'
                : 'text-mat-ef-revolution-secondary-500'
            "
            >{{ citySug ? citySug : this.form.get('city').value ?? '' }}</span
          >
        </div>
        <div>
          <button
            mat-button
            mat-raised-button
            color="secondary"
            class="revolution"
            (click)="changeAddressFields()"
          >
            {{ 'fill' | sharedTranslate }}
          </button>
        </div>
      </div>
      <div *ngIf="addressHints">
        <div
          class="grid grid-cols-3 border rounded p-4 items-center"
          *ngFor="let addHint of addressHints"
        >
          <div class="col-span-3 md:col-span-2">
            <span
              class="font-efSans"
              [ngClass]="
                addSug
                  ? 'text-mat-ef-revolution-primary-500'
                  : 'text-mat-ef-revolution-secondary-500'
              "
              >{{
                addHint.street
                  ? addHint.street
                  : this.form.get('street').value ?? ''
              }}</span
            >&nbsp;
            <span
              class="font-efSans"
              [ngClass]="
                addNumberSug || addNumberSugForce
                  ? 'text-mat-ef-revolution-primary-500'
                  : 'text-mat-ef-revolution-secondary-500'
              "
              >{{
                addHint.streetNumber
                  ? addHint.streetNumber
                  : this.form.get('streetNumber').value ?? ''
              }}</span
            >
            <span
              class="font-efSans"
              [ngClass]="
                addOptSug
                  ? 'text-mat-ef-revolution-primary-500'
                  : 'text-mat-ef-revolution-secondary-500'
              "
              *ngIf="this.form.get('streetAdditional').value || addOptSug"
              ><br />{{
                addHint.streetAdditional
                  ? addHint.streetAdditional
                  : this.form.get('streetAdditional').value ?? ''
              }}</span
            >
            <br />
            <span
              class="font-efSans"
              [ngClass]="
                zipSug
                  ? 'text-mat-ef-revolution-primary-500'
                  : 'text-mat-ef-revolution-secondary-500'
              "
              >{{
                addHint.zip ? addHint.zip : this.form.get('zip').value ?? ''
              }}</span
            >&nbsp;
            <span
              class="font-efSans"
              [ngClass]="
                citySug
                  ? 'text-mat-ef-revolution-primary-500'
                  : 'text-mat-ef-revolution-secondary-500'
              "
              >{{
                addHint.city ? addHint.city : this.form.get('city').value ?? ''
              }}</span
            >
          </div>
          <div class="col-span-1">
            <button
              mat-button
              mat-raised-button
              color="secondary"
              class="revolution"
              (click)="useAddressHint(addHint)"
            >
              {{ 'fill' | sharedTranslate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- <mat-divider></mat-divider> -->

    <span class="mat-error py-4" *ngIf="errorUpdate">
      {{ 'general-error-message' | sharedTranslate }}
    </span>
    <mat-error *ngIf="forceSuggestion && !addNumberSugForce" class="text-black">
      <mat-divider></mat-divider>
      <div class="py-4">
        <T
          str="Unfortunately, the address you entered cannot be verified at the moment. Please check your entry. If the address is correct, press Continue."
          key="customerPortal.b2c.personalize-new.error.invalidAddress"
          tags="cpt-auth, 3.1"
        ></T>
      </div>
      <mat-divider></mat-divider>
    </mat-error>
    <mat-error *ngIf="forceSuggestion && addNumberSugForce" class="text-black">
      <mat-divider></mat-divider>
      <div class="py-4">
        <T
          str="The house number entered appears to be invalid. Please check your entry. If the house number is correct, press Continue."
          key="customerPortal.b2c.personalize-new.address-suggestion-street-number"
          tags="cpt-auth, 3.1"
        ></T>
      </div>
      <mat-divider></mat-divider>
    </mat-error>
    <mat-card *ngIf="duplicateUserValidationError" class="duplicate-error flex flex-row gap-6">
      <mat-icon class="error" svgIcon="error_outline"></mat-icon>
      <!-- prettier-ignore -->
      <p [innerHTML]="getTranslation()"></p>
    </mat-card>
    <p class="font-efSans text-mat-ef-revolution-primary-500 py-4">
      <T
        str="Enter your contact details"
        key="customerPortal.b2c.personalize-new.subtitle"
        tags="cpt-auth, 3.1"
      ></T>
    </p>
  </div>
  <div class="w-full lg:w-1/2 py-6 flex flex-col gap-3">
    <form
      class="grid grid-cols-1 gap-3 revolution-form"
      #personalizeNewForm
      [formGroup]="form"
      method="POST"
      [action]=""
      (ngSubmit)="updateAddress()"
    >
      <mat-form-field class="col-span-1">
        <mat-label>
          {{ 'salutation' | sharedTranslate }}
        </mat-label>
        <mat-select formControlName="salutation" required>
          <mat-option
            *ngFor="let salutation of salutations"
            [id]="salutation"
            [value]="salutation"
          >
            {{ 'salutation-' + salutation.toLowerCase() | sharedTranslate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col-span-1">
        <mat-label>
          <T
            str="Title"
            key="customerPortal.b2c.personalize-new.user.title"
            tags="cpt-auth, 3.1"
          ></T>
        </mat-label>
        <mat-select formControlName="title">
          <mat-option [id]="'unknown'" [value]="'unknown'">-</mat-option>
          <mat-option *ngFor="let title of titles" [id]="title" [value]="title">
            {{ 'title-' + title?.toLowerCase() | sharedTranslate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-span-1">
        <mat-label>
          {{ 'first-name' | sharedTranslate }}
        </mat-label>
        <input
          class="input"
          matInput
          type="text"
          required
          formControlName="firstName"
          name="firstName"
          [errorStateMatcher]="matcher"
        />
        <mat-error *ngIf="form.controls.firstName.invalid">
          {{
            transifexService.getErrorTranslation(
              form.controls.firstName,
              'customerPortal.b2c.personalize-new.first-name.error.',
              {
                num: remoteConfigService.get(
                  'regEx.nameRegEx.firstName.maxLength'
                ),
                errKey: {
                  maxlength: 'max-length-characters',
                  pattern: 'only-numbers-letters-allowed'
                }
              }
            )
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-span-1">
        <mat-label>
          {{ 'last-name' | sharedTranslate }}
        </mat-label>
        <input
          class="input"
          matInput
          type="text"
          required
          formControlName="lastName"
          name="lastName"
          [errorStateMatcher]="matcher"
        />
        <mat-error *ngIf="form.controls.lastName.invalid">
          {{
            transifexService.getErrorTranslation(
              form.controls.lastName,
              'customerPortal.b2c.personalize-new.last-name.error.',
              {
                num: remoteConfigService.get(
                  'regEx.nameRegEx.lastName.maxLength'
                ),
                errKey: {
                  maxlength: 'max-length-characters',
                  pattern: 'only-numbers-letters-allowed'
                }
              }
            )
          }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="col-span-1">
        <mat-label>
          <T
            str="Street"
            key="customerPortal.b2c.personalize-new.street"
            tags="cpt-auth, 3.1"
          ></T>
        </mat-label>
        <input
          class="input"
          matInput
          type="text"
          required
          formControlName="street"
          name="street"
          [errorStateMatcher]="matcher"
        />
        <mat-icon
          *ngIf="form.controls.street.touched && form.controls.street.invalid"
          matSuffix
          color="error"
          svgIcon="error_outline"
        ></mat-icon>
        <mat-error *ngIf="form.controls.street.invalid">
          {{
            transifexService.getErrorTranslation(
              form.controls.street,
              'customerPortal.b2c.personalize-new.street.error.',
              {
                num: remoteConfigService.get(
                  'regEx.addressRegEx.street.maxLength'
                ),
                errKey: {
                  maxlength: 'max-length-characters',
                  pattern: 'only-numbers-letters-allowed'
                }
              }
            )
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-span-1">
        <mat-label>
          {{ 'number' | sharedTranslate }}
        </mat-label>
        <input
          class="input"
          matInput
          type="number"
          required
          formControlName="streetNumber"
          name="streetNumber"
          [errorStateMatcher]="matcher"
        />
        <mat-icon
          *ngIf="
            form.controls.streetNumber.touched &&
            form.controls.streetNumber.invalid
          "
          matSuffix
          color="error"
          svgIcon="error_outline"
        ></mat-icon>
        <mat-error *ngIf="form.controls.streetNumber.invalid">
          {{ 'required-field' | sharedTranslate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-span-1">
        <mat-label>
          {{ 'address-suffix' | sharedTranslate }}
        </mat-label>
        <input
          class="input"
          matInput
          type="text"
          formControlName="streetAdditional"
          name="streetAdditional"
          [errorStateMatcher]="matcher"
        />
        <mat-icon
          *ngIf="
            form.controls.streetAdditional.touched &&
            form.controls.streetAdditional.invalid
          "
          matSuffix
          color="error"
          svgIcon="error_outline"
        ></mat-icon>
        <mat-error *ngIf="form.controls.streetAdditional.invalid">
          {{
            transifexService.getErrorTranslation(
              form.controls.streetAdditional,
              'customerPortal.b2c.personalize-new.street-additional.error.',
              {
                num: remoteConfigService.get(
                  'regEx.addressRegEx.streetAdditional.maxLength'
                ),
                errKey: {
                  maxlength: 'max-length-characters',
                  pattern: 'only-numbers-letters-allowed'
                }
              }
            )
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-span-1">
        <mat-label>
          {{ 'postcode' | sharedTranslate }}
        </mat-label>
        <input
          class="input"
          matInput
          type="number"
          required
          formControlName="zip"
          name="zip"
          [errorStateMatcher]="matcher"
        />
        <mat-icon
          *ngIf="form.controls.zip.touched && form.controls.zip.invalid"
          matSuffix
          color="error"
          svgIcon="error_outline"
        >
        </mat-icon>
        <mat-error
          *ngIf="
            form.get('zip').errors &&
            !form.get('zip').errors.hasOwnProperty('pattern')
          "
        >
          {{
            transifexService.getErrorTranslation(
              form.controls.zip,
              'customerPortal.b2c.personalize-new.zip.error.',
              {
                num: remoteConfigService.get(
                  'regEx.addressRegEx.zip.maxLength'
                ),
                errKey: {
                  minlength: 'required-length-characters',
                  maxlength: 'required-length-characters',
                  pattern: 'only-numbers-allowed'
                }
              }
            )
          }}
        </mat-error>
        <mat-error
          *ngIf="
            form.get('zip').errors &&
            form.get('zip').errors.hasOwnProperty('pattern')
          "
        >
          {{ 'invalid-number' | sharedTranslate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-span-1">
        <mat-label>
          {{ 'city' | sharedTranslate }}
        </mat-label>
        <input
          class="input"
          matInput
          type="text"
          required
          formControlName="city"
          name="city"
          [errorStateMatcher]="matcher"
        />
        <mat-icon
          *ngIf="form.controls.city.touched && form.controls.city.invalid"
          matSuffix
          color="error"
          svgIcon="error_outline"
        ></mat-icon>
        <mat-error *ngIf="form.controls.city.invalid">
          {{
            transifexService.getErrorTranslation(
              form.controls.city,
              'customerPortal.b2c.personalize-new.city.error.',
              {
                num: remoteConfigService.get(
                  'regEx.addressRegEx.city.maxLength'
                ),
                errKey: {
                  maxlength: 'max-length-characters',
                  pattern: 'only-numbers-letters-allowed'
                }
              }
            )
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-span-1" *ngIf="showTelephoneFields">
        <mat-label>
          {{ 'prefix' | sharedTranslate }}
        </mat-label>
        <mat-select formControlName="mobilePrefix" required>
          <mat-option
            *ngFor="let mobilePrefix of mobilePrefixes | keyvalue"
            [value]="'+' + mobilePrefix.value"
            >+{{ mobilePrefix.value }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-span-1" *ngIf="showTelephoneFields">
        <mat-label>
          {{ 'mobile-number' | sharedTranslate }}
        </mat-label>
        <input
          class="input"
          matInput
          type="tel"
          required
          formControlName="mobile"
          [errorStateMatcher]="matcher"
        />
        <mat-icon
          *ngIf="form.controls.mobile.touched && form.controls.mobile.invalid"
          matSuffix
          color="error"
          svgIcon="error_outline"
        ></mat-icon>
        <mat-error *ngIf="form.controls.mobile.invalid">
          {{
            transifexService.getErrorTranslation(
              form.controls.mobile,
              'customerPortal.b2c.personalize-new.mobile.error.'
            )
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-span-1">
        <mat-label>
          {{ 'date-of-birth' | sharedTranslate }}
        </mat-label>
        <input
          matInput
          formControlName="birthday"
          readonly
          (click)="picker.open()"
          required
          [matDatepicker]="picker"
          [max]="maxDate"
          [errorStateMatcher]="matcher"
        />
        <mat-datepicker-toggle
          class="calendar-size"
          matPrefix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker
          [calendarHeaderComponent]="customHeader"
          #picker
        ></mat-datepicker>
        <span (click)="picker.open()" matSuffix>
          <mat-icon svgIcon="create"></mat-icon>
        </span>
        <mat-error *ngIf="form.controls.birthday.errors">
          {{ 'required-field' | sharedTranslate }}
        </mat-error>
      </mat-form-field>

      <div class="col-span-1">
        <input type="hidden" name="accountType" value="personal" />
        <input
          type="hidden"
          name="salutation"
          [value]="form.value.salutation"
        />
        <input
          type="hidden"
          name="title"
          [value]="form.value.title || 'unknown'"
        />
        <input type="hidden" name="country" [value]="form.value.country" />
        <input type="hidden" name="language" [value]="form.value.language" />
        <input type="hidden" name="birthDate" [value]="form.value.birthday" />
        <input
          type="hidden"
          name="telephoneNumber"
          [value]="form.value.telephone"
        />
        <input type="hidden" name="force" [value]="forceSuggestion" />
        <button
          mat-button
          mat-raised-button
          class="revolution w-full button-spinner"
          color="secondary"
          type="submit"
          (click)="updateAddress()"
          [disabled]="!form.valid || disabled"
        >
          <!--              <mat-icon *ngIf="!loading">done</mat-icon>-->
          <customer-portal-ef-spinner
            [color]="'mat-ef-revolution-grey-two-500'"
            [opacity]="0.7"
            [diameter]="16"
            *ngIf="disabled"
          >
          </customer-portal-ef-spinner>
          <span>
            {{ 'next' | sharedTranslate }}
          </span>
        </button>
      </div>
    </form>
  </div>
</div>
