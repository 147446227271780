import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { JsonApiResponse } from '@inside-hub-app/ef-ng-json-api'
import { HubConfigurationService } from '@inside-hub-app/hub-configuration'
import { map, Observable } from 'rxjs'
import {
  InspectionList,
  InspectionTemplate,
  InspectionTemplateRemove
} from '../models/dealer-aftersales-tradein-check.model'

@Injectable({
  providedIn: 'root'
})
export class DealerTradeinCheckService {
  apiUrl: string

  constructor (
    private readonly http: HttpClient,
    private readonly hubConfigurationService: HubConfigurationService
  ) {
    this.apiUrl = this.hubConfigurationService.get('api.pp-url')
  }

  getInspectionList (brandId: number = null): Observable<InspectionList> {
    let params = new HttpParams()
    if (brandId) {
      params = params.append('brandId', brandId)
    }

    return this.http
      .get<JsonApiResponse<InspectionList>>(`${this.apiUrl}/cdb/inspection`, {
        params
      })
      .pipe(map(res => res?.data?.attributes))
  }

  getInspectionTemplate (templateId: number): Observable<InspectionTemplate> {
    return this.http
      .get<JsonApiResponse<InspectionTemplate>>(
        `${this.apiUrl}/cdb/inspection/${templateId}`
      )
      .pipe(map(res => res?.data?.attributes))
  }

  updateInspectionTemplate (data: any = null): Observable<InspectionTemplate> {
    return this.http
      .put<JsonApiResponse<InspectionTemplate>>(
        `${this.apiUrl}/cdb/inspection/template`,
        data
      )
      .pipe(map(res => res?.data?.attributes))
  }

  deleteInspectionTemplate (
    templateId: number
  ): Observable<InspectionTemplateRemove> {
    return this.http.delete<InspectionTemplateRemove>(
      `${this.apiUrl}/cdb/inspection/template/${templateId}`
    )
  }

  duplicateInspectionTemplate (
    templateId: number
  ): Observable<InspectionTemplate> {
    return this.http
      .put<JsonApiResponse<InspectionTemplate>>(
        `${this.apiUrl}/cdb/inspection/template/${templateId}/duplicate`,
        null
      )
      .pipe(map(res => res?.data?.attributes))
  }

  importInspectionTemplate (
    fromTemplateId: number,
    toTemplateId: number
  ): Observable<InspectionTemplate> {
    return this.http
      .put<JsonApiResponse<InspectionTemplate>>(
        `${this.apiUrl}/cdb/inspection/import/from/${fromTemplateId}/to/${toTemplateId}`,
        null
      )
      .pipe(map(res => res?.data?.attributes))
  }

  // create or edit based of if category id inside payload exist
  updateInspectionCategory (
    templateId: number,
    data: any = null
  ): Observable<InspectionTemplate> {
    return this.http
      .put<JsonApiResponse<InspectionTemplate>>(
        `${this.apiUrl}/cdb/inspection/template/${templateId}/category`,
        data
      )
      .pipe(map(res => res?.data?.attributes))
  }

  deleteInspectionCategory (
    templateId: number,
    categoryId: number
  ): Observable<InspectionTemplate> {
    return this.http
      .delete<InspectionTemplateRemove>(
        `${this.apiUrl}/cdb/inspection/template/${templateId}/category/${categoryId}`
      )
      .pipe(map(el => el.dataItem?.attributes))
  }

  // create or edit based of if category check id inside payload exist
  updateInspectionCategoryCheck (
    templateId: number,
    categoryId: number,
    data: any = null
  ): Observable<InspectionTemplate> {
    return this.http
      .put<JsonApiResponse<InspectionTemplate>>(
        `${this.apiUrl}/cdb/inspection/template/${templateId}/category/${categoryId}/check`,
        data
      )
      .pipe(map(res => res?.data?.attributes))
  }

  deleteInspectionCategoryCheck (
    templateId: number,
    categoryId: number,
    checkId: number
  ): Observable<InspectionTemplate> {
    return this.http
      .delete<InspectionTemplateRemove>(
        `${this.apiUrl}/cdb/inspection/template/${templateId}/category/${categoryId}/check/${checkId}`
      )
      .pipe(map(el => el.dataItem?.attributes))
  }
}
