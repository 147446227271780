<customer-portal-lib-jumbo
  [texts]="jumboTexts"
  [class]="class"
  [ngClass]="country"
>
</customer-portal-lib-jumbo>
<div class="md:col-span-1"></div>
<!-- <div class="md:col-span-6 min-h-[700px] flex flex-col">
  <ng-content></ng-content>
</div> -->
