export type Environment =
  | 'local_de'
  | 'dev_de'
  | 'test_de'
  | 'stage_de'
  | 'live_de'
  | 'dev_ch'
  | 'test_ch'
  | 'stage1_ch'
  | 'live_ch'
  | 'local'
