import { Component, Input } from '@angular/core'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { B2CConfig } from '@inside-hub-app/customer-portal-config'
@Component({
  selector: 'customer-portal-app-localization-menu',
  templateUrl: './localization-menu.component.html'
})
export class LocalizationMenuComponent {
  @Input() color: string
  @Input() supportedLanguages: string[]

  constructor(
    private readonly remoteConfigService: EfRemoteConfigurationService<B2CConfig>
  ) {
    this.supportedLangs = this.remoteConfigService.get(
      'b2c.supportedLanguages'
    )
  }

  public supportedLangs: string[]
}
