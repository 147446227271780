import { EfDatatableSortDirection } from '../enum/efDatatable.enum'

export interface EfDatatableSortEvent {
  column: string
  direction: EfDatatableSortDirection
}

export interface DataSourceParameters {
  pageSize: number
  pageIndex: number
  sortColumn?: string
  sortDirection?: EfDatatableSortDirection
}