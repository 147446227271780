<div class="pt-5 right-5 absolute">
  <button
    mat-button
    mat-stroked-button
    [color]="color || 'primary'"
    [matMenuTriggerFor]="localeMenu"
  >
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <mat-menu #localeMenu="matMenu">
    <a *ngFor="let lang of supportedLanguages" mat-menu-item [href]="lang">{{
      lang
    }}</a>
  </mat-menu>
</div>
