<nav id="navbar-container" [ngClass]="{
    'bg-transparent': isMobileLogin,
    'bg-white': !isMobileLogin
  }">
  <div class="flex items-center h-[60px] lg:h-[80px]">
    <div class="w-full">
      <div class="py-1 px-6 md:px-2.5">
        <div class="relative flex items-center justify-between lg:mx-auto lg:max-w-1440">
          <div class="flex items-center logo">
            <mat-icon svgIcon="ef-logo-icon" class="w-10 h-10 md:w-[52px] md:h-[52px]" [ngClass]="{
                'text-white': isMobileLogin,
                'text-mat-ef-primary-500': !isMobileLogin
              }"></mat-icon>
            <div class="text-sm pl-3 lg:text-base flex gap-1" [ngClass]="{
                'text-white': isMobileLogin,
                'text-mat-ef-revolution-secondary-500': !isMobileLogin
              }">
              <span class="font-efSans font-bold"> Emil Frey </span>
              <span class="font-efSans">
                <ng-container *ngIf="!isMobileLogin">
                  {{ countryCode | sharedTranslate }}
                </ng-container>
                <ng-container *ngIf="isMobileLogin">
                  {{ 'customer-portal' | sharedTranslate }}
                </ng-container>
              </span>
            </div>
          </div>

          <div class="hidden grow md:flex justify-center">
            <ul
              class="navbar flex text-sm lg:text-base gap-10 font-bold font-efSans text-mat-ef-revolution-primary-500">
              <li class="" *ngFor="let l of navbarLinks">
                <a [href]="getNavbarLink(l)" target="_blank">
                  {{
                  {
                  key: l.translation
                  } | transifexTranslate
                  }}
                </a>
              </li>
            </ul>
          </div>

          <div *ngIf="includeLocalizationMenu" class="flex items-center login gap-4">
            <customer-portal-app-revolution-localization
              [isMobileLogin]="isMobileLogin"></customer-portal-app-revolution-localization>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
<mat-divider *ngIf="!isMobileLogin"></mat-divider>