import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { RegisterSentViewComponent } from './views/revolution/register-sent-view/register-sent-view.component'
import { AuthGuard } from './guards/auth.guard'
import { RegisterCreatedViewComponent } from './views/revolution/register-created-view/register-created-view.component'
import { ImportSentViewComponent } from './views/revolution/import-sent-view/import-sent-view.component'
import { FastRegistrationComponent } from './views/revolution/fast-registration/fast-registration.component'
import { ConsentsViewComponent } from './views/revolution/consents-view/consents-view.component'
import { BusinessInviteVerifyViewComponent } from './views/revolution/business-invite-verify-view/business-invite-verify-view.component'
import { RequestBusinessSentViewComponent } from './views/revolution/request-business-sent-view/request-business-sent-view.component'
import { ChangeEmailVerifyViewComponent } from './views/revolution/change-email-verify-view/change-email-verify-view.component'
import { RevolutionInviteEmailVerifyViewComponent } from './views/revolution/revolution-invite-email-verify-view/revolution-invite-email-verify-view.component'
import { RevolutionErrorViewComponent } from './views/revolution/revolution-error-view/revolution-error-view.component'
import { RevolutionRegisterVerifyViewComponent } from './views/revolution/revolution-register-verify-view/revolution-register-verify-view.component'
import { RevolutionForgotPasswordVerifyViewComponent } from './views/revolution/revolution-forgot-password-verify-view/revolution-forgot-password-verify-view.component'

// register flow: register -> registered -> (email link) -> verify -> (oauth login) -> personalize -> account-created -> (redirect to app)

let routes: Routes = []

routes = [
  {
    path: 'change-email/verify/:verificationId',
    component: ChangeEmailVerifyViewComponent
  },
  { path: 'register/sent/:email', component: RegisterSentViewComponent },
  {
    path: 'request-business/sent/:email',
    component: RequestBusinessSentViewComponent
  },
  {
    path: 'register/verify/:verificationId',
    component: RevolutionRegisterVerifyViewComponent
  },
  {
    path: 'register/created',
    component: RegisterCreatedViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'invite/verify/:verificationId',
    component: RevolutionInviteEmailVerifyViewComponent
  },
  {
    path: 'business-invite/verify/:verificationId',
    component: BusinessInviteVerifyViewComponent
  },
  { path: 'import/:email', component: ImportSentViewComponent },
  {
    path: 'forgot-password/:verificationId',
    component: RevolutionForgotPasswordVerifyViewComponent
  },
  { path: 'user', component: FastRegistrationComponent },
  { path: 'user-consents', component: ConsentsViewComponent },
  { path: 'error', component: RevolutionErrorViewComponent },
  {
    path: '**',
    redirectTo: '/error'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
