import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpParams } from '@angular/common/http'
import { com } from 'efd-cdb_backend-interfaces-ts'
import CdbNotificationMessageDTO = com.mocira.inside.cdb.client.dto.CdbNotificationMessageDTO
import CdbNotificationRecipientsDTO = com.mocira.inside.cdb.client.dto.CdbNotificationRecipientsDTO
import { NotificationParameters } from '../models/notifications.model'
import { JsonApiResponse } from '@inside-hub-app/ef-ng-json-api'
import { JsonApiRequestData } from '../models/jsonApiRequest.model'
import { BaseService } from './base.service'

export interface NotificationData {
  data: JsonApiRequestData<CdbNotificationMessageDTO>
}

export interface RecipientsData {
  data: JsonApiRequestData<CdbNotificationRecipientsDTO>
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends BaseService {
  getNotifications(
    params: Partial<NotificationParameters>
  ): Observable<JsonApiResponse<CdbNotificationMessageDTO>> {
    const parameters = this.setFilterParams(params)

    return this.http.get<JsonApiResponse<CdbNotificationMessageDTO>>(
      `${this.apiUrl}/cdb/notifications/list`,
      { params: parameters }
    )
  }

  getNotification(
    id: number
  ): Observable<JsonApiResponse<CdbNotificationMessageDTO>> {
    return this.http.get<JsonApiResponse<CdbNotificationMessageDTO>>(
      `${this.apiUrl}/cdb/notifications/${id}`
    )
  }

  saveNotification(notification) {
    // NotificationData
    return this.http.post(`${this.apiUrl}/cdb/notifications`, notification)
  }

  updateNotification(notification) {
    // NotificationData
    return this.http.put(`${this.apiUrl}/cdb/notifications`, notification)
  }

  updateRoles(recipients: RecipientsData) {
    return this.http.put(`${this.apiUrl}/cdb/notifications/roles`, recipients)
  }

  updateUserGroups(recipients: RecipientsData) {
    return this.http.put(
      `${this.apiUrl}/cdb/notifications/user_groups`,
      recipients
    )
  }

  setFilterParams(params: Partial<NotificationParameters>) {
    let parameters = new HttpParams()

    if (params.size) parameters = parameters.append('page[size]', params.size)
    if (params.number)
      parameters = parameters.append('page[number]', params.number)
    if (params.search)
      parameters = parameters.append(
        'filter[title][LIKE]',
        `%${params.search}%`
      )
    if (params.sort) parameters = parameters.append('sort', params.sort)
    if (params.type) {
      params.type.forEach(type => {
        parameters = parameters.append('filter[type]', type)
      })
    }
    if (params.status?.length) {
      params.status.forEach(status => {
        parameters = parameters.append('filter[status]', status)
      })
    }

    return parameters
  }

  deleteNotification(id: number) {
    return this.http.delete(`${this.apiUrl}/cdb/notifications/${id}`)
  }
}
