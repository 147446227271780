import { Injectable } from '@angular/core'
import type { com } from 'efd-cdb_backend-interfaces-ts'
import { type Observable } from 'rxjs'
import { BaseService } from './base.service'

type CdbExternalSystemConfigDTO =
  com.mocira.inside.cdb.client.dto.CdbExternalSystemConfigDTO
type CdbExternalSystemParameterDTO =
  com.mocira.inside.cdb.client.dto.CdbExternalSystemParameterDTO
type ExternalIdDTO = com.mocira.inside.cdb.client.dto.ExternalIdDTO
type CdbPlatformDTO = com.mocira.inside.cdb.client.dto.CdbPlatformDTO

@Injectable({
  providedIn: 'root'
})
export class DealerExternalSystemConfigService extends BaseService {
  getDealerExternalSystemConfigList (
    dealerId: number
  ): Observable<CdbExternalSystemConfigDTO[]> {
    return this.http.get<CdbExternalSystemConfigDTO[]>(
      `${this.apiUrl}/cdb/dealer/external/system/config/${dealerId}/list`
    )
  }

  createDealerExternalSystemConfig (
    data: CdbExternalSystemConfigDTO
  ): Observable<CdbExternalSystemConfigDTO> {
    return this.http.post<CdbExternalSystemConfigDTO>(
      `${this.apiUrl}/cdb/dealer/external/system/config`,
      data
    )
  }

  updateDealerExternalSystemConfig (
    id: number,
    data: CdbExternalSystemConfigDTO
  ): Observable<CdbExternalSystemConfigDTO> {
    return this.http.put<CdbExternalSystemConfigDTO>(
      `${this.apiUrl}/cdb/dealer/external/system/config/${id}`,
      data
    )
  }

  testDealerExternalSystemConfig (
    data: CdbExternalSystemConfigDTO
  ): Observable<CdbExternalSystemConfigDTO> {
    return this.http.post<CdbExternalSystemConfigDTO>(
      `${this.apiUrl}/cdb/dealer/external/system/config/test`,
      data
    )
  }

  deleteDealerExternalSystemConfig (
    id: number
  ): Observable<CdbExternalSystemConfigDTO> {
    return this.http.delete<CdbExternalSystemConfigDTO>(
      `${this.apiUrl}/cdb/dealer/external/system/config/${id}`
    )
  }

  saveCredential (
    configId: number,
    data: CdbExternalSystemConfigDTO
  ): Observable<CdbExternalSystemConfigDTO> {
    return this.http.post<CdbExternalSystemConfigDTO>(
      `${this.apiUrl}/cdb/dealer/external/system/config/pass/${configId}`,
      data
    )
  }

  deleteCredential (id: number): Observable<CdbExternalSystemConfigDTO> {
    return this.http.delete<CdbExternalSystemConfigDTO>(
      `${this.apiUrl}/cdb/dealer/credential/${id}`
    )
  }

  saveExternalSystemParameter (
    externalSystemConfigId: number,
    paramsList: CdbExternalSystemParameterDTO[]
  ): Observable<CdbExternalSystemParameterDTO[]> {
    return this.http.post<CdbExternalSystemParameterDTO[]>(
      `${this.apiUrl}/cdb/dealer/external/system/parameter/${externalSystemConfigId}/bulk`,
      paramsList
    )
  }

  deleteExternalSystemParameter (paramId: number): Observable<CdbExternalSystemParameterDTO> {
    return this.http.delete<CdbExternalSystemParameterDTO>(
      `${this.apiUrl}/cdb/dealer/external/system/parameter/${paramId}`
    )
  }

  checkAvailableEfitID (externalId: ExternalIdDTO) {
    return this.http.post<Record<'available', boolean>>(
      `${this.apiUrl}/cdb/dealer/externalId/status`,
      externalId
    )
  }

  // External system config parameters
  getExternalSystemParameterList (
    externalSystemConfigId: number
  ): Observable<CdbExternalSystemParameterDTO[]> {
    return this.http.get<CdbExternalSystemParameterDTO[]>(
      `${this.apiUrl}/cdb/dealer/external/system/parameter/${externalSystemConfigId}/list`
    )
  }

  getPlatformList (): Observable<CdbPlatformDTO[]> {
    return this.http.get<CdbPlatformDTO[]>(`${this.apiUrl}/cdb/platforms`)
  }
}
