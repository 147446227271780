import { Injectable } from '@angular/core'
import { BaseService } from './base.service'
import { PpStorageEnum, PpStorageKeysEnum } from '../enum/storage.enum'
import { Observable, of } from 'rxjs'
import { tap } from 'rxjs/operators'
import { com } from 'efd-cdb_backend-interfaces-ts'

type TokenDTO = com.mocira.inside.cdb.client.dto.security.TokenDTO

@Injectable({
  providedIn: 'root'
})

export class ImpersonateUserService extends BaseService {
  _isImpersonated: boolean

  impersonateUser (userId: number, dealerId: number): Observable<TokenDTO> {
    return this.http.get<TokenDTO>(`${this.apiUrl}/cdb/users/issue-token/user/${userId}/dealer/${dealerId}`)
  }

  setUserImpersonated (value: boolean): void {
    this._isImpersonated = value
    this.storage.set(
      PpStorageEnum.PREFIX + PpStorageKeysEnum.IMPERSONATED,
      value
    ).subscribe()
  }

  isUserImpersonated (): Observable<boolean> {
    if (this._isImpersonated) {
      return of(this._isImpersonated)
    }

    return this.storage.get(
      PpStorageEnum.PREFIX + PpStorageKeysEnum.IMPERSONATED
    ).pipe(tap((isImpersonated: boolean) => {
      this._isImpersonated = isImpersonated
    }))
  }
}
