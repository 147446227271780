import { Injectable } from '@angular/core'
import { BaseService } from './base.service'
import { HttpParams } from '@angular/common/http'
import { JsonApiResponse, JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { JsonApiRequest } from '../models/jsonApiRequest.model'
import { Observable } from 'rxjs'
import { hr } from 'efd-hub-backend-interfaces-ts'

type SystemPreferenceDto = hr.emilfreydigital.inside.hub.systempreference.dto.SystemPreferenceDto

@Injectable({
  providedIn: 'root'
})
export class UserNotificationsSettingsService extends BaseService {
  updateUserNotifications (data: Partial<SystemPreferenceDto>): Observable<JsonApiResponse<SystemPreferenceDto>> {
    const payloadData = {
      module: data.module,
      component: data.component,
      name: data.name,
      value: data.value,
      userId: data.userId
    }
    const wrappedData = JsonApiRequest.of(payloadData)

    return this.http.post<JsonApiResponse<SystemPreferenceDto>>(`${this.apiHubUrl}/system-preferences/`, wrappedData, { headers: this.headers })
  }

  getUserNotificationsSettings (userId: number, moduleName: string, componentName: string): Observable<JsonApiResponseList<SystemPreferenceDto>> {
    const params = new HttpParams({
      fromObject: {
        'filter[userId]': userId.toString(),
        'filter[component]': componentName,
        'filter[module]': moduleName
      }
    })
    return this.http.get<JsonApiResponseList<SystemPreferenceDto>>(`${this.apiHubUrl}/system-preferences`, { params })
  }
}
