import { MissingTranslationHandlerParams } from '@ngx-translate/core'
import { Injectable } from '@angular/core'
import { PpMissingTranslationHandler } from '@inside-hub-app/ef-ng-pp-client'
import { NotTranslatedService } from '../services/not-translated.service'

@Injectable()
export class CPTMissingTranslationHandler extends PpMissingTranslationHandler {
  override handle(params: MissingTranslationHandlerParams) {
    const res = super.handle(params)
    this.nts.notTranslated(res)
    return res
  }
  constructor(private nts: NotTranslatedService) {
    super()
  }
}
