import { Injectable } from '@angular/core'
import { com } from 'efd-cdb_backend-interfaces-ts'
import { Observable } from 'rxjs'
import { BaseService } from './base.service'

type ExternalIdDTO = com.mocira.inside.cdb.client.dto.ExternalIdDTO
type CdbPlatformDTO = com.mocira.inside.cdb.client.dto.CdbPlatformDTO

@Injectable({
  providedIn: 'root'
})
export class ExternalIdsService extends BaseService {
  getUserExternalIds (userId: number): Observable<ExternalIdDTO[]> {
    return this.http.get<ExternalIdDTO[]>(`${this.apiUrl}/cdb/user/${userId}/external-ids`)
  }

  configureUserExternalId (userId: number, data: ExternalIdDTO): Observable<ExternalIdDTO> {
    return this.http.put<ExternalIdDTO>(`${this.apiUrl}/cdb/user/${userId}/external-ids`, data)
  }

  getAllPlatforms (): Observable<CdbPlatformDTO[]> {
    return this.http.get<CdbPlatformDTO[]>(`${this.apiUrl}/cdb/platforms`)
  }
}
