import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthService } from '../services/auth.service'
import { PermissionService } from '../services/permission.service'

/**
 * Check whether the current users permission includes read rights to the passed permission name.
 * The permission name are passed via route.data.permission.
 * ```
 * {
 *   path: 'dashboard',
 *   component: DashboardComponent,
 *   canActivate: [PermissionGuard],
 *   data: { permission: 'permission.name.here' }
 * },
 * ```
 *
 * This also works as of 4.6.0.
 *
 * {
 *   path: 'dashboard',
 *   component: DashboardComponent,
 *   canActivate: [PermissionGuard],
 *   data: { permissionGuard: { rule: 'permission.name.here', redirect: 'redirect url here' } }
 * },
 */
@Injectable({
  providedIn: 'root'
})
export class PermissionGuard  {
  constructor (
    protected authService: AuthService,
    protected permissionService: PermissionService,
    protected router: Router
  ) {}

  canActivate (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    // get the permission rule from data
    const permissionRule =
      next.data?.permissionGuard?.rule || next.data?.permission
    if (!permissionRule) {
      return true
    }

    // get the redirect url from data
    let redirect: UrlTree | undefined
    if (next.data?.permissionGuard?.redirect) {
      redirect = this.router.parseUrl(next.data?.permissionGuard?.redirect)
    }

    // if no redirect url then just return true/false
    if (redirect == null) {
      return this.checkPermission(permissionRule as string)
    }

    // return true or redirect url
    return this.checkPermission(permissionRule as string).pipe(
      map(hasPermission => {
        return hasPermission || redirect
      })
    )
  }

  canActivateChild (
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const permissionRule =
      childRoute.data?.permissionGuard?.rule || childRoute.data?.permission
    if (!permissionRule) {
      return true
    }

    let redirect: UrlTree | undefined
    if (childRoute.data?.permissionGuard?.redirect) {
      redirect = this.router.parseUrl(
        childRoute.data?.permissionGuard?.redirect
      )
    }

    if (redirect == null) {
      return this.checkPermission(permissionRule as string)
    }

    return this.checkPermission(permissionRule as string).pipe(
      map(hasPermission => {
        return hasPermission || redirect
      })
    )
  }

  private checkPermission (permission: string): Observable<boolean> {
    return this.permissionService.canRead(permission)
  }
}
