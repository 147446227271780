import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { NGXLogger } from 'ngx-logger'
import {
  ClientConfigService,
  ClientConfig
} from '@inside-hub-app/customer-portal-b2c-client'

@Component({
  selector: 'customer-portal-app-register-created-view',
  templateUrl: './register-created-view.component.html'
})
export class RegisterCreatedViewComponent implements OnInit {
  constructor(
    private readonly clientConfigService: ClientConfigService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly logger: NGXLogger
  ) {}

  apiKey: string
  config: ClientConfig

  ngOnInit(): void {
    this.apiKey = this.route.snapshot.queryParams.apiKey

    this.clientConfigService.get(this.apiKey).subscribe(clientConfig => {
      this.config = clientConfig

      // redirect after 3s
      setTimeout(() => {
        window.location.href = 'http://dev-cpt-de.emilfreydigital.hr/'
      }, 3000)
    })
  }
}
