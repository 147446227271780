<div class="p-4 lg:p-10 flex-1 flex flex-col">
  <div class="welcome flex flex-col gap-6 py-6">
    <div class="font-efSans-secondary font-semibold text-sm uppercase">
      {{ 'customer-portal' | sharedTranslate }}
    </div>
    <div class="font-canelablack-primary text-4xl md:text-5xl">
      {{ 'e-mail-confirmed' | sharedTranslate }}
    </div>
  </div>
  <div class="grid gap-6">
    <mat-card class="card-flex-rounded">
      <p class="font-efSans-primary">
        <T
          str="Thank you for signing up with Emil Frey. You will be redirected to your account where you can see and manage your details and preferences."
          key="customerPortal.b2c.register-verify.text"
          tags="cpt-auth, 3.1"
        ></T>
      </p>
    </mat-card>

    <button
      mat-button
      mat-raised-button
      color="secondary"
      type="submit"
      class="revolution w-full lg:w-1/2 button-spinner"
      (click)="redirect()"
      [disabled]="disabled"
    >
      <customer-portal-ef-spinner
        [color]="'mat-ef-revolution-grey-two-500'"
        [opacity]="0.7"
        [diameter]="16"
        *ngIf="disabled"
      >
      </customer-portal-ef-spinner>
      <span>
        {{ 'next' | sharedTranslate }}
      </span>
    </button>
  </div>
</div>
