import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

export type RegistrationSource = '100_WEB' | 'WEB'

@Injectable({
  providedIn: 'root'
})

export class SourceService {
  constructor () {}
  public source$:BehaviorSubject<RegistrationSource> = new BehaviorSubject<RegistrationSource>('WEB')
  public source: string
  public setSource (source: RegistrationSource) {
    this.source = source
    this.source$.next(source)
  }
}
