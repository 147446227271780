import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { DealerService } from '@inside-hub-app/customer-portal-b2c-client'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { CptSharedDataService } from '@inside-hub-app/customer-portal-shared'
import { B2CConfig } from '@inside-hub-app/customer-portal-config'
import { NGXLogger } from 'ngx-logger'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { RegistrationSource, SourceService } from '../../../services/source.service'

@Component({
  selector: 'customer-portal-revolution-main-view',
  templateUrl: './revolution-main-view.component.html'
})
export class RevolutionMainViewComponent implements OnInit {
  backgroundUrl: string

  countryCode: string
  public registrationSource: RegistrationSource = 'WEB'
  constructor(
    private readonly dealerService: DealerService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly sharedData: CptSharedDataService,
    private readonly logger: NGXLogger,
    private readonly remoteConfigService: EfRemoteConfigurationService<B2CConfig>,
    private readonly sourceService: SourceService
  ) {
    this.countryCode = this.remoteConfigService.get('country.code')
  }

  public form = new FormGroup({
    accept: new FormControl<boolean | null>(false, [Validators.requiredTrue])
  })

  public authenticated = true
  private readonly loading = true
  private readonly redirectUrl: string

  public backgroundAsset = 'assets/images/EF_Revolution_Hero_Landingpage.jpg'

  ngOnInit(): void {
    this.sourceService.source$.subscribe((source) => {
      this.registrationSource = source
    })
    this.route.queryParams.subscribe(value => {
      if (this.route.snapshot.queryParams?.lang !== undefined) {
        this.translate.use(value.lang.toLowerCase())
      }
    })

    this.backgroundUrl = `url('${this.sharedData.getResourcesPath()}/${
      this.backgroundAsset
    }')`
  }

  public redirect() {
    window.location.href = this.redirectUrl
  }
}
