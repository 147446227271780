import { Inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Environment } from '../environments'
import { ENVIRONMENT } from '../injection-tokens'
import { JsonApiResponse, JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { com } from 'efd-cdb_backend-interfaces-ts'
import { JsonApiRequest, JsonApiRequestData } from '../models/jsonApiRequest.model'

type ExtAccountConfigDTO = com.mocira.inside.cdb.client.dto.CdbExtAccountConfigDTO

@Injectable({ providedIn: 'root' })

export class SharedEmailAddressesService {
  private readonly apiUrl: string

  constructor (
    private readonly http: HttpClient,
    @Inject(ENVIRONMENT) private readonly environment: Environment
  ) {
    this.apiUrl = this.environment.baseUrl
  }

  getSharedEmailAddresses (filterEntity, parameters): Observable<JsonApiResponseList<ExtAccountConfigDTO>> {
    let params = new HttpParams()
    params = params.append('filter[entityType][EQ]', filterEntity.type)
    params = params.append('filter[entityId][EQ]', filterEntity.id)
    if (parameters.search) params = params.append('filter[value][LIKE]', parameters.search)
    if (parameters.size) params = params.append('page[size]', parameters.size)
    if (parameters.number) params = params.append('page[number]', parameters.number)
    if (parameters.sort) params = params.append('sort', parameters.sort)

    return this.http.get<JsonApiResponseList<ExtAccountConfigDTO>>(`${this.apiUrl}/cdb/ext-account-config/list`, { params })
  }

  updateConfiguration (data: ExtAccountConfigDTO): Observable<JsonApiResponse<ExtAccountConfigDTO>> {
    const requestData = new JsonApiRequest(new JsonApiRequestData(data))
    return this.http.put<JsonApiResponse<ExtAccountConfigDTO>>(
      `${this.apiUrl}/cdb/ext-account-config`, requestData
    )
  }

  deleteConfiguration (id: number): Observable<JsonApiResponse<ExtAccountConfigDTO>> {
    return this.http.delete<JsonApiResponse<ExtAccountConfigDTO>>(
      `${this.apiUrl}/cdb/ext-account-config/${id}`
    )
  }
}
