<div class="jumbo" [ngClass]="country" [style.background-image]="backgroundUrl">
  <div class="wrapper" *ngIf="country === 'de'">
    <mat-tab-group class="jumbo-mat-tab-group" [selectedIndex]="selectedIndex" dynamicHeight="true">
      <mat-tab *ngFor="let text of tabTexts" class="jumbo-text-container">
        <h2 class="jumbo-text" [innerHTML]="text"></h2>
      </mat-tab>
    </mat-tab-group>
    <div class="switch">
      <span
        *ngFor="let text of tabTexts; index as i"
        (click)="change(i)"
        [class.active]="selectedIndex === i"
        >●</span
      >
    </div>
  </div>
</div>
