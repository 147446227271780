import { Component, Input } from '@angular/core'

@Component({
  selector: 'customer-portal-skeleton-loader',
  templateUrl: './skeleton-loader.component.html'
})
export class CPTSkeletonLoaderComponent {
  @Input()
  public loading: boolean
}
