export class JsonApiRequestData<T> {
  id: number
  attributes: T
  constructor (attributes: T, id?: number) {
    this.id = id
    this.attributes = attributes
  }
}

export class JsonApiRequest<T> {
  data: JsonApiRequestData<T>
  constructor (data: JsonApiRequestData<T>) {
    this.data = data
  }
  public static of<T> (data: T, id?: number): JsonApiRequest<T> {
    return new JsonApiRequest<T>(new JsonApiRequestData<T>(data, id))
  }
}

