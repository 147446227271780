import { HubConfiguration } from './hub-configuration.model'
import { HubMobileConfiguration } from './hub-mobile-configuration.model'

export class HubStaticConfiguration {

  private static configuration: HubConfiguration | HubMobileConfiguration

  public static get<T extends HubConfiguration | HubMobileConfiguration>(): T {
    if (this.configuration) {
      return this.configuration as T
    }

    let configuration = (window as any).configuration
    const overrides = (window as any).ef?.configuration?.overrides
    this.configuration = { ...configuration, ...overrides }
    return this.configuration as T
  }
}
