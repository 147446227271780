<div class="p-4 lg:p-10 flex-1 flex flex-col">
  <div class="welcome flex flex-col gap-6 py-6">
    <div class="font-efSans-secondary font-semibold text-sm uppercase">
      {{ 'customer-portal' | sharedTranslate }}
    </div>
    <div class="font-canelablack-primary text-4xl md:text-5xl">
      {{ 'communication' | sharedTranslate }}
    </div>
    <div
      class="text-mat-ef-revolution-primary-500 font-efSans text-xl font-light"
    >
      {{ 'data-protection' | sharedTranslate }}
    </div>
    <div
      class="text-mat-ef-revolution-primary-500 font-efSans text-xl font-light"
    >
      {{ 'consent-channels-message' | sharedTranslate }}
    </div>
  </div>
  <div class="consents">
    <div class="CTP-gdpr">
      <mat-list *ngIf="group" class="gdpr-list">
        <mat-list-item class="hidden md:block">
          <div
            class="grid grid-cols-5 w-full items-center uppercase text-mat-ef-revolution-grey-two-500"
          >
            <h3></h3>
            <h3></h3>
            <h3
              matListItemTitle
              *ngFor="let channel of group?.consents?.channels"
              class="font-light font-efSans text-mat-ef-revolution-grey-two-500 flex justify-end"
            >
              {{
                {
                  str: channel.name,
                  prefix: 'customerPortal.b2c.consents.channels.'
                } | transifexTranslate
              }}
            </h3>
          </div>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-divider class="block md:hidden relative w-full"></mat-divider>

        <mat-list-item>
          <div matListItemTitle class="hidden md:grid grid-cols-5 w-full items-center">
            <div class="flex flex-col col-span-2">
              <span
                class="font-efSans font-semibold text-mat-ef-revolution-primary-500"
              >
                {{ 'emil-frey-digital-services' | sharedTranslate }}
              </span>
              <span
                class="font-efSans text-mat-ef-revolution-primary-500 text-sm"
              >
                <T
                  str="Cannstatter Strasse 46, 70190 Stuttgart"
                  key="customerPortal.b2c.consents.company.street"
                  tags="cpt-auth, 3.1"
                ></T>
              </span>
            </div>
            <span
              class="grid-list-items list-none"
              *ngFor="let channel of group?.consents?.channels"
            >
              <mat-checkbox
                class="h-6 w-6 float-right"
                color="primary"
                (change)="
                  updateConsentValue(
                    'group',
                    group.group.id,
                    channel.name,
                    $event
                  )
                "
              ></mat-checkbox>
            </span>
          </div>

          <div matListItemLine class="grid md:hidden">
            <div class="flex flex-col">
              <div
                class="font-semibold font-efSans text-mat-ef-revolution-primary-500"
              >
                {{ 'emil-frey-digital-services' | sharedTranslate }}
              </div>
              <div
                class="font-efSans text-mat-ef-revolution-primary-500 text-sm"
              >
                <T
                  str="Cannstatter Strasse 46, 70190 Stuttgart"
                  key="customerPortal.b2c.consents.company.street"
                  tags="cpt-auth, 3.1"
                ></T>
              </div>
            </div>
            <div class="flex flex-col gap-5 my-4">
              <span
                class="grid-list-items flex justify-between items-center"
                *ngFor="let channel of group?.consents?.channels"
              >
                <h6
                  matListItemLine
                  class="font-light font-efSans text-mat-ef-revolution-grey-two-500"
                >
                  {{
                    {
                      str: channel.name,
                      prefix: 'customerPortal.b2c.consents.channels.'
                    } | transifexTranslate
                  }}
                </h6>
                <mat-checkbox
                  class="h-6 w-6"
                  color="primary"
                  [checked]="getConsentGroup(group.group.name, channel.name)"
                  (change)="
                    updateConsentValue(
                      'group',
                      group.group.id,
                      channel.name,
                      $event
                    )
                  "
                ></mat-checkbox>
              </span>
            </div>
          </div>
          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>

      <input type="hidden" name="step" value="gdpr" />
      <input
        *ngFor="let selectedChannel of selectedChannels"
        type="hidden"
        name="setConsents"
        [value]="selectedChannel"
      />

      <div class="flex flex-col gap-6 mt-6">
        <button
          mat-button
          mat-raised-button
          color="secondary"
          class="revolution w-full md:w-1/2 button-spinner"
          (click)="updateConsents()"
          [disabled]="loading"
        >
          <customer-portal-ef-spinner
            [color]="'mat-ef-revolution-grey-two-500'"
            [opacity]="0.7"
            [diameter]="16"
            *ngIf="loading"
          >
          </customer-portal-ef-spinner>
          {{ 'next' | sharedTranslate }}
        </button>
        <!-- prettier-ignore -->
        <p
          class="font-efSans text-mat-ef-revolution-primary-500 a-link"
          [innerHTML]="getTranslation1()"
        ></p>

        <!-- prettier-ignore -->
        <p
          class="font-efSans text-mat-ef-revolution-primary-500 a-link"
          [innerHTML]="getTranslation2()"
        ></p>

        <!-- prettier-ignore -->
        <p
          class="font-efSans text-mat-ef-revolution-primary-500 a-link"
          [innerHTML]="getTranslation3()"
        ></p>
      </div>
    </div>
  </div>
</div>
