<ng-container *ngIf="registrationSource==='100_WEB'">
  <customer-portal-ef-spinner-dialog [message]="waitMessage"></customer-portal-ef-spinner-dialog>
</ng-container>

<ng-container *ngIf="registrationSource!=='100_WEB'">
  <div class="p-4 md:p-10 flex-1 flex flex-col align-middle text-mat-ef-revolution-primary-500">
    <div class="verify flex flex-col gap-6 md:gap-10">
      <div class="font-canelablack-primary text-4xl md:text-5xl">
        {{ 'e-mail-confirmed' | sharedTranslate }}
      </div>
      <div class="text-mat-ef-revolution-primary-500 font-efSans text-xl font-light">
        <T str="Thank you for signing up with Emil Frey. You will be redirected to your account where you can see and manage your details and preferences."
          key="customerPortal.b2c.register-verify.text" tags="cpt-auth, 3.1"></T>
      </div>
      <div class="next">
        <button data-cy="registration-confirm-email-submit-button"
          class="revolution w-full lg:w-1/2 font-bold button-spinner" mat-button mat-flat-button color="secondary"
          type="submit" (click)="redirect()" [disabled]="disabled">
          <!-- <div class="flex-1 flex items-center justify-between"> -->
          <customer-portal-ef-spinner [color]="'mat-ef-revolution-grey-two-500'" [opacity]="0.7" [diameter]="16"
            *ngIf="disabled">
          </customer-portal-ef-spinner>
          <span>
            {{ 'next' | sharedTranslate }}
          </span>
          <mat-icon svgIcon="chevron_right"></mat-icon>
          <!-- </div> -->
        </button>
      </div>
    </div>
  </div>
</ng-container>