import { Injectable } from '@angular/core'
import { BaseService } from './base.service'
import { Observable } from 'rxjs'
import { HttpParams } from '@angular/common/http'
import { JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { com } from 'efd-cdb_backend-interfaces-ts'

type CdbLocationRolesDTO = com.mocira.inside.cdb.client.dto.CdbLocationRolesDTO
type CdbUserRoleDTO = com.mocira.inside.cdb.rest.dto.CdbUserRoleDTO

@Injectable({
  providedIn: 'root'
})

export class UserRolesService extends BaseService {
  getUserRoles (userId: number, dealerId: number, platform: string): Observable<any> {
    const config = { params: { platform } }

    return this.http.get(`${this.apiUrl}/cdb/user/${userId}/dealer/${dealerId}/roles`, config)
  }

  getUserRolesAdditionalParams (
    userId: number,
    dealerId: number,
    thisLocationOnly: boolean,
    dealershipEnabled = true
  ): Observable<JsonApiResponseList<CdbLocationRolesDTO>> {
    let params = new HttpParams()
    params = params.append('platform', 'INSiDE')

    const route = `${this.apiUrl}/cdb/user/${userId}/dealer/${dealerId}/roles`
    if (thisLocationOnly) {
      params = params.append('thisLocationOnly', thisLocationOnly)
    }
    if (!dealershipEnabled) {
      params = params.append('locationEnabled', false)
    }
    return this.http.get<JsonApiResponseList<CdbLocationRolesDTO>>(route, {
      params
    })
  }

  getRoles (): Observable<JsonApiResponseList<any>> {
    return this.http.get<JsonApiResponseList<any>>(`${this.apiUrl}/cdb/roles`)
  }

  updateUserRoles (dataObject: CdbUserRoleDTO, isDealershipEnabled = true): Observable<any> {
    let params = new HttpParams()

    if (!isDealershipEnabled) {
      params = params.append('locationEnabled', false)
    }

    return this.http.put<JsonApiResponseList<any>>(
      `${this.apiUrl}/cdb/user/roles/update`,
      dataObject,
      { params }
    )
  }
}
