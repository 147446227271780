<button
  *ngIf="localeData.supported.length > 1"
  mat-button
  mat-stroked-button
  [color]="'primary'"
  class="revolution m-0 min-w-fit"
  [matMenuTriggerFor]="localeMenu"
  [ngClass]="{
    'border-white text-white': isMobileLogin,
  }"
>
  {{ localeData?.current }}
  <mat-icon svgIcon="keyboard_arrow_down"></mat-icon>
</button>
<mat-menu #localeMenu="matMenu" class="locale-menu">
  <a
    *ngFor="let lang of localeData.supported"
    mat-menu-item
    [href]="lang.url"
    >{{ lang?.label }}</a
  >
</mat-menu>
