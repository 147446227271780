export enum PpStorageEnum {
  PREFIX = 'inside_hub_app.',
  PP_PREFIX = 'ppModule.',
  DQ_DATA = 'dq_metadata',
  REQUIRED_ACTION = 'required_action'
}

export enum PpStorageKeysEnum {
  SELECTED_LANGUAGE = 'language',
  LOGIN_RESPONSE = 'loginResponse',
  CURRENT_DEALER = 'current_dealer',
  IMPERSONATED = 'is_user_impersonated',
  VISIT_ID = 'visitId',
  PROFILE_PICTURE_UPDATED = 'profilePictureUpdated',
}
