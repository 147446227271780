import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { com } from 'efd-cdb_backend-interfaces-ts'
import { BaseService } from './base.service'

type CdbBrandMinDTO = com.mocira.inside.cdb.client.dto.CdbBrandMinDTO
type CdbDealerBrandSmDTO = com.mocira.inside.cdb.client.dto.CdbDealerBrandSmDTO

@Injectable({
  providedIn: 'root'
})
export class BrandService extends BaseService {
  getBrandsList (params?): Observable<JsonApiResponseList<CdbBrandMinDTO>> {
    let config = {}

    if (params) {
      config = { params }
    }

    return this.http.get<JsonApiResponseList<CdbBrandMinDTO>>(`${this.apiUrl}/cdb/brands-list`, config)
  }

  getBrandsByDealerId (dealerId: number): Observable<JsonApiResponseList<CdbDealerBrandSmDTO>> {
    return this.http.get<JsonApiResponseList<CdbDealerBrandSmDTO>>(`${this.apiUrl}/cdb/dealer/${dealerId}/brands`)
  }

  public getDealerBrands (
    dealerId: number
  ): Observable<JsonApiResponseList<CdbDealerBrandSmDTO>> {
    return this.http.get<JsonApiResponseList<CdbDealerBrandSmDTO>>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/brands`
    )
  }

  public saveDealerBrands (
    dealerId: number,
    brandsList: object[]
  ): Observable<JsonApiResponseList<CdbDealerBrandSmDTO>> {
    return this.http.put<JsonApiResponseList<CdbDealerBrandSmDTO>>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/brands`,
      { data: brandsList }
    )
  }
}
