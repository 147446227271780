import { Injectable } from '@angular/core'
import { type Observable } from 'rxjs'
import { type JsonApiResponse } from '@inside-hub-app/ef-ng-json-api'
import { JsonApiRequest } from '../models/jsonApiRequest.model'
import { com } from 'efd-cdb_backend-interfaces-ts'
import CdbDocumentDTO = com.mocira.inside.cdb.client.dto.CdbDocumentDTO
import { BaseService } from './base.service'

@Injectable({
  providedIn: 'root'
})
export class DocumentManagementService extends BaseService {
  // get documents list
  getDocuments(parameters) {
    const config = {
      params: parameters
    }

    return this.http.get(`${this.apiUrl}/cdb/document/list`, config)
  }

  // download document
  downloadDocument (documentId: number): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/cdb/document/${documentId}/download`, { responseType: 'blob' })
  }

  // delete document
  deleteDocument(documentId: number) {
    return this.http.delete(`${this.apiUrl}/cdb/document/${documentId}`)
  }

  // update document
  updateDocument(data: object): Observable<JsonApiResponse<CdbDocumentDTO>> {
    const wrappedData = JsonApiRequest.of(data)
    return this.http.put<JsonApiResponse<CdbDocumentDTO>>(
      `${this.apiUrl}/cdb/document`,
      wrappedData
    )
  }

  // save document
  saveDocument(data, forceSave?: boolean) {
    let config
    if (forceSave) {
      config = {
        params: { force: true }
      }
    }

    const formData: FormData = new FormData()
    formData.append('file', data.file)
    formData.append('documentName', data.documentName)
    // don't add description if null because it will be stringified
    if (data.description) formData.append('description', data.description)
    formData.append('dealerId', data.dealerId)
    formData.append('brandId', data.brandId)
    formData.append('documentType', data.documentType)
    formData.append('area', data.category)

    return this.http.post(`${this.apiUrl}/cdb/document/upload`, formData, config)
  }
}
