export interface FacebookResponse {
  success: boolean
  error: {
    message: string
    type: string
    code: number
    fbtrace_id: string
  }
}

export interface FacebookLoginResource extends FacebookResponse {
  status: string
  authResponse: {
    accessToken: string
    expiresIn: number
    signedRequest: string
    userID: string
  }
}

export interface FacebookSubscribedPageResource {
  category: string // "Business"
  id: string // "1066576900371189" 1066576900371189
  link: string // "http://localhost/"
  name: string // "INSiDE Leads Switzerland - DEV"
  subscribed_fields: string[] // ["leadgen"]
}

export interface FacebookCategoryResource {
  id: string
  name: string
}

export interface FacebookPageResource {
  access_token: string
  category: string
  category_list: FacebookCategoryResource[]
  name: string
  id: string
  tasks: string[]
}

export interface FacebookUserResource {
  id: string
  about: string
  address: any
  auth_method: string
  birthday: string
  email: string
  first_name: string
  gender: string
  hometown: any
  is_shared_login: boolean
  is_verified: boolean
  last_name: string
  link: string
  middle_name: string
  name: string
  short_name: string
}

export interface FacebookPaging {
  previous: string
  next: string
  cursors: {
    after: string
    before: string
  }
}

export interface FacebookDataResponse<T> {
  response: boolean
  data: T[]
  paging: FacebookPaging
}