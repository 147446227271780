import { Injectable } from '@angular/core'
import { BaseService } from './base.service'
import { Observable } from 'rxjs'
import { com } from 'efd-cdb_backend-interfaces-ts'
import { JsonApiResponse, JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { JsonApiRequest, JsonApiRequestData } from '../models/jsonApiRequest.model'

type CdbDealerBrandProfileDTO = com.mocira.inside.cdb.client.dto.CdbDealerBrandProfileDTO
type CdbDealerBrandSettingsDTO = com.mocira.inside.cdb.client.dto.CdbDealerBrandSettingsDTO
type CdbDealerBrandSmDTO = com.mocira.inside.cdb.client.dto.CdbDealerBrandSmDTO
type CdbDealerBrandDTO = com.mocira.inside.cdb.client.dto.CdbDealerBrandDTO

@Injectable({
  providedIn: 'root'
})

export class DealerBrandService extends BaseService {
  getBrandSPecificProfile (dealerBrandId: number): Observable<any> {
    return this.http.get<JsonApiResponse<CdbDealerBrandProfileDTO>>(
      `${this.apiUrl}/cdb/dealer/brand/${dealerBrandId}/profile`
    )
  }

  updateBrandSpecificProfile (
    dealerBrandId: number,
    dealerBrandProfile: CdbDealerBrandProfileDTO,
    updateFlags: boolean
  ): Observable<any> {
    const data = new JsonApiRequest(new JsonApiRequestData(dealerBrandProfile))
    return this.http.put<JsonApiResponse<CdbDealerBrandProfileDTO>>(
      `${this.apiUrl}/cdb/dealer/brand/${dealerBrandId}/profile?saveFlags=${updateFlags}`,
      data
    )
  }

  getDealerBrandSettings (dealerBrandId: number): Observable<any> {
    return this.http.get<JsonApiResponse<CdbDealerBrandSettingsDTO>>(
      `${this.apiUrl}/cdb/dealer/brand/${dealerBrandId}/settings`
    )
  }

  updateDealerBrandSettings (
    dealerBrandId: number,
    dealerBrandSettings: CdbDealerBrandSettingsDTO
  ): Observable<any> {
    const data = new JsonApiRequest(new JsonApiRequestData(dealerBrandSettings))
    return this.http.put<JsonApiResponse<CdbDealerBrandSettingsDTO>>(
      `${this.apiUrl}/cdb/dealer/brand/${dealerBrandId}/settings`,
      data
    )
  }

  getDealerBrands(
    dealerId: number
  ): Observable<JsonApiResponseList<CdbDealerBrandSmDTO>> {
    return this.http.get<JsonApiResponseList<CdbDealerBrandSmDTO>>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/brands`
    )
  }

  saveDealerBrands(
    dealerId: number,
    brandsList: object[]
  ): Observable<JsonApiResponseList<CdbDealerBrandSmDTO>> {
    return this.http.put<JsonApiResponseList<CdbDealerBrandSmDTO>>(
      `${this.apiUrl}/cdb/dealer/${dealerId}/brands`,
      { data: brandsList }
    )
  }

  getDmsType (
    dealerBrandId: number
  ): Observable<CdbDealerBrandDTO> {
    return this.http.get<CdbDealerBrandDTO>(`${this.apiUrl}/cdb/dealer/${dealerBrandId}`)
  }
}
