import { HttpClient, HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Environment } from '../environments'
import { ENVIRONMENT } from '../injection-tokens'
import { JsonApiResponse } from '@inside-hub-app/ef-ng-json-api'
import { com, hr } from 'efd-cdb_backend-interfaces-ts'
import {
  JsonApiRequest,
  JsonApiRequestData
} from '../models/jsonApiRequest.model'

export type CdbWagesConfigurationDTO =
  com.mocira.inside.cdb.client.dto.CdbWagesConfigurationDTO
export type CdbBrandWagesDTO = com.mocira.inside.cdb.client.dto.CdbBrandWagesDTO
export type CdbBrandWageDetailDTO =
  com.mocira.inside.cdb.client.dto.CdbBrandWageDetailDTO
export type CdbRepairWageRequestDTO =
  hr.emilfreydigital.cdb.dto.CdbRepairWageRequestDTO

export type CdbDeleteWageRequestDTO = {
  brandId: number
  wageType: string
}

type Params = {
  brandId: number
  mappingBrandsScope: 'DEALER_BRANDS' | 'BRANDS_WITH_DEFINED_WAGES' | 'ALL'
}

@Injectable({
  providedIn: 'root'
})
export class DealerAftersalesWagesService {
  private readonly apiUrl: string

  constructor (
    private readonly http: HttpClient,
    @Inject(ENVIRONMENT) private readonly environment: Environment
  ) {
    this.apiUrl = this.environment.baseUrl
  }

  getWagesConfigurationList (
    parameters?: Params
  ): Observable<JsonApiResponse<CdbWagesConfigurationDTO>> {
    let params = new HttpParams()

    if (parameters?.brandId) {
      params = params.append('brandId', parameters?.brandId)
    }

    if (parameters?.mappingBrandsScope) {
      params = params.append(
        'mappingBrandsScope',
        parameters?.mappingBrandsScope
      )
    }

    return this.http.get<JsonApiResponse<CdbWagesConfigurationDTO>>(
      `${this.apiUrl}/cdb/wages-configuration/list`,
      {
        params
      }
    )
  }

  getBrandWagesConfiguration (
    brandId: number
  ): Observable<JsonApiResponse<CdbBrandWagesDTO>> {
    return this.http.get<JsonApiResponse<CdbBrandWagesDTO>>(
      `${this.apiUrl}/cdb/wages-configuration/brand/${brandId}`
    )
  }

  createOrUpdateRepairWagePriceEntry (
    req: CdbRepairWageRequestDTO
  ): Observable<JsonApiResponse<CdbWagesConfigurationDTO>> {
    const data = new JsonApiRequest(new JsonApiRequestData(req))

    return this.http.put<JsonApiResponse<CdbWagesConfigurationDTO>>(
      `${this.apiUrl}/cdb/wages-configuration`,
      data
    )
  }

  deleteWagePrice (brandId = 0, wageType: string): Observable<unknown> {
    return this.http.delete(
      `${this.apiUrl}/cdb/wages-configuration/brand/${brandId}/type/${wageType}`
    )
  }
}
