import { LocationStrategy, PathLocationStrategy } from '@angular/common'
import { type ApplicationRef, type DoBootstrap, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { HttpClient, HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import {
  MatLegacyNativeDateModule as MatNativeDateModule,
  MAT_LEGACY_DATE_LOCALE as MAT_DATE_LOCALE
} from '@angular/material/legacy-core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module'

// libraries
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core'
import {
  PpMissingTranslationHandler,
  PpModule,
  PpTranslationLoader,
  type Translation,
  TranslationService,
  type PpModuleConfig
} from '@inside-hub-app/ef-ng-pp-client'
import {
  KeycloakAngularModule,
  KeycloakService
} from '@emilfreydigital/keycloak-angular'
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger'

// modules
import {
  B2CModule,
  type Environment as B2cEnvironment
} from '@inside-hub-app/customer-portal-b2c-client'

// components
import { AppComponent } from './app.component'

// views
import { MatCardModule } from '@angular/material/card'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import {
  IconModule,
  CustomerPortalSharedModule
} from '@inside-hub-app/customer-portal-shared'
import {
  type B2CConfig,
  CustomerPortalCustomerPortalConfigModule
} from '@inside-hub-app/customer-portal-config'
import { GoogleTagManagerModule } from 'angular-google-tag-manager'
import { type Observable } from 'rxjs'
import { CustomCalendarComponent } from './components/custom-calendar/custom-calendar.component'
import { FooterNewComponent } from './components/footer-new/footer-new.component'
import { FooterComponent } from './components/footer/footer.component'
import { LocalizationMenuComponent } from './components/localization-menu/localization-menu.component'
import { BusinessInviteVerifyViewComponent } from './views/revolution/business-invite-verify-view/business-invite-verify-view.component'
import { ConsentsViewComponent } from './views/revolution/consents-view/consents-view.component'
import { FastRegistrationComponent } from './views/revolution/fast-registration/fast-registration.component'
import { ForgotPasswordVerifyViewComponent } from './views/revolution/forgot-password-verify-view/forgot-password-verify-view.component'
import { RegisterCreatedViewComponent } from './views/revolution/register-created-view/register-created-view.component'
import { ChangeEmailVerifyViewComponent } from './views/revolution/change-email-verify-view/change-email-verify-view.component'
import { RequestBusinessSentViewComponent } from './views/revolution/request-business-sent-view/request-business-sent-view.component'
import { RevolutionErrorViewComponent } from './views/revolution/revolution-error-view/revolution-error-view.component'
import { RevolutionForgotPasswordVerifyViewComponent } from './views/revolution/revolution-forgot-password-verify-view/revolution-forgot-password-verify-view.component'
import { RevolutionInviteEmailVerifyViewComponent } from './views/revolution/revolution-invite-email-verify-view/revolution-invite-email-verify-view.component'
import { RevolutionMainViewComponent } from './views/revolution/revolution-main-view/revolution-main-view.component'
import { RevolutionRegisterVerifyViewComponent } from './views/revolution/revolution-register-verify-view/revolution-register-verify-view.component'
import { ImportSentViewComponent } from './views/revolution/import-sent-view/import-sent-view.component'
import { InviteEmailVerifyViewComponent } from './views/revolution/invite-email-verify-view/invite-email-verify-view.component'
import { RegisterSentViewComponent } from './views/revolution/register-sent-view/register-sent-view.component'
import { RegisterVerifyViewComponent } from './views/revolution/register-verify-view/register-verify-view.component'
import { TxNativeModule } from '@emilfreydigital/transifex-angular'
import { EfRemoteConfigModule } from '@inside-hub-app/ef-remote-config'
import { MatDialogModule } from '@angular/material/dialog'

const keycloakService = new KeycloakService()
const remoteConfig = (window as any).ef.remoteConfig.configuration as B2CConfig

const ppEnvironments: PpModuleConfig = {
  storagePrefix: remoteConfig['ppEnvironments.b2c.storagePrefix'],
  translationPrefix: remoteConfig['ppEnvironments.b2c.translationPrefix'],
  environment: {
    domain: remoteConfig['ppEnvironments.b2c.environment.domain'],
    baseUrl: remoteConfig['ppEnvironments.b2c.environment.baseUrl'],
    authorizationExcludedUrls: [
      {
        pattern:
          remoteConfig[
            'ppEnvironments.b2c.environment.authorizationExcludedUrls.0.pattern'
          ]
      },
      {
        pattern:
          remoteConfig[
            'ppEnvironments.b2c.environment.authorizationExcludedUrls.1.pattern'
          ]
      },
      {
        pattern:
          remoteConfig[
            'ppEnvironments.b2c.environment.authorizationExcludedUrls.2.pattern'
          ]
      }
    ]
  }
}

@NgModule({
  declarations: [
    AppComponent,
    RegisterSentViewComponent,
    RegisterVerifyViewComponent,
    RegisterCreatedViewComponent,
    ForgotPasswordVerifyViewComponent,
    InviteEmailVerifyViewComponent,
    LocalizationMenuComponent,
    FooterComponent,
    FooterNewComponent,
    ImportSentViewComponent,
    FastRegistrationComponent,
    ConsentsViewComponent,
    CustomCalendarComponent,
    BusinessInviteVerifyViewComponent,
    RequestBusinessSentViewComponent,
    ChangeEmailVerifyViewComponent,
    RevolutionMainViewComponent,
    RevolutionErrorViewComponent,
    RevolutionRegisterVerifyViewComponent,
    RevolutionInviteEmailVerifyViewComponent,
    RevolutionForgotPasswordVerifyViewComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatCardModule,
    MatIconModule,
    // @angular/material
    AppRoutingModule,
    KeycloakAngularModule,
    LoggerModule.forRoot({
      enableSourceMaps: !remoteConfig.production,
      level: !remoteConfig.production
        ? NgxLoggerLevel.DEBUG
        : NgxLoggerLevel.ERROR
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [TranslationService, HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: PpMissingTranslationHandler
      }
    }),
    // b2c client
    B2CModule.forRoot({
      environment: remoteConfig['b2c.environment'] as B2cEnvironment,
      apiKey: remoteConfig['b2c.apiKey'],
      cordovaLoginRedirectUri: null
    }),
    // pp client
    PpModule.forRoot(ppEnvironments),
    TxNativeModule.forRoot(),
    MatListModule,
    MatMenuModule,
    // GTM
    GoogleTagManagerModule.forRoot({
      id: remoteConfig['googleTagManager.gtmId']
    }),
    CustomerPortalSharedModule,
    IconModule.forRoot({
      iconsPath: './assets/icons',
      useKcResourcePath: false
    }),
    EfRemoteConfigModule,
    CustomerPortalCustomerPortalConfigModule
  ],
  providers: [
    { provide: KeycloakService, useValue: keycloakService },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' }
  ]
})
export class AppModule implements DoBootstrap {
  ngDoBootstrap (app: ApplicationRef) {
    app.bootstrap(AppComponent)
  }
}

export function getTranslations (http: HttpClient): Observable<Translation[]> {
  const country = remoteConfig['country.name'].toLowerCase()
  return http.get(
    './assets/i18n/translations-' + country + '.json'
  ) as Observable<Translation[]>
}

export function HttpLoaderFactory (
  translationService: TranslationService,
  http: HttpClient
) {
  if (window) {
    const params = new URL(window.location.href).searchParams
    if (params) {
      const showTranslationKeys = params.get('showTranslationKeys')
      if (showTranslationKeys === 'true') {
        return new PpTranslationLoader(
          [{ type: '', prefix: '' }],
          '',
          translationService
        )
      }
    }
  }

  return new PpTranslationLoader(
    [
      {
        type: 'customerPortal',
        prefix: 'b2c.'
      },
      {
        type: 'customerPortal',
        prefix: 'kc.'
      },
      {
        type: 'customerPortal',
        prefix: 'customer-portal.'
      }
    ],
    '',
    translationService,
    ['it', 'fr', 'de', 'en'],
    getTranslations(http)
  )

  /**
  return new PpTranslationLoader(
    'customerPortal',
    '',
    translationService,
    ['it', 'fr', 'de', 'en'],
    getTranslations(http))
  */
}
