import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  type JsonApiRequest,
  type JsonApiResponse
} from '@inside-hub-app/ef-ng-json-api'
import { HubConfigurationService } from '@inside-hub-app/hub-configuration'
import { map, type Observable } from 'rxjs'
import { type CdbSmallMaterialDTO } from '../models/dealer-aftersales-small-material.model'

@Injectable({
  providedIn: 'root'
})
export class DealerAftersalesSmallMaterialService {
  apiUrl: string

  constructor (
    private readonly http: HttpClient,
    private readonly hubConfigurationService: HubConfigurationService
  ) {
    this.apiUrl = this.hubConfigurationService.get('api.pp-url')
  }

  getSmallMaterial (): Observable<CdbSmallMaterialDTO> {
    return this.http
      .get<JsonApiResponse<CdbSmallMaterialDTO>>(
        `${this.apiUrl}/cdb/small-material`
      )
      .pipe(map(res => res?.data?.attributes))
  }

  updateSmallMaterial (
    data: JsonApiRequest<CdbSmallMaterialDTO>
  ): Observable<CdbSmallMaterialDTO> {
    return this.http
      .put<JsonApiResponse<any>>(`${this.apiUrl}/cdb/small-material`, data)
      .pipe(map(res => res?.data?.attributes))
  }
}
