
<div class="p-4 lg:p-10 flex-1 flex flex-col">
  <div class="welcome flex flex-col gap-6 py-6">
    <div class="font-efSans-secondary font-semibold text-sm uppercase">
      {{ 'customer-portal' | sharedTranslate }}
    </div>
    <div class="font-canelablack-primary text-4xl md:text-5xl">
      <T
        str="Confirmation of email address change"
        key="customerPortal.b2c.change-email-verify.title"
        tags="cpt-auth, 3.1"
      ></T>
    </div>
  </div>
  <div class="grid gap-6">
    <mat-card class="card-flex-rounded">
      <p class="font-efSans text-mat-ef-revolution-primary-500">
        <T
          str="Thank you for verifying your email. You will be redirected to your account to confirm username change."
          key="customerPortal.b2c.change-email-verify.text2"
          tags="cpt-auth, 3.14"
        ></T>
      </p>
    </mat-card>
    <button
      mat-button
      mat-raised-button
      class="revolution w-full lg:w-1/2 button-spinner"
      color="secondary"
      (click)="verify()"
      [disabled]="submitting"
    >
      <customer-portal-ef-spinner
        [color]="'mat-ef-revolution-grey-two-500'"
        [opacity]="0.7"
        [diameter]="16"
        *ngIf="submitting"
      >
      </customer-portal-ef-spinner>
      {{ 'next' | sharedTranslate }}
    </button>
  </div>
</div>
<!-- will show spinner instead of normal view since we autoredirect  -->
<customer-portal-ef-spinner-dialog *ngIf="autoredirect"
></customer-portal-ef-spinner-dialog>
