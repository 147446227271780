import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { JsonApiResponse } from '@inside-hub-app/ef-ng-json-api'
import { com, hr } from 'efd-cdb_backend-interfaces-ts'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import {
  JsonApiRequest,
  JsonApiRequestData
} from '../models/jsonApiRequest.model'
import { BaseService } from './base.service'

type CdbCheckConfigurationDTO =
  com.mocira.inside.cdb.client.dto.CdbCheckConfigurationDTO
type CdbCheckItemDTO = com.mocira.inside.cdb.client.dto.CdbCheckItemDTO
type CdbCheckItemRequestDTO = hr.emilfreydigital.cdb.dto.CdbCheckItemRequestDTO

@Injectable({
  providedIn: 'root'
})
export class AftersalesChecksService extends BaseService {
  toggleCheckConfiguration (
    checkType: 'walk-around-check' | 'quality-check',
    enabled: boolean
  ) {
    return this.http
      .put<JsonApiResponse<CdbCheckConfigurationDTO>>(
        `${this.apiUrl}/cdb/check-configuration/type/${checkType}?enabled=${enabled}`,
        null
      )
      .pipe(map(res => res?.data?.attributes))
  }

  toggleAllowMediaUpload (
    checkType: 'walk-around-check' | 'quality-check',
    enabled: boolean
  ): Observable<CdbCheckConfigurationDTO> {
    return this.http
      .put<JsonApiResponse<CdbCheckConfigurationDTO>>(
        `${this.apiUrl}/cdb/check-configuration/type/${checkType}/media?mediaEnabled=${enabled}`,
        null
      )
      .pipe(map(res => res?.data?.attributes))
  }

  getCheckConfiguration (
    checkType: 'walk-around-check' | 'quality-check',
    brandId?: number
  ): Observable<CdbCheckConfigurationDTO> {
    let params = new HttpParams()
    if (brandId) {
      params = params.append('brandId', brandId)
    }

    return this.http
      .get<JsonApiResponse<CdbCheckConfigurationDTO>>(
        `${this.apiUrl}/cdb/check-configuration/type/${checkType}`,
        {
          ...this.headers,
          params
        }
      )
      .pipe(map(res => res?.['data']?.attributes))
  }

  patchCheckItem (
    checkType: 'walk-around-check' | 'quality-check',
    data: CdbCheckItemRequestDTO
  ) {
    const requestData = new JsonApiRequest(new JsonApiRequestData(data))

    return this.http
      .put<JsonApiResponse<CdbCheckItemDTO>>(
        `${this.apiUrl}/cdb/check-configuration/type/${checkType}/item`,
        requestData
      )
      .pipe(map(res => res?.data?.attributes))
  }

  deleteCheckItem (id: number) {
    return this.http.delete(`${this.apiUrl}/cdb/check-configuration/item/${id}`)
  }

  reorderCheckItem (id: number, newSequenceNumber: number) {
    return this.http
      .put<JsonApiResponse<CdbCheckConfigurationDTO>>(
        `${this.apiUrl}/cdb/check-configuration/item/${id}/order/${newSequenceNumber}`,
        null
      )
      .pipe(map(res => res?.data?.attributes))
  }
}
