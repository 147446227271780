import type { com, hr } from 'efd-cdb_backend-interfaces-ts'
import type { EfDatatableSortEvent } from './efDatatable.model'

export type CdbUserApiDTO = hr.emilfreydigital.cdb.dto.CdbUserApiDTO
export type CdbUserApiSecretDTO = hr.emilfreydigital.cdb.dto.CdbUserApiSecretDTO
export type CdbDealerListDTO = com.mocira.inside.cdb.client.dto.CdbDealerListDTO

export type ApiUsersParameters = {
  search?: string
  enabled?: boolean
  partnerId?: number
  roleIds?: number[]
  pageNumber?: number
  pageSize?: number
  sort?: EfDatatableSortEvent
}

export type UserApiCreateDTO = {
  username: string
  enabled: boolean
  roles: Array<{ id: number }>
  partners: Array<{ id: number }>
  technicalContact: string
  description: string
  type: string
}

export type UserApiUpdateDTO = UserApiCreateDTO & { id: number }
