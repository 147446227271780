import { Injectable } from '@angular/core'
import { HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { JsonApiResponse, JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { JsonApiRequest, JsonApiRequestData } from '../models/jsonApiRequest.model'
import { BaseService } from './base.service'
import { com } from 'efd-cdb_backend-interfaces-ts'

type CdbServiceDTO = com.mocira.inside.cdb.client.dto.CdbServiceDTO

@Injectable({ providedIn: 'root' })

export class ServicesService extends BaseService {
  public getServices (parameters): Observable<JsonApiResponseList<CdbServiceDTO>> {
    let params = new HttpParams()

    if (parameters !== null) {
      for (const [key, value] of Object.entries(parameters)) {
        params = params.append(key, value as string)
      }
    }

    return this.http.get<JsonApiResponseList<CdbServiceDTO>>(
    `${this.apiUrl}/cdb/service-settings`, { params }
    )
  }

  public getServiceById (serviceId: number): Observable<CdbServiceDTO> {
    return this.http.get<JsonApiResponse<CdbServiceDTO>>(
      `${this.apiUrl}/cdb/service-settings/${serviceId}`
    ).pipe(map(res => res?.data?.attributes))
  }

  public addService (data: CdbServiceDTO): Observable<CdbServiceDTO> {
    const requestData = new JsonApiRequest(new JsonApiRequestData(data))

    return this.http.post<JsonApiResponse<CdbServiceDTO>>(
      `${this.apiUrl}/cdb/service-settings`, requestData
    ).pipe(map(res => res?.data?.attributes))
  }

  public updateService (data: CdbServiceDTO): Observable<CdbServiceDTO> {
    const requestData = new JsonApiRequest(new JsonApiRequestData(data))

    return this.http.put<JsonApiResponse<CdbServiceDTO>>(
      `${this.apiUrl}/cdb/service-settings`, requestData
    ).pipe(map(res => res?.data?.attributes))
  }

  public deleteService (serviceId: number): Observable<JsonApiResponse<CdbServiceDTO>> {
    return this.http.delete<JsonApiResponse<CdbServiceDTO>>(
      `${this.apiUrl}/cdb/service-settings/${serviceId}`
    )
  }
}
