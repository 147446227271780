import { com } from 'efd-cdb_backend-interfaces-ts'

type CdbNestedBrandDTO = com.mocira.inside.cdb.client.dto.CdbNestedBrandDTO

export interface PostZoneAdministrationObject {
  id: number
  brand: Partial<CdbNestedBrandDTO>
  manager: Partial<CdbNestedBrandDTO>
  name: string
  area: string
}
