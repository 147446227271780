import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { DealerService } from '@inside-hub-app/customer-portal-b2c-client'
import { CptSharedDataService } from '@inside-hub-app/customer-portal-shared'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'customer-portal-revolution-app-invite-email-verify-view',
  templateUrl: './revolution-invite-email-verify-view.component.html'
})
export class RevolutionInviteEmailVerifyViewComponent implements OnInit {
  backgroundUrl: string
  public submitting: boolean

  constructor(
    private readonly dealerService: DealerService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly sharedData: CptSharedDataService
  ) {
    this.route.queryParams.subscribe(value => {
      translate.use(value.lang)
    })
  }

  public form = new FormGroup({
    accept: new FormControl<boolean | null>(false, [Validators.requiredTrue])
  })

  private loading = true
  private redirectUrl: string

  public backgroundAssetUrl =
    './assets/images/EF_Revolution_Hero_Landingpage.jpg'

  ngOnInit(): void {
    this.dealerService
      .verify(
        this.route.snapshot.params.verificationId,
        null,
        this.translate.currentLang
          ? this.translate.currentLang
          : this.translate.getDefaultLang()
      )
      .subscribe(
        response => {
          this.loading = false
          this.redirectUrl = response.redirectUrl
        },
        errorResponse => {
          // verify email error
          this.loading = false
          window.history.state.code = errorResponse?.error?.code
          window.history.state.message = errorResponse?.error?.message
          this.router.navigate(['error'], { skipLocationChange: true })
        }
      )
  }

  public redirect(): void {
    this.submitting = true
    window.location.href = this.redirectUrl
  }
}
