import { Injectable } from '@angular/core'
import { JsonApiResponse, JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { BaseService } from './base.service'
import { com } from 'efd-cdb_backend-interfaces-ts'
import { HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { TrainingAdministrationParameters } from '../models/training-administration.model'
import { JsonApiRequest } from '../models/jsonApiRequest.model'
import { EfDatatableSortDirection } from '../enum/efDatatable.enum'
import { isArray } from 'lodash'

type CdbBrandQualificationPlanDTO = com.mocira.inside.cdb.client.dto.CdbBrandQualificationPlanDTO
type CdbBrandQualificationSubscriptionPlanDTO = com.mocira.inside.cdb.client.dto.CdbBrandQualificationSubscriptionPlanDTO
type CdbUserBrandQualificationSubscriptionDTO = com.mocira.inside.cdb.client.dto.CdbUserBrandQualificationSubscriptionDTO

@Injectable({
  providedIn: 'root'
})

export class TrainingAdministrationService extends BaseService {
  getArrangedTrainingByBrand (parameters: TrainingAdministrationParameters): Observable<JsonApiResponseList<CdbBrandQualificationPlanDTO>> {
    const params = this.setParams(parameters)
    return this.http.get<JsonApiResponseList<CdbBrandQualificationPlanDTO>>(`${this.apiUrl}/cdb/brand-qualifications/planing`, { params })
  }

  getArrangedTrainingById (trainingEducationId: number): Observable<JsonApiResponseList<CdbBrandQualificationPlanDTO>> {
    return this.http.get<JsonApiResponseList<CdbBrandQualificationPlanDTO>>(`${this.apiUrl}/cdb/brand-qualifications/planing/${trainingEducationId}`)
  }

  createArrangedTraining (arrangedTraining: CdbBrandQualificationPlanDTO): Observable<JsonApiResponse<void>> {
    const wrappedData = JsonApiRequest.of(arrangedTraining)
    return this.http.post<JsonApiResponse<void>>(`${this.apiUrl}/cdb/brand-qualifications/planing`, wrappedData)
  }

  updateArrangedTraining (arrangedTraining: CdbBrandQualificationPlanDTO): Observable<JsonApiResponse<void>> {
    const wrappedData = JsonApiRequest.of(arrangedTraining)
    return this.http.put<JsonApiResponse<void>>(`${this.apiUrl}/cdb/brand-qualifications/planing`, wrappedData)
  }

  deleteArrangedTraining (arrangedTrainingId: number): Observable<JsonApiResponse<void>> {
    return this.http.delete<JsonApiResponse<void>>(`${this.apiUrl}/cdb/brand-qualifications/planing/${arrangedTrainingId}`)
  }

  getArrangedTrainingPlans (arrangedTrainingId: number, parameters: TrainingAdministrationParameters): Observable<JsonApiResponseList<CdbBrandQualificationSubscriptionPlanDTO>> {
    const params = this.setParams(parameters)
    return this.http.get<JsonApiResponseList<CdbBrandQualificationSubscriptionPlanDTO>>(`${this.apiUrl}/cdb/brand-qualifications/${arrangedTrainingId}/planing`, { params })
  }

  updateUserTrainingSubscription (trainingEducationId: number, userSubsribeData: CdbUserBrandQualificationSubscriptionDTO): Observable<JsonApiResponse<void>> {
    const wrappedData = JsonApiRequest.of(userSubsribeData)
    return this.http.put<JsonApiResponse<void>>(`${this.apiUrl}/cdb/brand-qualifications/${trainingEducationId}/user-subscription`, wrappedData)
  }

  setParams (parameters: TrainingAdministrationParameters): HttpParams {
    let params = new HttpParams()

    if (parameters?.brandId) {
      if (isArray(parameters?.brandId)) {
        parameters?.brandId.forEach((id) => {
          params = params.append('filter[brandId]', id)
        })
      } else {
        params = params.append('filter[brandId]', parameters.brandId)
      }
    }

    if (parameters?.status) {
      params = params.append('status', parameters.status)
    }

    if (parameters?.pageNumber) {
      params = params.append('page[number]', parameters.pageNumber)
    }

    if (parameters?.size) {
      params = params.append('page[size]', parameters.size)
    }

    if (parameters?.search) {
      params = params.append('search', parameters.search)
    }

    if (parameters.language) {
      params = params.append('lang', parameters.language)
    }

    if (parameters?.sort) {
      let sortValue = parameters.sort.column
      if (parameters.sort.direction === EfDatatableSortDirection.DESCENDING) {
        sortValue = `-${parameters.sort.column}`
      }
      params = params.append('sort', sortValue)
    }

    if (parameters?.managedBy) {
      params = params.append('filter[managedBy]', parameters.managedBy)
    }

    return params
  }
}
