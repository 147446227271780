import { Injectable } from '@angular/core'
import { HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { JsonApiResponse, JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { JsonApiRequest, JsonApiRequestData } from '../models/jsonApiRequest.model'
import { BaseService } from './base.service'
import { com } from 'efd-cdb_backend-interfaces-ts'

type CdbEducationDTO = com.mocira.inside.cdb.client.dto.CdbEducationDTO

@Injectable({ providedIn: 'root' })

export class EducationsService extends BaseService {
  public getEducations (parameters): Observable<JsonApiResponseList<CdbEducationDTO>> {
    let params = new HttpParams()

    if (parameters !== null) {
      for (const [key, value] of Object.entries(parameters)) {
        params = params.append(key, value as string)
      }
    }

    return this.http.get<JsonApiResponseList<CdbEducationDTO>>(
    `${this.apiUrl}/cdb/educations`, { params }
    )
  }

  public getEducationById (educationId: number): Observable<CdbEducationDTO> {
    return this.http.get<JsonApiResponse<CdbEducationDTO>>(
      `${this.apiUrl}/cdb/educations/${educationId}`
    ).pipe(map(res => res?.data?.attributes))
  }

  public addEducation (data: CdbEducationDTO): Observable<JsonApiResponse<CdbEducationDTO>> {
    const requestData = new JsonApiRequest(new JsonApiRequestData(data))

    return this.http.post<JsonApiResponse<CdbEducationDTO>>(
      `${this.apiUrl}/cdb/educations`, requestData
    )
  }

  public updateEducation (data: CdbEducationDTO): Observable<JsonApiResponse<CdbEducationDTO>> {
    const requestData = new JsonApiRequest(new JsonApiRequestData(data))

    return this.http.put<JsonApiResponse<CdbEducationDTO>>(
      `${this.apiUrl}/cdb/educations`, requestData
    )
  }

  public deleteEducation (educationId: number): Observable<JsonApiResponse<CdbEducationDTO>> {
    return this.http.delete<JsonApiResponse<CdbEducationDTO>>(
      `${this.apiUrl}/cdb/educations/${educationId}`
    )
  }
}
