import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { NGXLogger } from 'ngx-logger'
import _ from 'lodash'

import { JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { HubConfigurationService } from '@inside-hub-app/hub-configuration'
import { hr } from 'efd-cdb_backend-interfaces-ts'

import { StorageService } from '@inside-hub-app/hub-storage'
import { DataQualityCheckFilters, DqMetadata } from '../models/data-quality.model'
import { PpStorageEnum } from '../enum/storage.enum'

type DqMetadataDTO = hr.emilfreydigital.cdb.dto.DqMetadataDTO
type CdbUserMailParamsDTO = hr.emilfreydigital.cdb.dto.CdbUserMailParamsDTO

@Injectable({
  providedIn: 'root'
})
export class PpDataQualityCheckService {
  apiUrl: string

  constructor (
    private readonly logger: NGXLogger,
    private readonly http: HttpClient,
    private readonly hubConfiguration: HubConfigurationService,
    private readonly storageService: StorageService
  ) {
    this.apiUrl = this.hubConfiguration.get('api.pp-url')
  }

  public getDataQualityMetadata (filters?: DataQualityCheckFilters): Observable<JsonApiResponseList<DqMetadataDTO>> {
    let params = new HttpParams()
    if (!_.isEmpty(filters)) {
      _.forEach(filters, (value, key) => {
        params = params.append(`filter[${key}]`, value)
      })
    }
    return this.http.get<JsonApiResponseList<DqMetadataDTO>>(`${this.apiUrl}/cdb/dq-metadata`, { params })
  }

  public verifyEmailCheck (data: CdbUserMailParamsDTO): Observable<CdbUserMailParamsDTO> {
    return this.http.post(`${this.apiUrl}/cdb/users/verify-mail/send`, data)
  }

  public checkEmailValidity (data: CdbUserMailParamsDTO): Observable<CdbUserMailParamsDTO> {
    return this.http.post(`${this.apiUrl}/cdb/users/verify-mail/verify`, data)
  }

  public async deleteStoredDqMetadata (): Promise<void> {
    await this.deleteDqMetadataByRequiredAction(DqMetadata.VERIFY_EMAIL)
    await this.deleteDqMetadataByRequiredAction(DqMetadata.UPDATE_PROFILE)
  }

  // get data by required actions - for now it can only by update user profile or verify email
  public getDqMetadataByRequiredAction (type: DqMetadata.UPDATE_PROFILE | DqMetadata.VERIFY_EMAIL): Observable<DqMetadataDTO[]> {
    return this.storageService.get(PpStorageEnum.PREFIX + PpStorageEnum.DQ_DATA + '.' + String(type))
  }

  // store data by required actions
  public storeDqMetadataByRequiredAction (userDqMetadata: DqMetadataDTO[], type: DqMetadata.UPDATE_PROFILE | DqMetadata.VERIFY_EMAIL): Observable<void> {
    return this.storageService.set(PpStorageEnum.PREFIX + PpStorageEnum.DQ_DATA + '.' + String(type), userDqMetadata)
  }

  public deleteDqMetadataByRequiredAction (type: DqMetadata.UPDATE_PROFILE | DqMetadata.VERIFY_EMAIL): Observable<void> {
    return this.storageService.delete(PpStorageEnum.PREFIX + PpStorageEnum.DQ_DATA + '.' + String(type))
  }

  public getRequiredAction (requiredAction: DqMetadata.VERIFY_EMAIL | DqMetadata.UPDATE_PROFILE): Observable<boolean> {
    return this.storageService.get(PpStorageEnum.PREFIX + '.' + PpStorageEnum.REQUIRED_ACTION + '.' + requiredAction)
  }

  // used to store action that it was routed from user profile overview
  public storeRequiredAction (requiredAction: DqMetadata.VERIFY_EMAIL | DqMetadata.UPDATE_PROFILE, actionStatus = true): Observable<void> {
    return this.storageService.set(PpStorageEnum.PREFIX + '.' + PpStorageEnum.REQUIRED_ACTION + '.' + requiredAction, actionStatus)
  }

  // used to delete action that it was routed from user profile overview
  public deleteRequiredAction (requiredAction: DqMetadata.VERIFY_EMAIL | DqMetadata.UPDATE_PROFILE): Observable<void> {
    return this.storageService.delete(PpStorageEnum.PREFIX + '.' + PpStorageEnum.REQUIRED_ACTION + '.' + requiredAction)
  }
}
