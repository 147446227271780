import { Component, type OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { RegisterService } from '@inside-hub-app/customer-portal-b2c-client'
import { TranslateService } from '@ngx-translate/core'
import { type RegistrationSource, SourceService } from '../../../services/source.service'

@Component({
  selector: 'customer-portal-revolution-app-register-verify-view',
  templateUrl: './revolution-register-verify-view.component.html'
})
export class RevolutionRegisterVerifyViewComponent implements OnInit {
  waitMessage:
  {
    key: 'customerPortal.b2c.register.verify.autoredirect'
    str: 'Please wait while your account is being created, you will be redirected automatically.'
  }

  constructor (
    private readonly registerService: RegisterService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly sourceService: SourceService
  ) {
    this.route.queryParams.subscribe(value => {
      translate.use(value.lang)
    })
  }

  public loading = true
  private redirectUrl: string
  public disabled = false
  private autoRedirect = false
  public registrationSource: RegistrationSource = 'WEB'
  ngOnInit (): void {
    this.autoRedirect = Boolean(this.route.snapshot.queryParams.autoRedirect) || false
    this.sourceService.source$.subscribe((source) => {
      this.registrationSource = source
    })
    this.registerService
      .verifyEmail(
        this.route.snapshot.params.verificationId,
        this.translate.currentLang
      )
      .subscribe(
        res => {
          this.loading = false
          this.redirectUrl = res.redirectUrl
          if (this.autoRedirect) {
            this.redirect()
          }
        },
        errorResponse => {
          // verify email error
          this.loading = false
          window.history.state.code = errorResponse?.error?.code
          window.history.state.message = errorResponse?.error?.message
          this.router.navigate(['error'], { skipLocationChange: true })
        }
      )
  }

  redirect (): void {
    this.disabled = true
    this.loading = true
    window.location.href = this.redirectUrl
  }

  login (): void {
    this.redirect()
  }
}
