<div class="footer">
  <div class="container">
    <img class="logo" [src]="logoUrl" (click)="openUrl()" />
    <div class="logo-text">
      Emil Frey
      {{ countryName.toLowerCase() | sharedTranslate }}
    </div>
  </div>
  <div class="divider"></div>
  <div class="impressum">
    <div class="container">
      <a [href]="getImpressumLink()" target="_blank" class="link">
        {{ 'impressum' | sharedTranslate }}
      </a>
      <div class="link">|</div>
      <a [href]="getDataProtectionLink()" target="_blank" class="link">
        {{ 'data-protection' | sharedTranslate }}
      </a>
    </div>
  </div>
</div>
