
<div class="p-4 md:p-10 flex-1 flex flex-col gap-8 text-mat-ef-revolution-primary-500 h-full">
  <div class="md:flex-1 flex flex-col md:justify-center md:items-center">
    <div class="flex justify-center">
      <h2 class="font-canelablack-primary text-4xl md:text-5xl">
        <T *ngIf="code === '1007'; else errorText" str="Account already verified" key="customerPortal.b2c.error.verifiedTitle" tags="cpt-auth, 3.14"></T>
        <ng-template #errorText><T str="An error has occurred" key="customerPortal.b2c.error.title" tags="cpt-auth, 3.1"></T></ng-template>
      </h2>
    </div>
    <div class="flex-direction-column justify-center p-4 md:p-10">
      <mat-card class="mat-elevation-z0 border-mat-ef-revolution-supergrey-500 border rounded-lg flex flex-row gap-6">
        <div class="p-4 w-fit">
          <mat-icon>
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <path
                d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M13,17 L11,17 L11,15 L13,15 L13,17 Z M13,13 L11,13 L11,7 L13,7 L13,13 Z"
                id="path-1"></path>
              <title>error</title>
            </svg>
          </mat-icon>
        </div>
        <div class="p-4 flex-1">
          <p>
            <!-- there cant be empty space between "| translate:{ _key"  -->
            <!-- prettier-ignore -->
            {{ transifexStr | translate:{ _key: transifexKey, _tags: 'cpt-auth, 3.1, notranslate'} }}
          </p>
        </div>
      </mat-card>
      <div *ngIf="redirectUrl" class="mt-3">
        <button class="revolution revolution w-full font-bold button-spinner" mat-button mat-raised-button
          color="secondary" type="submit" (click)="redirectButtonClicked()">
          <div class="flex-1 flex items-center justify-between">
            <span>
              <T str="Continue to login" key="customerPortal.b2c.error.continue_to_login" tags="cpt-auth, 3.14"></T>
            </span>
            <mat-icon svgIcon="chevron_right"></mat-icon>
          </div>
        </button>
      </div>
    </div>
  </div>

  
</div>
