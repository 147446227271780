<div class="p-4 lg:p-10 flex-1 flex flex-col" [formGroup]="form">
  <div class="welcome flex flex-col gap-6 py-6">
    <div class="font-efSans-secondary font-semibold text-sm uppercase">
      {{ 'customer-portal' | sharedTranslate }}
    </div>
    <div class="font-canelablack-primary text-4xl md:text-5xl">
      {{ 'e-mail-confirmed' | sharedTranslate }}
    </div>
  </div>
  <div class="grid gap-6">
    <mat-card class="card-flex-rounded">
      <T
        str="An email with further instructions has been sent to you. If you have not received an email, you can email to have a new email sent."
        key="customerPortal.b2c.invite-verify.text"
        tags="cpt-auth, 3.1"
      ></T>
    </mat-card>
    <button
      mat-button
      mat-raised-button
      color="secondary"
      type="submit"
      class="revolution w-full lg:w-1/2 button-spinner"
      (click)="redirect()"
      [disabled]="submitting"
    >
      <customer-portal-ef-spinner
        [color]="'mat-ef-revolution-grey-two-500'"
        [opacity]="0.7"
        [diameter]="16"
        *ngIf="submitting"
      >
      </customer-portal-ef-spinner>
      {{ 'next' | sharedTranslate }}
    </button>
  </div>
</div>
