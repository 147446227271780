export type ConfigurationArrayPlaceholder<T> = { __array: T }
export type ConfigurationPlaceholder<T> = { __type: T }
export type PickKeys<T, C> = {
  [key in keyof T]: T[key] extends C ? key : never
}[keyof T]

export type OmitKeys<T, C> = {
  [key in keyof T]: T[key] extends C ? never : key
}[keyof T]

export type ConfigurationArrayValue = string[] | number[]
export type ConfigurationValue = string | number | boolean | ConfigurationArrayValue | ConfigurationPlaceholder<any> | ConfigurationArrayPlaceholder<any>
export interface GenericConfiguration { readonly [key: string]: ConfigurationValue }
