import { hr, com } from 'efd-cdb_backend-interfaces-ts'

export type CdbRepairListDTO = com.mocira.inside.cdb.client.dto.CdbRepairListDTO
export type CdbRepairDTO = com.mocira.inside.cdb.client.dto.CdbRepairDTO
export type CdbRepairRequestDTO = hr.emilfreydigital.cdb.dto.CdbRepairRequestDTO
export type RepairType = com.mocira.inside.cdb.client.dto.enums.RepairType
export type RepairCategory =
  com.mocira.inside.cdb.client.dto.enums.RepairCategory
export type RepairOrderType =
  com.mocira.inside.cdb.client.dto.enums.RepairOrderType
export type KnowledgeLevel =
  com.mocira.inside.cdb.client.dto.enums.KnowledgeLevel
export type CdbDealerBrandMinDTO =
  com.mocira.inside.cdb.client.dto.CdbDealerBrandMinDTO
export type CdbRepairPackageDTO =
  com.mocira.inside.cdb.client.dto.CdbRepairPackageDTO
export type Color = com.mocira.inside.cdb.client.dto.enums.Color
export type CdbRepairPackageItemDTO =
  com.mocira.inside.cdb.client.dto.CdbRepairPackageItemDTO
export type RepairPackageItemType =
  com.mocira.inside.cdb.client.dto.enums.RepairPackageItemType

export interface WorkPackageData {
  repairId: number
  translations: { [key: string]: string }
  packageId?: number
}
