import { ChangeDetectorRef, Component, Input, type OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import {
  type KcLocaleData,
  CptSharedDataService
} from '../../../services/kc-shared-data.service'

@Component({
  selector: 'customer-portal-app-revolution-localization',
  templateUrl: './revolution-localization.component.html',
  styleUrls: ['./revolution-localization.component.scss']
})
export class RevolutionLocalizationComponent implements OnInit {
  @Input() isMobileLogin = false
  constructor (
    private readonly ksSharedDataService: CptSharedDataService,
    private readonly translate: TranslateService,
    private readonly cdf: ChangeDetectorRef
  ) {}

  public localeData: KcLocaleData = {
    current: '',
    supported: []
  }

  setSelectedLanguage (code: string): void {
    let selected = this.localeData.supported.find(({ url }) =>
      url.includes('kc_locale=' + code)
    )
    if (selected == null) {
      selected = {
        label: 'de',
        url: ''
      }
    }
    this.localeData.current = selected.label
    this.translate.use(code)
    this.cdf.detectChanges()
  }

  ngOnInit (): void {
    this.localeData = this.ksSharedDataService?.getLocaleData()
    const urlParams = new URLSearchParams(window.location.href)
    const kcLocale = urlParams.get('kc_locale')
    const redirectUri = urlParams.get('redirect_uri')

    if (kcLocale != null) {
      const lang = kcLocale ?? 'de'
      this.setSelectedLanguage(lang)
    }

    // If there is redirect_uri in the path, we must add it to target href, CPEFG-3445
    if (redirectUri != null) {
      this.localeData.supported.forEach(ld => {
        ld.url += '&redirect_uri=' + redirectUri
      })
    }
  }
}
