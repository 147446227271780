import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BaseService } from './base.service'
import { type Observable } from 'rxjs'
import { type JsonApiResponse, type JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { type com, type hr } from 'efd-cdb_backend-interfaces-ts'
import { type BrandSpecificInformationCategory } from '../enum/brand-specific.enum'
import { JsonApiRequest, JsonApiRequestData } from '../models/jsonApiRequest.model'
import * as _ from 'lodash'
import { type DataSourceParameters } from '../models/efDatatable.model'
import { CertificateInfrastructureParameters } from '../models/dealer-service.model'

type CdbDealerBrandNoteDTO = hr.emilfreydigital.cdb.dto.CdbDealerBrandNoteDTO
type CdbDealerStatusConfigDTO = com.mocira.inside.cdb.client.dto.CdbDealerStatusConfigDTO
type CdbDealerBrandStatusDTO = com.mocira.inside.cdb.rest.dto.CdbDealerBrandStatusDTO
type CdbBrandSpecificDTO = hr.emilfreydigital.cdb.dto.CdbBrandSpecificDTO
type CdbAttributeDTO = com.mocira.inside.cdb.client.dto.CdbAttributeDTO
// type CdbDocumentReducedDTO = com.mocira.inside.cdb.client.dto.CdbDocumentReducedDTO
type CdbContractDTO = com.mocira.inside.cdb.client.dto.CdbContractDTO
type CdbFinanceDTO = com.mocira.inside.cdb.client.dto.CdbFinanceDTO
type CdbServiceRateDTO = com.mocira.inside.cdb.client.dto.CdbServiceRateDTO
type CdbQualificationUserCntDTO = com.mocira.inside.cdb.client.dto.CdbQualificationUserCntDTO
type CdbQualificationUserDTO = com.mocira.inside.cdb.client.dto.CdbQualificationUserDTO
type CdbPlatformDTO = com.mocira.inside.cdb.client.dto.CdbPlatformDTO
type CdbBrandSpecificCheckDTO = hr.emilfreydigital.cdb.dto.CdbBrandSpecificCheckDTO
type CdbToolDTO = com.mocira.inside.cdb.client.dto.CdbToolDTO

@Injectable({ providedIn: 'root' })

export class BrandSpecificService extends BaseService {
  getDocumentNotes (dealerBrandId: number, parameters?: any): Observable<JsonApiResponseList<CdbDealerBrandNoteDTO>> {
    let params = new HttpParams()

    params = params.append('filter[deleted][IsNULL]', '')
    if (parameters?.category !== null && parameters?.category !== '') params = params.append('filter[category]', parameters.category)
    if (parameters?.search !== null && parameters?.search !== '') params = params.append('filter[title][LIKE]', `%${parameters.search}%`) // `%${parameters.search}%`
    if (parameters?.number !== null) params = params.append('page[number]', parameters.number)
    if (parameters?.size !== null) params = params.append('page[size]', parameters.size)

    return this.http.get<JsonApiResponseList<CdbDealerBrandNoteDTO>>(
      `${this.apiUrl}/cdb/brand-specific/${dealerBrandId}/document-notes`, { params }
    )
  }

  saveDocumentNote (dealerBrandId: number, document: CdbDealerBrandNoteDTO, file: any): Observable<JsonApiResponse<CdbDealerBrandNoteDTO>> {
    const formData: FormData = new FormData()
    formData.append('data', JSON.stringify(document))
    if (file !== null) formData.append('file', file)

    return this.http.post<JsonApiResponse<CdbDealerBrandNoteDTO>>(`${this.apiUrl}/cdb/brand-specific/${dealerBrandId}/document-notes`, formData)
  }

  updateDocumentNote (document: CdbDealerBrandNoteDTO): Observable<JsonApiResponse<CdbDealerBrandNoteDTO>> {
    const formData: FormData = new FormData()
    formData.append('data', JSON.stringify(document))

    return this.http.put<JsonApiResponse<CdbDealerBrandNoteDTO>>(`${this.apiUrl}/cdb/brand-specific/document-notes`, formData)
  }

  deleteDocumentNote (documentNoteId: number): Observable<JsonApiResponse<CdbDealerBrandNoteDTO>> {
    return this.http.delete<JsonApiResponse<CdbDealerBrandNoteDTO>>(`${this.apiUrl}/cdb/brand-specific/document-notes/${documentNoteId}`)
  }

  downloadDocument (documentId: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/cdb/document/${documentId}/download`, { responseType: 'blob' })
  }

  getAllDealerStatuses (): Observable<JsonApiResponseList<CdbDealerStatusConfigDTO>> {
    return this.http.get<JsonApiResponseList<CdbDealerStatusConfigDTO>>(`${this.apiUrl}/cdb/brand-specific/status-config`)
  }

  updateDealerStatus (dealerId: number, data): Observable<JsonApiResponse<CdbDealerBrandStatusDTO>> {
    return this.http.put<JsonApiResponse<CdbDealerBrandStatusDTO>>(
      `${this.apiUrl}/cdb/dealer/brand/${dealerId}/status`,
      data
    )
  }

  getDealerStatus (dealerBrandId: number): Observable<JsonApiResponse<CdbDealerBrandStatusDTO>> {
    return this.http.get<JsonApiResponse<CdbDealerBrandStatusDTO>>(`${this.apiUrl}/cdb/dealer/brand/${dealerBrandId}/status`)
  }

  getBrandSpecific (
    dealerBrandId: number
  ): Observable<JsonApiResponseList<CdbBrandSpecificDTO>> {
    return this.http.get<JsonApiResponseList<CdbBrandSpecificDTO>>(
      `${this.apiUrl}/cdb/brand-specific/${dealerBrandId}`
    )
  }

  getBrandSpecificByCategory (
    dealerBrandId: number,
    category: BrandSpecificInformationCategory
  ): Observable<JsonApiResponseList<CdbBrandSpecificDTO>> {
    const params = { params: { category } }

    return this.http.get<JsonApiResponseList<CdbBrandSpecificDTO>>(
      `${this.apiUrl}/cdb/brand-specific/${dealerBrandId}`,
      params
    )
  }

  setBrandSpecificByCategory (
    dealerBrandId: number,
    category: BrandSpecificInformationCategory,
    attributes: CdbAttributeDTO[]
  ): Observable<any> {
    const requestData = new JsonApiRequest(
      new JsonApiRequestData({
        id: dealerBrandId,
        category,
        attributes
      })
    )

    return this.http.post<JsonApiResponse<CdbBrandSpecificDTO>>(
      `${this.apiUrl}/cdb/brand-specific`,
      requestData
    )
  }

  getDealerBrandContract (
    dealerBrandId: number
  ): Observable<JsonApiResponseList<CdbContractDTO>> {
    const params = {}
    if (dealerBrandId) params['filter[dealerBrandId]'] = dealerBrandId
    const config = { params }
    return this.http.get<JsonApiResponseList<CdbContractDTO>>(
      `${this.apiUrl}/cdb/contract/list`,
      config
    )
  }

  createDealerBrandContract (
    data: CdbContractDTO
  ): Observable<JsonApiResponseList<CdbContractDTO>> {
    const wrappedData = JsonApiRequest.of(data)
    return this.http.post<JsonApiResponseList<CdbContractDTO>>(
      `${this.apiUrl}/cdb/contract/`,
      wrappedData
    )
  }

  updateDealerBrandContract (
    data: CdbContractDTO
  ): Observable<JsonApiResponseList<CdbContractDTO>> {
    const wrappedData = JsonApiRequest.of(data, data.id)
    return this.http.put<JsonApiResponseList<CdbContractDTO>>(
      `${this.apiUrl}/cdb/contract/`,
      wrappedData
    )
  }

  // finance
  getDealerBrandFinance (
    dealerBrandId: number
  ): Observable<JsonApiResponseList<CdbFinanceDTO>> {
    const params = {}
    if (dealerBrandId) params['filter[dealerBrandId]'] = dealerBrandId
    const config = { params }
    return this.http.get<JsonApiResponseList<CdbFinanceDTO>>(
      `${this.apiUrl}/cdb/finance/list`,
      config
    )
  }

  createDealerBrandFinance (
    data: CdbFinanceDTO
  ): Observable<JsonApiResponseList<CdbFinanceDTO>> {
    const wrappedData = JsonApiRequest.of(data)
    return this.http.post<JsonApiResponseList<CdbFinanceDTO>>(
      `${this.apiUrl}/cdb/finance/`,
      wrappedData
    )
  }

  updateDealerBrandFinance (
    data: CdbFinanceDTO
  ): Observable<JsonApiResponseList<CdbFinanceDTO>> {
    const wrappedData = JsonApiRequest.of(data, data.id)
    return this.http.put<JsonApiResponseList<CdbFinanceDTO>>(
      `${this.apiUrl}/cdb/finance/`,
      wrappedData
    )
  }

  // service_rate
  getDealerBrandServiceRate (
    params: object
  ): Observable<JsonApiResponseList<CdbServiceRateDTO>> {
    const prepareParams = {}
    if (params) {
      _.each(params, (value, key) => {
        prepareParams[key] = value
      })
    }
    const config = { params: prepareParams }
    return this.http.get<JsonApiResponseList<CdbServiceRateDTO>>(
      `${this.apiUrl}/cdb/service_rate/list`,
      config
    )
  }

  createDealerBrandServiceRate (
    data: CdbServiceRateDTO
  ): Observable<JsonApiResponse<CdbServiceRateDTO>> {
    const wrappedData = JsonApiRequest.of(data)
    return this.http.post<JsonApiResponse<CdbServiceRateDTO>>(
      `${this.apiUrl}/cdb/service_rate/`,
      wrappedData
    )
  }

  deleteDealerBrandServiceRate (serviceRateId: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/cdb/service_rate/${serviceRateId}`)
  }

  // Third-party systems
  getDealerBrandTpsList (): Observable<CdbPlatformDTO[]> {
    return this.http.get<CdbPlatformDTO[]>(
      `${this.apiUrl}/cdb/dealer/brand/third_party_system/list`
    )
  }

  saveDealerBrandExternalId (dealerBrandId: number, data): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/cdb/dealer/brand/${dealerBrandId}/externalId/`,
      data
    )
  }

  getDealerMandatoryFunctions (
    dealerBrandId: number,
    parameters?
  ): Observable<any> {
    let params = new HttpParams()

    if (parameters?.number) {
      params = params.append('page[number]', parameters.number)
    }

    if (parameters?.size) {
      params = params.append('page[size]', parameters.size)
    }

    if (parameters?.search) {
      params = params.append('search', parameters.search)
    }

    if (parameters?.department) {
      params = params.append('filter[departments]', parameters.department)
    }

    return this.http.get<JsonApiResponseList<CdbQualificationUserCntDTO>>(
      `${this.apiUrl}/cdb/dealer/brand/${dealerBrandId}/qualifications`,
      { params }
    )
  }

  getDealerMandatoryFunctionsByQualification (
    dealerBrandId: number,
    qualificationId: number,
    parameters
  ): Observable<any> {
    let params = new HttpParams()

    if (parameters?.number) {
      params = params.append('page[number]', parameters.number)
    }

    if (parameters?.size) {
      params = params.append('page[size]', parameters.size)
    }

    if (parameters?.sortByDatatable?.column) {
      let sortString = parameters?.sortByDatatable?.column

      if (parameters?.sortByDatatable?.direction === 'desc') {
        sortString = '-' + sortString
      }

      params = params.append('sort', sortString)
    }

    return this.http.get<JsonApiResponseList<CdbQualificationUserDTO>>(
      `${this.apiUrl}/cdb/dealer/brand/${dealerBrandId}/qualifications/${qualificationId}/users`,
      { params }
    )
  }

  getDealerEducation (
    dealerBrandId: number,
    parameters: DataSourceParameters | null = null,
    filter = {},
    searchTerm: string = null
  ): Observable<any> {
    const params = {}
    params['filter[type]'] = 'EDUCATION'

    if (parameters) {
      const { pageIndex, pageSize } = parameters

      params['page[size]'] = pageSize
      params['page[number]'] = pageIndex + 1

      if (searchTerm) {
        params['search'] = searchTerm
      }

      for (const filterKey in filter) {
        const filterValue = filter[filterKey]
        if (_.isArray(filterValue) && filterValue.length) {
          params[`filter[${filterKey}][IN]`] = filterValue
        } else if (_.isObject(filterValue)) {
          for (const filterValueKey in filterValue) {
            if (
              filterValue[filterValueKey] &&
              filterValue[filterValueKey] !== false
            ) {
              params[`filter[${filterKey}][${filterValueKey}]`] =
                filterValue[filterValueKey]
            }
          }
        } else if (filterValue || filterValue === false) {
          params[`filter[${filterKey}]`] = filterValue
        }
      }
    }

    const config = {
      params
    }

    return this.http.get<JsonApiResponseList<CdbQualificationUserCntDTO>>(
      `${this.apiUrl}/cdb/dealer/brand/${dealerBrandId}/qualifications`,
      config
    )
  }

  getDealerTrainingEducationUsers (
    dealerBrandId: number,
    qualificationId: number,
    parameters: any,
    filter,
    searchTerm: string = null
  ): Observable<any> {
    const { pageIndex, pageSize } = parameters
    const params = {}

    params['page[size]'] = pageSize
    params['page[number]'] = pageIndex + 1

    if (searchTerm) {
      params['search'] = searchTerm
    }

    for (const filterKey in filter) {
      const filterValue = filter[filterKey]
      if (filterValue || filterValue === false) {
        params[filterKey] = filterValue
      }
    }

    const config = {
      params
    }

    return this.http.get<JsonApiResponseList<CdbQualificationUserDTO>>(
      `${this.apiUrl}/cdb/dealer/brand/${dealerBrandId}/qualifications/${qualificationId}/users`,
      config
    )
  }

  getManagementSummary (
    dealerBrandId: number,
    history: boolean,
    parameters?
  ): Observable<JsonApiResponseList<CdbBrandSpecificCheckDTO>> {
    const params = { params: {} }

    if (history) params.params['history'] = history
    if (parameters?.type) params.params['filter[type]'] = parameters.type
    if (parameters?.size) params.params['page[size]'] = parameters.size
    if (parameters?.page) params.params['page[number]'] = parameters.page
    if (parameters?.sort) params.params['sort'] = parameters.sort

    return this.http.get<JsonApiResponseList<CdbBrandSpecificCheckDTO>>(
      `${this.apiUrl}/cdb/brand-specific/check/${dealerBrandId}`,
      params
    )
  }

  createCheck (checkData): Observable<CdbBrandSpecificCheckDTO> {
    const requestData = new JsonApiRequest(new JsonApiRequestData(checkData))

    return this.http.post<CdbBrandSpecificCheckDTO>(
      `${this.apiUrl}/cdb/brand-specific/check`,
      requestData
    )
  }

  getTools (
    params: Partial<CertificateInfrastructureParameters>
  ): Observable<JsonApiResponse<CdbToolDTO>> {
    let parameters = new HttpParams()

    if (params.brandId && typeof params.brandId === 'number') {
      parameters = parameters.append('filter[brand.id]', params.brandId)
    }

    if (params.lang) {
      parameters = parameters.append('lang', params.lang)
    }
    if (params.search) {
      parameters = parameters.append('search', `%${params.search}%`)
    }

    if (params.size) {
      parameters = parameters.append('page[size]', params.size)
    }
    if (params.number) {
      parameters = parameters.append('page[number]', params.number)
    }

    return this.http.get<JsonApiResponse<CdbToolDTO>>(
      `${this.apiUrl}/cdb/infrastructure/tool`,
      { params: parameters }
    )
  }

  saveTool (toolObj: CdbToolDTO) {
    return this.http.post(`${this.apiUrl}/cdb/infrastructure/tool`, {
      data: { attributes: toolObj }
    })
  }

  updateTool (toolObj: CdbToolDTO, toolId: number) {
    return this.http.put(`${this.apiUrl}/cdb/infrastructure/tool`, {
      data: { id: toolId, attributes: toolObj }
    })
  }

  deleteTool (toolId: number) {
    return this.http.delete(`${this.apiUrl}/cdb/infrastructure/tool/${toolId}`)
  }
}
