import { Component, OnInit } from '@angular/core'
import {
  RegisterService,
  ClientConfigService
} from '@inside-hub-app/customer-portal-b2c-client'
import { ActivatedRoute, Router } from '@angular/router'
import { NGXLogger } from 'ngx-logger'
import { Location } from '@angular/common'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'customer-portal-app-register-verify-view',
  templateUrl: './register-verify-view.component.html'
})
export class RegisterVerifyViewComponent implements OnInit {
  constructor (
    private readonly registerService: RegisterService,
    private readonly clientConfigService: ClientConfigService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly logger: NGXLogger,
    private readonly location: Location,
    private readonly translate: TranslateService
  ) {
    this.route.queryParams.subscribe(value => {
      translate.use(value.lang)
    })
  }

  public loading = true
  private redirectUrl: string
  public disabled = false
  private autoRedirect = false

  ngOnInit (): void {
    this.autoRedirect = Boolean(this.route.snapshot.queryParams.autoRedirect) || false
    this.registerService
      .verifyEmail(
        this.route.snapshot.params.verificationId,
        this.translate.currentLang
      )
      .subscribe(
        res => {
          this.loading = false
          this.redirectUrl = res.redirectUrl
          if (this.autoRedirect) {
            this.redirect()
          }
        },
        errorResponse => {
          // verify email error
          this.loading = false
          window.history.state.code = errorResponse?.error?.code
          window.history.state.message = errorResponse?.error?.message
          //  this.router.navigate(['error'], { skipLocationChange: true })
        }
      )
  }

  redirect (): void {
    this.disabled = true
    this.loading = true
    window.location.href = this.redirectUrl
  }
}
