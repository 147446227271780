import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { DealerService } from '@inside-hub-app/customer-portal-b2c-client'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'customer-portal-app-business-invite-verify-view',
  templateUrl: './business-invite-verify-view.component.html'
})
export class BusinessInviteVerifyViewComponent implements OnInit {
  constructor(
    private readonly dealerService: DealerService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translate: TranslateService
  ) {}

  public form = new FormGroup({
    accept: new FormControl<boolean | null>(false, [Validators.requiredTrue])
  })

  public authenticated = true
  private loading = true
  private redirectUrl: string
  submitting: boolean
  ngOnInit(): void {
    this.dealerService
      .verify(
        this.route.snapshot.params.verificationId,
        null,
        this.translate.getDefaultLang()
      )
      .subscribe(
        response => {
          this.loading = false
          this.redirectUrl = response.redirectUrl
        },
        errorResponse => {
          // verify email error
          this.loading = false
          window.history.state.code = errorResponse?.error?.code
          window.history.state.message = errorResponse?.error?.message
          this.router.navigate(['error'], { skipLocationChange: true })
        }
      )
  }

  public redirect() {
    this.submitting = true
    window.location.href = this.redirectUrl
  }
}
