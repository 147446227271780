import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class NotTranslatedService {
  missingTranslations: string[] = []

  constructor() {}

  notTranslated(key: string) {
    if (!this.missingTranslations.includes(key) && this.isValidKey(key)) {
      this.missingTranslations.push(key)
    }
    window['missingTranslations'] = this.missingTranslations
  }

  isValidKey(key: string) {
    return this.lastChar(key) !== '.' && this.translateKey(key) !== 'undefined'
  }
  lastChar(key: string) {
    const translate = this.translateKey(key)
    return translate.slice(-1)
  }
  translateKey(key: string) {
    return key.split(',')[0]
  }
}
