import { Injectable } from '@angular/core'
import { JsonApiResponse, JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { com } from 'efd-cdb_backend-interfaces-ts'
import { Observable } from 'rxjs'
import { JsonApiRequest } from '../models/jsonApiRequest.model'
import { BaseService } from './base.service'

type CdbUserMinDTO = com.mocira.inside.cdb.client.dto.CdbUserMinDTO
type CdbDealerUserTeamDTO = com.mocira.inside.cdb.client.dto.CdbDealerUserGroupDTO
type CdbUserTeamDTO = com.mocira.inside.cdb.client.dto.CdbUserGroupDTO

@Injectable({
  providedIn: 'root'
})
export class DealerTeamsService extends BaseService {
  getUsersByDealerId (dealerId: number, parameters?): Observable<JsonApiResponseList<CdbUserMinDTO>> {
    const config = {
      params: parameters
    }
    return this.http.get<JsonApiResponseList<CdbUserMinDTO>>(`${this.apiUrl}/cdb/users/location/${dealerId}`, config)
  }

  getDealerTeamsByDealerId (dealerId: number): Observable<JsonApiResponseList<CdbDealerUserTeamDTO>> {
    return this.http.get<JsonApiResponseList<CdbDealerUserTeamDTO>>(`${this.apiUrl}/cdb/dealer/${dealerId}/user-groups`)
  }

  getDealerTeamByTeamId (teamId: number): Observable<JsonApiResponse<CdbUserTeamDTO>> {
    return this.http.get<JsonApiResponse<CdbUserTeamDTO>>(`${this.apiUrl}/cdb/user/groups/${teamId}`)
  }

  createDealerTeam (data: CdbUserTeamDTO): Observable<JsonApiResponse<CdbUserTeamDTO>> {
    const wrappedData = JsonApiRequest.of(data)
    return this.http.post<JsonApiResponse<CdbUserTeamDTO>>(`${this.apiUrl}/cdb/user/groups/`, wrappedData)
  }

  updateDealerTeam (data: CdbUserTeamDTO): Observable<JsonApiResponse<CdbUserTeamDTO>> {
    const wrappedData = JsonApiRequest.of(data, data.id)
    return this.http.put<JsonApiResponse<CdbUserTeamDTO>>(`${this.apiUrl}/cdb/user/groups/`, wrappedData)
  }

  deleteDealerTeam (teamId: number): Observable<JsonApiResponse<void>> {
    return this.http.delete<JsonApiResponse<void>>(`${this.apiUrl}/cdb/user/groups/${teamId}`)
  }

  findEmailByQuery (searchTerm: string): Observable<JsonApiResponse<CdbDealerUserTeamDTO>> {
    const params = {
      type: 'TEAM_MEMBERSHIP'
    }
    return this.http.get<JsonApiResponse<CdbDealerUserTeamDTO>>(
      `${this.apiUrl}/cdb/user/groups/email/${searchTerm}`, { params }
    )
  }
}
