import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { AuthService } from '../services/auth.service'
import { KcAuthService } from '../services/kc-auth.service'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'

/**
 * Check whether the user has an active login session.
 * If there is no redirect url, the guard will just return false.
 * If there is no redirectQueryParameter, the default name is 'returnUrl'.
 * ```
 * {
 *   path: 'dashboard',
 *   component: DashboardComponent,
 *   canActivate: [PermissionGuard],
 *   data: {
 *     authGuard: {
 *       redirect: 'route/to/redirect',
 *       redirectQueryParameter: 'returnUrl'
 *     }
 *   }
 * },
 * ```
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor (
    protected authService: AuthService,
    protected router: Router,
    protected kcAuthService: KcAuthService,
    protected keycloak: KeycloakService
  ) {}

  canActivate (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.checkLogin().pipe(
      map(result => {
        if (!result) {
          // get the redirect route from data
          const redirectUrl = next.data?.authGuard?.redirect
          const redirectReturnParameter =
            next.data?.authGuard?.redirectQueryParameter || 'returnUrl'
          if (!redirectUrl) {
            this.authService.logout()
            return this.router.parseUrl('login')
          }

          return this.router.parseUrl(
            `${redirectUrl}?${redirectReturnParameter}=${encodeURIComponent(
              this.getFullUrl(next)
            )}`
          )
        }

        return result
      })
    )
  }

  canActivateChild (
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.checkLogin().pipe(
      map(result => {
        if (!result) {
          // get the redirect route from data
          const redirectUrl = childRoute.data?.authGuard?.redirect
          const redirectReturnParameter =
            childRoute.data?.authGuard?.redirectQueryParameter || 'returnUrl'
          if (!redirectUrl) {
            this.authService.logout()
            return this.router.parseUrl('login')
          }

          return this.router.parseUrl(
            `${redirectUrl}?${redirectReturnParameter}=${encodeURIComponent(
              this.getFullUrl(childRoute)
            )}`
          )
        }

        return result
      })
    )
  }

  private checkLogin (): Observable<boolean> {
    if (!this.kcAuthService.isEnabled()) {
      return this.authService.getToken().pipe(
        mergeMap(token =>
          token ? this.authService.verifyToken(token) : of(false)
        ),
        catchError(e => of(false))
      )
    } else {
      return of(true)
    }
  }

  private getFullUrl (route: ActivatedRouteSnapshot) {
    const url = route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()).join('/'))
      .join('/')
    const queryParams = route.queryParamMap.keys
      .map(key => `${key}=${route.queryParams[key]}`)
      .join('&')
    return `${url}?${queryParams}`
  }
}
