import { Injectable } from '@angular/core'
import { com } from 'efd-cdb_backend-interfaces-ts'
import { Observable } from 'rxjs'
import { BaseService } from './base.service'

type CdbWorkingDayDTO = com.mocira.inside.cdb.client.dto.CdbWorkingDayDTO
type CdbWorkingHoursExceptionDTO =
  com.mocira.inside.cdb.client.dto.CdbWorkingHoursExceptionDTO

@Injectable({
  providedIn: 'root'
})

export class WorkingDayService extends BaseService {
  typesGetAll (): Observable<any> {
    return this.http.get(`${this.apiUrl}/cdb/work_activity_type/list`)
  }

  workdayGetAll (dealerId: number): Observable<CdbWorkingDayDTO[]> {
    return this.http.get<CdbWorkingDayDTO[]>(
      `${this.apiUrl}/cdb/working_day/list?dealerId=${dealerId}`
    )
  }

  workdayDelete (id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/cdb/working_day/${id}`)
  }

  holidayGetAll (country: string, region: string): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/cdb/holiday/list??country=${country}&region=${region}`
    )
  }

  getWorkingHoursExceptions (
    dealerId: number
  ): Observable<CdbWorkingHoursExceptionDTO[]> {
    return this.http.get<CdbWorkingHoursExceptionDTO[]>(
      `${this.apiUrl}/cdb/working_day/exceptions/dealer/${dealerId}`
    )
  }

  updateWorkingHours (
    workingDay: CdbWorkingDayDTO
  ): Observable<CdbWorkingDayDTO[]> {
    return this.http.put<CdbWorkingDayDTO[]>(
      `${this.apiUrl}/cdb/working_day`,
      workingDay
    )
  }

  updateWorkingDays (
    dealerId: number,
    workingDays
  ): Observable<CdbWorkingDayDTO[]> {
    return this.http.put<CdbWorkingDayDTO[]>(
      `${this.apiUrl}/cdb/working_day/dealer/${dealerId}`,
      workingDays
    )
  }

  saveWorkingHours (
    workingDay: CdbWorkingDayDTO
  ): Observable<CdbWorkingDayDTO[]> {
    return this.http.post<CdbWorkingDayDTO[]>(
      `${this.apiUrl}/cdb/working_day`,
      workingDay
    )
  }

  deleteWorkingHour (id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/cdb/working_day/${id}`)
  }

  deleteWorkingHours (workingHours): Observable<any> {
    const config = {
      params: { id: workingHours }
    }

    return this.http.delete(
      `${this.apiUrl}/cdb/working_day/working-days`,
      config
    )
  }
}
