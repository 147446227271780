import { ChangeDetectorRef, Component, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { TRANSLATION_PREFIX } from '@inside-hub-app/ef-ng-pp-client'
import {
  type RevolutionLink,
  type CustomerPortalConfig,
  type B2CConfig
} from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { SharedService } from '../../../services/shared.service'

@Component({
  selector: 'customer-portal-app-revolution-header',
  templateUrl: './revolution-header.component.html',
  providers: [{ provide: TRANSLATION_PREFIX, useValue: 'kc.' }]
})
export class RevolutionHeaderComponent {
  navbarLinks
  defaultLanguage: string
  countryCode: string

  @Input() includeLocalizationMenu = true

  @Input() isMobileLogin = false

  constructor (
    private readonly translateService: TranslateService,
    private readonly cdf: ChangeDetectorRef,
    private readonly remoteConfigService: EfRemoteConfigurationService<
    CustomerPortalConfig | B2CConfig
    >,
    private readonly sharedService: SharedService
  ) {
    this.navbarLinks = this.remoteConfigService.getArrayValues<RevolutionLink[]>(
      'revolution.links.navbar.links'
    ) 
    this.defaultLanguage = this.remoteConfigService.get('b2c.defaultLanguage')
    this.countryCode = this.remoteConfigService.get('country.code')
  }

  getNavbarLink (link: RevolutionLink): void {
    const language = this.sharedService.currentLanguage()
    return link[`urls.${language}`]
  }
}
