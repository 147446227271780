import { Component, type OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import {
  CptSharedDataService,
  IconService,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { type B2CConfig } from '@inside-hub-app/customer-portal-config'
import { TranslateService } from '@ngx-translate/core'
import { NGXLogger } from 'ngx-logger'
import { combineLatest, filter } from 'rxjs'
import packageJson from '../../package.json'
import { TitleService } from './services/title.service'
import {
  type RegistrationSource,
  SourceService
} from './services/source.service'

@Component({
  selector: 'customer-portal-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public appVersion = packageJson.version
  public registrationSource: RegistrationSource = 'WEB'
  title = ''

  sub = {
    transifexLanguageIsLoading: null,
    combineLatest: null
  }

  showTransifexSpinner = true
  currentTransifexLang
  transifexInitialized = false
  queryParamsLang

  constructor (
    private readonly translate: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly titleService: TitleService,
    private readonly sharedDataService: CptSharedDataService,
    private readonly logger: NGXLogger,
    private readonly transifexService: TransifexService,
    private readonly iconService: IconService,
    private readonly remoteConfigService: EfRemoteConfigurationService<B2CConfig>,
    private readonly sourceService: SourceService
  ) {
    this.translate.addLangs(
      this.remoteConfigService.get('b2c.supportedLanguages')
    )
    this.translate.setDefaultLang(
      this.remoteConfigService.get('b2c.defaultLanguage')
    )
    this.sharedDataService.setResourcesPath('.')

    this.transifexService.initializeTransifex(
      this.remoteConfigService.get('transifexConfig.token')
    )
  }

  ngOnInit (): void {
    this.iconService.registerIcons()
    this.logger.debug('cpt-auth Version: ', this.appVersion)
    this.titleService.title$.subscribe(title => {
      this.title = title
    })

    this.sub.transifexLanguageIsLoading =
      this.transifexService.transifexLanguageIsLoading.subscribe(loading => {
        this.showTransifexSpinner = loading
      })

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.route.snapshot.queryParams.source) {
          this.registrationSource = this.route.snapshot.queryParams.source
          this.sourceService.setSource(this.registrationSource)
        }
        if (this.route.snapshot.queryParams.lang) {
          this.queryParamsLang = this.route.snapshot.queryParams.lang
        }
      }
    })

    this.setTransifexLanguage()
  }

  setTransifexLanguage (): void {
    this.sub.combineLatest = combineLatest([
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)),
      this.transifexService.onTransifexInitialized
    ]).subscribe(res => {
      const transifex = res[1]
      const lang =
        this.route.snapshot.queryParams.lang ??
        this.queryParamsLang ??
        this.remoteConfigService.get('b2c.defaultLanguage')

      if (lang !== this.currentTransifexLang && transifex) {
        this.currentTransifexLang = lang
        this.transifexService.setLanguage(
          lang,
          this.remoteConfigService.get('transifexConfig.transifexLanguages'),
          this.remoteConfigService.get('country.code')
        )
      }
    })
  }
}
