import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { HubConfigurationService } from '@inside-hub-app/hub-configuration'
import { StorageService } from '@inside-hub-app/hub-storage'
import { STORAGE_PREFIX } from '../injection-tokens'

@Injectable({
  providedIn: 'root'
})

export class BaseService {
  apiUrl: string
  apiPpServiceUrl: string
  apiHubUrl: string
  dataUrl: string
  headers
  hubConfiguration = inject(HubConfigurationService)
  storage = inject(StorageService)
  http = inject(HttpClient)
  storagePrefix = inject(STORAGE_PREFIX)

  constructor () {
    this.apiUrl = this.hubConfiguration.get<string>('api.pp-url')
    this.apiPpServiceUrl = this.hubConfiguration.get<string>('api.pp-efit-permission-service-url')
    this.apiHubUrl = this.hubConfiguration.get<string>('api.hub-url')
    this.dataUrl = this.hubConfiguration.get<string>('api.inside-url')
    this.headers = { 'Content-Type': 'application/vnd.api+json' }
  }
}
