import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { type JsonApiResponse, type JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { type Observable } from 'rxjs'
import { EfDatatableSortDirection } from '../enum/efDatatable.enum'
import type {
  ApiUsersParameters,
  CdbDealerListDTO,
  CdbUserApiDTO,
  CdbUserApiSecretDTO,
  UserApiCreateDTO,
  UserApiUpdateDTO
} from '../models/api-users.model'
import { BaseService } from './base.service'

@Injectable({
  providedIn: 'root'
})
export class ApiUsersService extends BaseService {
  getApiUsers (parameters: ApiUsersParameters): Observable<JsonApiResponseList<CdbUserApiDTO>> {
    const params = this.setParams(parameters)
    return this.http.get<JsonApiResponseList<CdbUserApiDTO>>(`${this.apiUrl}/cdb/user/api`, { params })
  }

  createApiUser (data: UserApiCreateDTO): Observable<JsonApiResponse<CdbUserApiDTO>> {
    return this.http.post<JsonApiResponse<CdbUserApiDTO>>(`${this.apiUrl}/cdb/user/api`, data)
  }

  updateApiUser (data: UserApiUpdateDTO): Observable<JsonApiResponse<CdbUserApiDTO>> {
    return this.http.put<JsonApiResponse<CdbUserApiDTO>>(`${this.apiUrl}/cdb/user/api`, data)
  }

  manageApiUserStatus (userId: number, enabled: boolean): Observable<CdbUserApiDTO> {
    const data = {
      id: userId,
      enabled
    }
    return this.http.put<CdbUserApiDTO>(`${this.apiUrl}/cdb/user/api/activation-status`, { ...data })
  }

  getApiSecret (userId: number): Observable<JsonApiResponse<CdbUserApiSecretDTO>> {
    return this.http.get<JsonApiResponse<CdbUserApiSecretDTO>>(`${this.apiUrl}/cdb/user/api/${userId}/secret`)
  }

  regenerateApiSecret (userId: number): Observable<JsonApiResponse<CdbUserApiSecretDTO>> {
    return this.http.post<JsonApiResponse<CdbUserApiSecretDTO>>(`${this.apiUrl}/cdb/user/api/${userId}/secret/regenerate`, {})
  }

  getPartnersList (searchTerm: string): Observable<JsonApiResponseList<CdbDealerListDTO>> {
    const params = this.setPartnersParams(searchTerm)
    return this.http.get<JsonApiResponseList<CdbDealerListDTO>>(
      `${this.apiUrl}/cdb/dealer`, { params }
    )
  }

  private setPartnersParams (searchTerm: string): HttpParams {
    let params = new HttpParams()

    if (searchTerm !== undefined &&
      searchTerm !== null &&
      searchTerm.length > 0) {
      params = params.append('search', searchTerm)
    } else {
      params = params.append('page[size]', 10)
    }

    params = params.append('filter[enabled]', true)

    return params
  }

  private setParams (parameters: ApiUsersParameters): HttpParams {
    let params = new HttpParams()

    if (parameters?.search !== null &&
        parameters?.search !== undefined &&
        parameters?.search !== ''
    ) {
      params = params.append('search', `${parameters.search}`)
    }

    if (
      parameters?.enabled !== null &&
      parameters?.enabled !== undefined
    ) {
      params = params.append('filter[enabled]', parameters.enabled)
    }

    if (
      parameters?.roleIds !== null &&
      parameters?.roleIds !== undefined &&
      parameters?.roleIds.length > 0
    ) {
      parameters.roleIds.forEach((id) => {
        params = params.append('roleId', id)
      })
    }

    if (parameters?.partnerId !== null && parameters?.partnerId !== undefined) {
      params = params.append('partnerId', `${parameters.partnerId}`)
    }

    if (parameters?.pageSize !== null && parameters?.pageSize !== undefined) {
      params = params.append('page[size]', `${parameters.pageSize}`)
    }

    if (parameters?.pageNumber !== null && parameters?.pageNumber !== undefined) {
      params = params.append('page[number]', `${parameters.pageNumber}`)
    }

    if (parameters?.sort !== null && parameters?.sort !== undefined) {
      let sortValue = parameters.sort.column
      if (parameters.sort.direction === EfDatatableSortDirection.DESCENDING) {
        sortValue = `-${parameters.sort.column}`
      }
      params = params.append('sort', sortValue)
    }

    return params
  }
}
