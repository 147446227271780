import { Injectable } from '@angular/core'
import { HubConfigurationService } from '@inside-hub-app/hub-configuration'
import { Observable } from 'rxjs'
import type {
  FacebookDataResponse,
  FacebookLoginResource,
  FacebookPageResource,
  FacebookResponse,
  FacebookSubscribedPageResource,
  FacebookUserResource
} from '../models/facebook-service.model'

@Injectable({
  providedIn: 'root'
})
export class FacebookService {
  config = null
  libraryStartedLoading = false
  sdkUrl = 'https://connect.facebook.net/en_US/sdk.js'

  constructor(hubConfigurationService: HubConfigurationService) {
    this.config = hubConfigurationService.configuration.common.social.facebook
  }

  isLibraryLoaded() {
    // @ts-expect-error
    return window?.FB
  }

  getScope() {
    return this.config.scope
  }

  getConfig(parameter?: string): any {
    if (!this.config) {
      throw new Error('Unable to boot FB Service without FB configuration data')
    }

    if (!parameter) {
      return this.config
    }

    if (parameter && !this.config.hasOwnProperty(parameter)) {
      throw new Error('FB config: ' + parameter + ' does not exists!')
    }

    return this.config[parameter]
  }

  getApiVersion() {
    return this.getConfig('apiVersion')
  }

  getAppId() {
    return this.getConfig('appId')
  }

  removeLibrary() {
    // Destroy old API
    document
      .querySelectorAll('script[src^="' + this.sdkUrl + '"]')
      .forEach(script => {
        script.remove()
      })

    // @ts-expect-error
    if (window.FB) {
      // @ts-expect-error
      delete window.FB
    }
  }

  loadLibrary() {
    (function (d, s, id) {
      let js
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }

  user(): Observable<FacebookUserResource> {
    return new Observable(observer => {
      window['FB'].api(
        '/me',
        {
          fields: 'last_name, first_name, email'
        },
        response => {
          if (!response || response.error) {
            observer.error(response.error)
            observer.complete()
          } else {
            observer.next(response)
            observer.complete()
          }
        }
      )
    })
  }

  login(scope: any): Observable<FacebookLoginResource> {
    return new Observable(observer => {
      window['FB'].login((response: FacebookLoginResource) => {
        if (response.authResponse) {
          observer.next(response)
          observer.complete()
        } else {
          observer.error(response)
          observer.complete()
        }
      }, scope)
    })
  }

  logout(): Observable<FacebookResponse> {
    return new Observable(observer => {
      window['FB'].logout((response: FacebookResponse) => {
        observer.next(response)
        observer.complete()
      })
    })
  }

  getUserAccessToken() {
    return window['FB'].getAuthResponse()['accessToken']
  }

  getSubscribedApps(
    page: FacebookPageResource
  ): Observable<FacebookDataResponse<FacebookSubscribedPageResource>> {
    return new Observable(observer => {
      window['FB'].api(
        '/' + page.id + '/subscribed_apps',
        { access_token: page.access_token },
        response => {
          if (!response || response.error) {
            observer.error(response.error)
            observer.complete()
          } else {
            observer.next(response)
            observer.complete()
          }
        }
      )
    })
  }

  subscribe(page: FacebookPageResource): Observable<FacebookResponse> {
    return new Observable(observer => {
      window['FB'].api(
        '/' + page.id + '/subscribed_apps',
        'post',
        {
          access_token: page.access_token,
          subscribed_fields: 'leadgen'
        },
        response => {
          if (!response || response.error) {
            observer.error(response.error)
            observer.complete()
          } else {
            observer.next(response)
            observer.complete()
          }
        }
      )
    })
  }

  unsubsribe(page: FacebookPageResource): Observable<FacebookResponse> {
    return new Observable(observer => {
      window['FB'].api(
        '/' + page.id + '/subscribed_apps',
        'delete',
        {
          access_token: page.access_token
        },
        response => {
          if (!response || response.error) {
            observer.error(response.error)
            observer.complete()
          } else {
            observer.next(response)
            observer.complete()
          }
        }
      )
    })
  }

  getPages(
    parameters?: any
  ): Observable<FacebookDataResponse<FacebookPageResource>> {
    const query = []
    if (parameters) {
      for (const key in parameters) {
        if (parameters.hasOwnProperty(key)) {
          query.push(key + '=' + parameters[key])
        }
      }
    }
    return new Observable(observer => {
      window['FB'].api(
        '/me/accounts' + (query.length > 0 ? '?' + query.join('&') : ''),
        response => {
          if (!response || response.error) {
            observer.error(response.error)
            observer.complete()
          } else {
            observer.next(response)
            observer.complete()
          }
        }
      )
    })
  }
}
