import { Injectable } from '@angular/core'
import { BaseService } from './base.service'
import { HttpParams } from '@angular/common/http'
import { type Observable } from 'rxjs'
import type { AuditListDto, ChangelogParameters } from '../models/changelog.model'

@Injectable({
  providedIn: 'root'
})

export class ChangelogService extends BaseService {
  getEntityChangelogData (parameters: ChangelogParameters): Observable<AuditListDto> {
    const params = this.setParams(parameters)
    return this.http.get<AuditListDto>(`${this.apiUrl}/v1/audit`, { params })
  }

  setParams (parameters: ChangelogParameters): HttpParams {
    let params = new HttpParams()

    if (parameters?.action !== null && parameters?.action !== undefined) {
      params = params.append('action', `${parameters.action}`)
    }

    if (parameters?.target !== null && parameters?.target !== undefined) {
      params = params.append('target', `${parameters.target}`)
    }

    if (parameters?.crud !== null && parameters?.crud !== undefined) {
      params = params.append('crud', `${parameters.crud}`)
    }

    if (parameters?.pageSize !== null && parameters?.pageSize !== undefined) {
      params = params.append('pageSize', `${parameters.pageSize}`)
    }

    if (parameters?.prevPageCursor !== null && parameters?.prevPageCursor !== undefined) {
      params = params.append('prevPageCursor', `${parameters.prevPageCursor}`)
    }

    if (parameters?.nextPageCursor !== null && parameters?.nextPageCursor !== undefined) {
      params = params.append('nextPageCursor', `${parameters.nextPageCursor}`)
    }

    return params
  }
}
