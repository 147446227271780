import { Injectable } from '@angular/core'
import { BaseService } from './base.service'
import { hr } from 'efd-cdb_backend-interfaces-ts'
import { Observable } from 'rxjs'
import { JsonApiResponse, JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { HttpParams } from '@angular/common/http'
import { JsonApiRequest } from '../models/jsonApiRequest.model'

type CdbScheduledUpdateDTO = hr.emilfreydigital.cdb.dto.CdbScheduledUpdateDTO
type EUpdateEntityType = hr.emilfreydigital.cdb.model.ScheduledUpdate.EUpdateEntityType
type EUpdateStatus = hr.emilfreydigital.cdb.model.ScheduledUpdate.EUpdateStatus

@Injectable({
  providedIn: 'root'
})

export class ScheduledUpdatesService extends BaseService {
  getScheduledUpdates (entityId: number, entityType: EUpdateEntityType, updateStatus: EUpdateStatus): Observable<JsonApiResponseList<CdbScheduledUpdateDTO>> {
    const params = new HttpParams({
      fromObject: {
        'filter[entityId]': entityId,
        'filter[type]': entityType,
        'filter[status]': updateStatus
      }
    })

    return this.http.get<JsonApiResponseList<CdbScheduledUpdateDTO>>(`${this.apiUrl}/cdb/scheduled-updates`, { params })
  }

  createScheduledUpdates (data: CdbScheduledUpdateDTO): Observable<JsonApiResponse<CdbScheduledUpdateDTO>> {
    const wrappedData = JsonApiRequest.of(data)

    return this.http.post<JsonApiResponse<CdbScheduledUpdateDTO>>(`${this.apiUrl}/cdb/scheduled-updates`, wrappedData)
  }

  updateScheduledUpdates (data: CdbScheduledUpdateDTO): Observable<JsonApiResponse<CdbScheduledUpdateDTO>> {
    const wrappedData = JsonApiRequest.of(data)

    return this.http.put<JsonApiResponse<CdbScheduledUpdateDTO>>(`${this.apiUrl}/cdb/scheduled-updates`, wrappedData)
  }

  deleteScheduledUpdates (scheduledUpdateID: number): Observable<JsonApiResponse<void>> {
    return this.http.delete<JsonApiResponse<void>>(`${this.apiUrl}/cdb/scheduled-updates/${scheduledUpdateID}`)
  }
}
