import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ResetPasswordService } from '@inside-hub-app/customer-portal-b2c-client'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'customer-portal-revolution-app-forgot-password-verify-view',
  templateUrl: './revolution-forgot-password-verify-view.component.html'
})
export class RevolutionForgotPasswordVerifyViewComponent implements OnInit {
  langLoaded: boolean
  submitting: boolean

  constructor(
    private readonly resetPasswordService: ResetPasswordService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translate: TranslateService
  ) {
    this.route.queryParams.subscribe(value => {
      translate.use(value.lang)
    })
  }

  public authenticated = true
  public loading = true
  private redirectUrl: string
  public disabled = false

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => {
      this.translate.use(param.lang).subscribe(response => {
        this.langLoaded = true
        this.resetPasswordService
          .verify(
            this.route.snapshot.params.verificationId,
            param.lang || this.translate.getDefaultLang()
          )
          .subscribe(
            res => {
              this.redirectUrl = res.redirectUrl
            },
            errorResponse => {
              window.history.state.code = errorResponse?.error?.code
              window.history.state.message = errorResponse?.error?.message
              this.router.navigate(['error'], { skipLocationChange: true })
            }
          )
      })
    })
  }

  public redirect() {
    this.submitting = true
    window.location.href = this.redirectUrl
  }
}
