import { Injectable } from '@angular/core'
import {
  JsonApiResponse,
  JsonApiResponseList
} from '@inside-hub-app/ef-ng-json-api'
import {
  JsonApiRequest,
  JsonApiRequestData
} from '../models/jsonApiRequest.model'
import { BaseService } from './base.service'
import { com } from 'efd-cdb_backend-interfaces-ts'
import { HttpParams } from '@angular/common/http'
import { UserGroupFilters } from '../models/user-groups.model'

type CdbUserGroupDTO = com.mocira.inside.cdb.client.dto.CdbUserGroupDTO

@Injectable({
  providedIn: 'root'
})
export class UserGroupsService extends BaseService {
  public getUserGroups(filters?: UserGroupFilters) {
    let params = new HttpParams()
    if (filters) {
      if (filters?.name)
        params = params.append('filter[name]', String(filters.name))
      if (filters?.id) params = params.append('filter[id]', String(filters.id))
      if (filters?.usersId)
        params = params.append('filter[id]', filters.usersId.toString())
      if (filters?.dealerId)
        params = params.append('filter[dealer.id]', String(filters.dealerId))
      if (filters?.groupId)
        params = params.append('filter[group.id]', String(filters.groupId))
      if (filters?.type) params = params.append('filter[type]', filters.type)
    }
    return this.http.get<JsonApiResponseList<CdbUserGroupDTO>>(
      `${this.apiUrl}/cdb/user/groups`,
      { params }
    )
  }

  public addUserGroup(userGroup: CdbUserGroupDTO) {
    const data = new JsonApiRequest(new JsonApiRequestData(userGroup))
    return this.http.post<JsonApiResponse<CdbUserGroupDTO>>(
      `${this.apiUrl}/cdb/user/groups`,
      data
    )
  }

  public updateUserGroup(dealerId: number, userGroup: CdbUserGroupDTO) {
    const data = new JsonApiRequest(new JsonApiRequestData(userGroup, dealerId))
    return this.http.put<JsonApiResponse<CdbUserGroupDTO>>(
      `${this.apiUrl}/cdb/user/groups`,
      data
    )
  }

  public deleteUserGroup(groupId: number) {
    return this.http.delete<JsonApiResponse<CdbUserGroupDTO>>(
      `${this.apiUrl}/cdb/user/groups/${groupId}`
    )
  }
}
