export enum FunctionTrainingEducationTypes {
  TRAINING_EDUCATION = 'EDUCATION',
  MANDATORY_FUNCTIONS = 'FUNCTION'
}

export enum FunctionTrainingEducationCategory {
  ALL = 'all',
  SALES = 'sales',
  AFTERSALES = 'after-sales',
  MARKETING = 'marketing'
}
