import { Injectable } from '@angular/core'
import { AbstractControl, ValidatorFn } from '@angular/forms'
import * as _ from 'lodash'
import { HubConfigurationService } from '@inside-hub-app/hub-configuration'
import { PhoneNumberUtil } from 'google-libphonenumber'

@Injectable({
  providedIn: 'root'
})
export class PhoneNumberValidatorService {
  phoneNumberUtil

  constructor (
    private readonly hubConfiguration: HubConfigurationService) {
    this.phoneNumberUtil = PhoneNumberUtil.getInstance()
  }

  // Validators
  phoneNumberValidator (selectedCountry?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let validNumber = false
      try {
        const country = []
        if (selectedCountry) {
          country.push(selectedCountry)
        } else {
          _.map(this.hubConfiguration.get<string[]>('modules.partner-profile.country'), function (value) {
            country.push(value)
          })
        }

        _.forEach(country, el => {
          if (this.phoneNumberUtil.isValidNumberForRegion(this.phoneNumberUtil.parse(control.value, el), el)) {
            validNumber = true
          }
        })
      } catch (e) { }

      if (validNumber || !control.value) {
        return null
      } else {
        return { wrongNumber: control.value }
      }
    }
  }
}
