<div class="p-4 lg:p-10 flex-1 flex flex-col">
  <div class="welcome flex flex-col gap-6 py-6">
    <div class="font-efSans-secondary font-semibold text-sm uppercase">
      {{ 'customer-portal' | sharedTranslate }}
    </div>
    <div class="font-canelablack-primary text-4xl md:text-5xl">
      <T
        str="Check your inbox"
        key="customerPortal.b2c.register-sent.title"
        tags="cpt-auth, 3.1"
      ></T>
    </div>
    <div
      class="text-mat-ef-revolution-primary-500 font-efSans text-xl font-light"
    >
      <T
        str="E-Mail confirmed"
        key="customerPortal.b2c.confirm-you-mail"
        tags="cpt-auth, 3.1"
      ></T>
    </div>
  </div>

  <div class="grid gap-6">
    <mat-card class="card-flex-rounded flex flex-row gap-6">
      <mat-icon
        class="card-flex-rounded-icon"
        matBadge="1"
        matBadgeColor="accent"
        matBadgeSize="medium"
        svgIcon="move_to_inbox"
      ></mat-icon>
      <p class="font-efSans text-mat-ef-revolution-primary-500">
        <T
          str="We've just sent you a confirmation email at { email }."
          key="customerPortal.b2c.register-sent.sent-to"
          tags="cpt-auth, 3.1"
          [vars]="{email}"
        ></T>
      </p>
    </mat-card>
    <p class="font-efSans text-mat-ef-revolution-primary-500">
      <T
        str="Select the Confirm link in the email to create an account."
        key="customerPortal.b2c.register-sent.click"
        tags="cpt-auth, 3.1"
      ></T>
    </p>
  </div>
</div>
