import { Injectable, Inject } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, of, Observer } from 'rxjs'
import { tap } from 'rxjs/operators'
import { KeycloakEvent, KeycloakEventType, KeycloakService } from '@emilfreydigital/keycloak-angular'
import { com } from 'inside-backend-interfaces-ts'
import { HubConfigurationService } from '@inside-hub-app/hub-configuration'
import { StorageService } from '@inside-hub-app/hub-storage'
import { STORAGE_PREFIX } from '../injection-tokens'
import { NGXLogger } from 'ngx-logger'
import { AuthService } from './auth.service'

type TokenDTO = com.mocira.inside.cdb.client.dto.security.TokenDTO

enum LogoutUri {
  LOGOUT = '/login/logout'
}

@Injectable({
  providedIn: 'root'
})

export class KcAuthService {
  _loginInfo: TokenDTO

  constructor (
    @Inject(STORAGE_PREFIX) private readonly storagePrefix: string,
    private readonly service: KeycloakService,
    private readonly hubConfigurationService: HubConfigurationService,
    private readonly storageService: StorageService,
    private readonly logger: NGXLogger,
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  observeLoginInfo (): Observable<TokenDTO> {
    return null
  }

  initListeners (): void {
    this.service.keycloakEvents$.subscribe((val: KeycloakEvent) => {
      switch (val.type) {
        case KeycloakEventType.OnAuthError: {
          this.setRedirectUri()
          break
        }
        case KeycloakEventType.OnTokenExpired:
          this.refreshToken().subscribe((response: boolean) => {
            if (response) {
              this.getToken().subscribe((token: string) => {
                this.authService.setToken(token)
              })
            }
          })
          break
        case KeycloakEventType.OnAuthRefreshError: {
          this.setRedirectUri()
          break
        }
        case KeycloakEventType.OnAuthSuccess:
          if ((window as any).cordova && this.router.url === '/login/kc-logout') {
            this.router.navigate(['/login'])
          }
          break
        case KeycloakEventType.OnAuthLogout: {
          this.setRedirectUri()
          break
        }
      }
    })
  }

  cleanTokenAndLoginInfo (): void {
    this.authService.removeToken()
    this.authService.removeLoginInfo()
    this.clearToken()
    this.removeLoginInfo()
  }

  getRedirectUri (): string {
    const url = location.origin
    const redirectUri = url.includes('localhost') ? `${url}/login` : `${url}/app/login`

    return redirectUri
  }

  isEnabled (): boolean {
    return this.hubConfigurationService.get<boolean>('hub.auth.keycloak.enabled')
  }

  getToken (): Observable<string> {
    return new Observable<string>((sub) => {
      this.service.getToken()
        .then((token) => {
          sub.next(token)
        })
        .catch((e) => {
          sub.error(e)
        })
    })
  }

  removeToken (): Observable<undefined> {
    return null
  }

  setLoginInfo (loginInfo: TokenDTO): Observable<void> {
    this._loginInfo = loginInfo
    return this.storageService.set(`${this.storagePrefix}loginInfo`, loginInfo)
  }

  getLoginInfo (): Observable<TokenDTO> {
    if (this._loginInfo) {
      return of(this._loginInfo)
    }

    return this.storageService.get(`${this.storagePrefix}loginInfo`).pipe(
      tap(loginInfo => {
        this._loginInfo = loginInfo
      })
    )
  }

  removeLoginInfo (): void {
    this._loginInfo = null
    this.storageService.delete(`${this.storagePrefix}loginInfo`).subscribe(() => {})
  }

  refreshToken (): Observable<boolean> {
    return new Observable<boolean>((sub) => {
      this.service.updateToken()
        .then((res) => { sub.next(res) })
        .catch((err) => { sub.error(err) })
    })
  }

  login (): Observable<TokenDTO> {
    return new Observable<TokenDTO>((sub) => {
      this.service.login()
        .then((res) => { sub.next(null) })
        .catch((err) => { sub.error(err) })
    })
  }

  verifyToken (): Observable<boolean> {
    return new Observable<boolean>((observer: Observer<boolean>) => {
      observer.next(!this.service.isTokenExpired())
    })
  }

  logout (): Observable<void> {
    return new Observable<void>((sub) => {
      let url

      // Setup redirect url and client id
      if (window.location.host.includes('localhost')) {
        url = `${window.location.origin}/login`
        this.service.getKeycloakInstance().clientId = this.hubConfigurationService.get<string>('hub.auth.keycloak.client-id-localhost')
      } else {
        url = `${window.location.origin}/app/login`
        this.service.getKeycloakInstance().clientId = this.hubConfigurationService.get<string>('hub.auth.keycloak.client-id')
      }

      // Handle cordova keycloak logout with iframe because of bug in keycloak.js related to redirect url after logout
      if (window['cordova']) {
        const url = this.hubConfigurationService.get<string>('hub.auth.keycloak.url')
        const realm = this.hubConfigurationService.get<string>('hub.auth.keycloak.realm')
        const redirectUri = 'ionic://localhost'
        const idToken = this.service.getKeycloakInstance().idToken

        const logoutUrl = `${url}realms/${realm}/protocol/openid-connect/logout?post_logout_redirect_uri=${encodeURIComponent(redirectUri)}&id_token_hint=${encodeURIComponent(idToken)}`

        const ifrm = document.createElement('iframe')
        ifrm.setAttribute('src', logoutUrl)
        ifrm.style.width = '1px'
        ifrm.style.height = '1px'
        document.body.appendChild(ifrm)

        setTimeout(() => {
          sub.next(undefined)
          this.cleanTokenAndLoginInfo()
          document.body.removeChild(ifrm)
        }, 3000)
      } else {
        this.service.logout(url)
          .then(() => {
            sub.next(undefined)
            this.cleanTokenAndLoginInfo()
          })
          .catch((err) => {
            sub.error(err)
          })
      }
    })
  }

  clearToken (): void {
    this.service.clearToken()
  }

  setRedirectUri (): void {
    let url
    if (window.location.host.includes('localhost')) {
      url = `${window.location.origin}/login`
    } else {
      url = `${window.location.origin}/app/login`
    }

    const pathname = window.location.pathname.replace('/app', '')
    const returnUrl = `?returnUrl=${encodeURIComponent(pathname)}`
    if (!window['cordova']) {
      if (pathname !== LogoutUri.LOGOUT) {
        this.service.getKeycloakInstance().redirectUri = url + returnUrl
      } else {
        this.service.getKeycloakInstance().redirectUri = url
      }
    }
  }
}
