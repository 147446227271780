import { Injectable } from '@angular/core'
import { JsonApiResponse } from '@inside-hub-app/ef-ng-json-api'
import { com } from 'efd-cdb_backend-interfaces-ts'
import { Observable } from 'rxjs'
import { JsonApiRequest } from '../models/jsonApiRequest.model'
import { BaseService } from './base.service'

type CdbDealerLeadsAttributesDTO = com.mocira.inside.cdb.client.dto.CdbDealerLeadsAttributesDTO

@Injectable({
  providedIn: 'root'
})
export class DealerLeadsConfigurationService extends BaseService {
  getDealerLeadsConfiguration (dealerId: number): Observable<JsonApiResponse<CdbDealerLeadsAttributesDTO>> {
    return this.http.get<JsonApiResponse<CdbDealerLeadsAttributesDTO>>(`${this.apiUrl}/cdb/dealer/${dealerId}/leads-configuration`)
  }

  updateDealerLeadsConfiguration (data: CdbDealerLeadsAttributesDTO, dealerId: number): Observable<JsonApiResponse<CdbDealerLeadsAttributesDTO>> {
    const wrappedData = JsonApiRequest.of(data, data.id)
    return this.http.put<JsonApiResponse<CdbDealerLeadsAttributesDTO>>(`${this.apiUrl}/cdb/dealer/${dealerId}/leads-configuration`, wrappedData)
  }
}
