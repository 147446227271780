export enum DealerAttributeCategory {
  OTHER = 'other'
}

export enum DealerAttributeName {
  IMPRESSUM_URL = 'impressum_url',
  DATA_PROTECTION_URL = 'privacy_policy_url'
}

export interface DealerAttribute {
  name: DealerAttributeName
  value: string
  category: DealerAttributeCategory
}

export interface DealerAttributeSave {
  value: string
  categoryId: number
  entityId: number
}

export interface DealerAttributeUpdate {
  id: number
  value: string
}
