import { Inject, Injectable } from '@angular/core'
import { TranslateService as NgxTranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { TRANSLATION_PREFIX } from '../injection-tokens'

@Injectable({
  providedIn: 'any'
})
export class PpTranslateService {
  constructor (
    private readonly translateService: NgxTranslateService,
    @Inject(TRANSLATION_PREFIX) private readonly translationPrefix: string
  ) {}

  umlauts = [
    { umlaut: '\u00dc', replacement: 'U' },
    { umlaut: '\u00fc', replacement: 'u' },
    { umlaut: '\u00c4', replacement: 'A' },
    { umlaut: '\u00e4', replacement: 'a' },
    { umlaut: '\u00d6', replacement: 'O' },
    { umlaut: '\u00f6', replacement: 'o' },
    { umlaut: '\u00df', replacement: 'ss' }
  ]

  public get (
    key: string | string[],
    interpolateParams?: Object,
    prefix?: string
  ): Observable<any> {
    if (prefix === undefined) {
      prefix = this.translationPrefix
    }

    if (typeof key === 'string') {
      const keyWithPrefix = `${prefix}${this.replaceUmlaute(key)}`
      return this.translateService.get(keyWithPrefix, interpolateParams).pipe(
        map((translation: string) => {
          if (translation === keyWithPrefix) {
            return this.replaceUmlaute(key)
          }

          return translation
        })
      )
    }

    const keysWithPrefixes = key.map(
      key => `${prefix}${this.replaceUmlaute(key)}`
    )
    return this.translateService.get(keysWithPrefixes, interpolateParams).pipe(
      map((translations: { [keyWithPrefix: string]: string }) => {
        const translationsWithoutPrefix: any = {}
        for (const keyWithPrefix of Object.keys(translations)) {
          const keyWithoutPrefix = keyWithPrefix.substring(prefix.length)
          translationsWithoutPrefix[keyWithoutPrefix] =
            translations[keyWithPrefix]

          if (translations[keyWithPrefix] === keyWithPrefix) {
            translationsWithoutPrefix[keyWithoutPrefix] = keyWithoutPrefix
          }
        }

        return translationsWithoutPrefix
      })
    )
  }

  replaceUmlaute (str: string) {
    let replacedString = str
    this.umlauts.forEach(item => {
      replacedString = replacedString.replace(
        new RegExp(item.umlaut, 'g'),
        item.replacement
      )
    })
    return replacedString
  }
}
