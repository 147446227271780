import type { TrainingAdministrationRoles, TrainingAdministrationCategory } from '../enum/training-administration.enum'
import { type EfDatatableSortEvent } from './efDatatable.model'

export interface TrainingAdministrationParameters {
  status?: TrainingAdministrationCategory
  brandId?: number | number[]
  language?: string
  pageNumber?: number
  size?: number
  sort?: EfDatatableSortEvent
  search?: string
  managedBy?: TrainingAdministrationRoles
}
