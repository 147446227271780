import { Injectable } from '@angular/core'
import { B2CModuleConfig } from '../b2c.module'
import { HttpClient } from '@angular/common/http'
import { User } from './user.service'
import { Observable } from 'rxjs'
import { hr as cpt } from 'efd-b2c-backend-interfaces-ts'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'

export type DealerCreateOrUpdateUserRequest =
  cpt.emilfreydigital.b2c_auth_server.rest.admin.dto.UserAccountDTO
export type BusinessCreateOrUpdateUserRequest =
  cpt.emilfreydigital.b2c_auth_server.rest.admin.dto.BusinessRelatedAccountDTO
export type DealerCreatedUserVerifyResponse =
  cpt.emilfreydigital.b2c_auth_server.rest.common.dto.VerificationDTO

/*
  This service requires authentication with a Partner Profile token.
*/
@Injectable({
  providedIn: 'root'
})
export class DealerService {
  constructor(
    private readonly config: B2CModuleConfig,
    private readonly http: HttpClient,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('b2c.baseUrl')
    this.apiKey = this.config.apiKey
    this.fallbackApiKey = this.remoteConfigService.get('b2c.apiKey')
  }

  private readonly apiUrl
  private readonly apiKey
  private readonly fallbackApiKey

  public getUser(
    id: number,
    ppToken: string,
    apiKey: string = null
  ): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/admin/user/${id}`, {
      headers: {
        'api-key': apiKey || this.apiKey || this.fallbackApiKey,
        'x-auth-token': ppToken
      }
    })
  }

  public updateUser(
    id: number,
    request: DealerCreateOrUpdateUserRequest,
    ppToken: string,
    apiKey: string = null
  ): Observable<User> {
    return this.http.put<User>(`${this.apiUrl}/admin/account/${id}`, request, {
      headers: {
        'api-key': apiKey || this.apiKey || this.fallbackApiKey,
        'x-auth-token': ppToken
      }
    })
  }

  public updateBusinessUser(
    id: number,
    request: BusinessCreateOrUpdateUserRequest,
    ppToken: string,
    apiKey: string = null
  ): Observable<BusinessCreateOrUpdateUserRequest> {
    return this.http.post<BusinessCreateOrUpdateUserRequest>(
      `${this.apiUrl}/admin/account/business`,
      request,
      {
        headers: {
          'api-key': apiKey || this.apiKey || this.fallbackApiKey,
          'x-auth-token': ppToken
        }
      }
    )
  }

  public createUser(
    request: DealerCreateOrUpdateUserRequest,
    ppToken: string,
    apiKey: string = null
  ): Observable<User> {
    return this.http.post<User>(`${this.apiUrl}/admin/account`, request, {
      headers: {
        'api-key': apiKey || this.apiKey || this.fallbackApiKey,
        'x-auth-token': ppToken
      }
    })
  }

  public verify(
    token: string,
    apiKey: string = null,
    lang: string = null
  ): Observable<DealerCreatedUserVerifyResponse> {
    return this.http.get<DealerCreatedUserVerifyResponse>(
      `${this.apiUrl}/admin/activation/verify/${token}?lang=${lang}`,
      {
        headers: {
          'api-key': apiKey || this.apiKey || this.fallbackApiKey
        }
      }
    )
  }
}
