import {
  AfterViewChecked,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnDestroy
} from '@angular/core'
import { TranslateDirective, TranslateService } from '@ngx-translate/core'

@Directive({
  selector: '[ppTranslate]'
})
export class PpTranslateDirective implements AfterViewChecked, OnDestroy {
  @Input() set ppTranslate (key: string) {
    this.translateDirective.key = key
  }

  @Input() set ppTranslateParams (params: any) {
    this.translateDirective.translateParams = params
  }

  private translateDirective: TranslateDirective

  constructor (
    translateService: TranslateService,
    element: ElementRef<any>,
    _ref: ChangeDetectorRef
  ) {
    this.translateDirective = new TranslateDirective(
      translateService,
      element,
      _ref
    )
  }

  ngAfterViewChecked (): void {
    this.translateDirective.ngAfterViewChecked()
  }

  ngOnDestroy (): void {
    this.translateDirective.ngOnDestroy()
  }
}
