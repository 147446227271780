<!-- if registration source is 100 web-->
<ng-container *ngIf="registrationSource==='100_WEB'">
  <router-outlet></router-outlet>
</ng-container>

<ng-container *ngIf="registrationSource!=='100_WEB'">
  <customer-portal-app-revolution-header
  *ngIf="countryCode"
  [ngClass]="{ 'md:hidden': countryCode === 'de' }"
  [includeLocalizationMenu]="false"
>
</customer-portal-app-revolution-header>
<div class="w-full grid grid-cols-1 lg:grid-cols-12">
  <div
    class="hidden md:block md:max-h-screen md:col-span-5"
    *ngIf="countryCode === 'ch' || !countryCode"
  >
    <customer-portal-app-revolution-hero></customer-portal-app-revolution-hero>
  </div>
  <!-- prettier-ignore -->
  <customer-portal-lib-container
    class="hidden md:block md:min-h-[280px] lg:max-h-full lg:col-span-5 bg-cover bg-top md:bg-center"
    *ngIf="countryCode === 'de' || !countryCode"
    [texts]="[
    'The easiest way to care for your vehicles' | translate:{ _key: 'customerPortal.b2c.jumbo.text.1', _tags: 'cpt-auth, 3.1'},
    'Car service and leasing data at a glance' | translate:{ _key: 'customerPortal.b2c.jumbo.text.2', _tags: 'cpt-auth, 3.1'},
    'Easy appointments and advanced services' | translate:{ _key: 'customerPortal.b2c.jumbo.text.3', _tags: 'cpt-auth, 3.1'}
    ]"
  >
  </customer-portal-lib-container>
  <div class="md:col-span-1"></div>
  <div class="md:col-span-6 min-h-[700px] flex flex-col md:justify-center">
    <router-outlet></router-outlet>
  </div>
</div>
<span *ngIf="countryCode === 'ch' || !countryCode">
  <customer-portal-app-revolution-footer></customer-portal-app-revolution-footer>
</span>

<span *ngIf="countryCode === 'de' || !countryCode">
  <customer-portal-lib-footer-new></customer-portal-lib-footer-new>
</span>
</ng-container>


