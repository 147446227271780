import { LocationStrategy } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { CptSharedDataService } from '../../services/kc-shared-data.service'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
@Component({
  selector: 'customer-portal-lib-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {
  constructor(
    private readonly sharedData: CptSharedDataService,
    private readonly location: LocationStrategy,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {}

  @Input('texts')
  public jumboTexts: string[] = []

  public isLoginPage = false
  public background = 'assets/images/background.jpg'
  public backgroundLogin = 'assets/background_login.jpg'
  public class: string
  public country

  ngOnInit() {
    this.isLoginPage = this.sharedData.getView() === 'login'
    this.class =
      this.location.getBaseHref() === '/dealer-invite/' ? 'jumbo-full' : ''
    this.country = this.remoteConfigService.get('country.code') || ''
  }
}
