import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { RegisterService } from '@inside-hub-app/customer-portal-b2c-client'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'

@Component({
  selector: 'customer-portal-app-request-business-sent-view',
  templateUrl: './request-business-sent-view.component.html'
})
export class RequestBusinessSentViewComponent implements OnInit {
  constructor(
    private readonly registerService: RegisterService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly keycloak: KeycloakService
  ) {}

  public authenticated = true
  public email: string
  public resendDisabled: boolean

  ngOnInit(): void {
    this.email = this.route.snapshot.params.email
    this.authenticated = this.keycloak.isLoggedIn()
  }

  public login(): void {
    this.keycloak.login().catch(e => {
      console.error(e)
    })
  }
}
