import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy
} from '@angular/core'
import { MatCalendar } from '@angular/material/datepicker'
import { DateAdapter } from '@angular/material/core'
import {
  MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS,
  MatLegacyDateFormats as MatDateFormats
} from '@angular/material/legacy-core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import * as moment from 'moment'

@Component({
  selector: 'customer-portal-app-custom-calendar',
  styles: [
    `
      .example-header {
        display: flex;
        padding: 0.5em;
      }

      .example-header button:disabled {
        color: grey;
        background: transparent;
        border: none;
      }

      .example-header-label {
        flex: 1;
        height: 1.3em;
        font-weight: 500;
        text-align: center;
        display: grid;
        grid-template-columns: 1fr auto;
        cursor: pointer;
      }

      .example-double-arrow .mat-icon {
        margin: -22%;
      }
      .example-header {
        color: #003899;
        height: 48px;
        font-size: 20px;
      }
    `
  ],
  template: `
    <div class="example-header" (load)="(periodLabel)">
      <button
        [disabled]="disableLast"
        mat-icon-button
        (click)="previousClicked()"
      >
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span (click)="yearChange()" class="example-header-label"
        >{{ periodLabel
        }}<mat-icon class="icon">arrow_drop_down</mat-icon></span
      >
      <button [disabled]="disableNext" mat-icon-button (click)="nextClicked()">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomCalendarComponent<D> implements OnDestroy {
  private readonly _destroyed = new Subject<void>()
  public disableNext = false
  public disableLast = false

  constructor(
    private _calendar: MatCalendar<D>,
    private readonly _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private readonly _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck())
    this.disableArrows()
  }

  ngOnDestroy() {
    this._destroyed.next()
    this._destroyed.complete()
  }

  get periodLabel() {
    const today = moment(new Date())
    const current = moment(this._calendar.activeDate)
    this.disableNext =
      this._calendar.maxDate &&
      today.month() === current.month() &&
      today.year() - 18 === current.year()
    return this._dateAdapter.format(
      this._calendar.activeDate,
      this._dateFormats.display.monthYearLabel
    )
  }

  disableArrows() {
    const current = moment(this._calendar.activeDate)
    this.disableLast =
      current.year() - 24 <= 1900 ||
      (this._calendar.minDate &&
        moment(this._calendar.minDate).month() === current.month() &&
        moment(this._calendar.minDate).year() === current.year())
  }

  yearChange() {
    this._calendar.currentView !== 'multi-year'
      ? (this._calendar.currentView = 'multi-year')
      : (this._calendar.currentView = 'month')
  }

  previousClicked() {
    const mode = this._calendar.currentView === 'month' ? 'month' : 'year'
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -24)
    this.disableArrows()
  }

  nextClicked() {
    const mode = this._calendar.currentView === 'month' ? 'month' : 'year'
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 24)
    this.disableArrows()
  }
}
