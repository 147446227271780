<div
  class="p-4 md:p-10 flex-1 flex flex-col align-middle text-mat-ef-revolution-primary-500"
>
  <div class="verify flex flex-col gap-6 md:gap-10">
    <h2 class="font-canelablack-primary text-4xl md:text-5xl">
      {{ 'e-mail-confirmed' | sharedTranslate }}
    </h2>
    <mat-card
      class="text-mat-ef-revolution-primary-500 font-efSans text-xl font-light"
    >
      <T
        str="Thank you for verifying your email. You will be redirected to your account where you can change your password."
        key="customerPortal.b2c.forgot-password-verify.text"
        tags="cpt-auth, 3.1"
      ></T>
    </mat-card>
    <div class="next">
      <button
        class="revolution revolution w-full lg:w-1/2 font-bold button-spinner"
        mat-button
        mat-raised-button
        color="secondary"
        type="submit"
        (click)="redirect()"
        [disabled]="submitting"
      >
        <customer-portal-ef-spinner
          [color]="'mat-ef-revolution-grey-two-500'"
          [opacity]="0.7"
          [diameter]="16"
          *ngIf="submitting"
        >
        </customer-portal-ef-spinner>
        <div class="flex-1 flex items-center justify-between">
          <span>
            {{ 'next' | sharedTranslate }}
          </span>
          <mat-icon svgIcon="chevron_right"></mat-icon>
        </div>
      </button>
    </div>
  </div>
</div>
