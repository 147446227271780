<div class="md:hidden bg-white">
  <nav class="flex items-center justify-between w-full mb-1 shadow py-1 px-1">
    <button
      [disabled]="backIsDisabled()"
      mat-button
      (click)="onClickB()"
      class="flex items-center font-normal p-0 text-mat-ef-revolution-secondary-500 disabled:opacity-50"
    >
      <mat-icon svgIcon="chevron_left"></mat-icon>
      {{ 'back' | sharedTranslate }}
    </button>

    <button
      [disabled]="forwardIsDisabled()"
      mat-button
      (click)="onClickF()"
      class="flex items-center font-normal p-0 text-mat-ef-revolution-secondary-500 disabled:opacity-50"
    >
      {{ 'forward' | sharedTranslate }}
      <mat-icon svgIcon="chevron_right"></mat-icon>
    </button>
  </nav>
</div>
