export interface NotificationParameters {
  size: number
  number: number
  search: string
  sort: string
  type: string[]
  status: string[]
}

export interface Language {
  id: number
  name: string
  code: string
}

export interface Role {
  id: number | 'all-roles'
  name: string
  level?: string
}

export interface UserGroup {
  id: number
  name: string
}

export interface RolesGroup {
  disabled?: boolean
  name: string
  roles: Role[]
}
