<div class="flex grow mr-10 py-2.5 px-8 rounded-0" *ngIf="loading">
  <ngx-skeleton-loader
    appearance="line"
    animation="progress"
  ></ngx-skeleton-loader>
  <br />
  <div class="w-5 float-left">
    <div class="mt-5 h-fit">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>
  </div>
  <div class="w-[calc(100%_-_20px)] float-right py-0 px-5 pr-4">
    <div class="mt-5 h-fit">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>
    <div class="panel-heading">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>
    <div class="panel-heading">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>
  </div>
</div>
