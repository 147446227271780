export enum TrainingAdministrationCategory {
  ALL = 'ALL',
  PLANNED = 'PLANNED',
  FINISHED = 'FINISHED'
}

export enum UserTrainingStatus {
  SUBSCRIBED = 'SUBSCRIBED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  FINISHED = 'FINISHED'
}

export enum TrainingAdministrationRoles {
  IMPORTER = 'importer',
  TRAINING_ADVISOR = 'training_advisor'
}
