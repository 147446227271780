import { Injectable } from '@angular/core'
import { JsonApiResponse, JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { BaseService } from './base.service'
import { com } from 'efd-cdb_backend-interfaces-ts'
import { HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { FunctionsTrainingEducationParameters } from '../models/functions-training-education.model'
import { FunctionTrainingEducationTypes } from '../enum/functions-training-education.enum'
import { JsonApiRequest, JsonApiRequestData } from '../models/jsonApiRequest.model'
import { EfDatatableSortDirection } from '../enum/efDatatable.enum'
import { isArray } from 'lodash'

type CdbBrandQualificationDTO = com.mocira.inside.cdb.client.dto.CdbBrandQualificationDTO
type CdbDealerBrandDTO = com.mocira.inside.cdb.client.dto.CdbDealerBrandDTO
type BrandQualificationUserCandidateDTO = com.mocira.inside.cdb.dto.BrandQualificationUserCandidateDTO

@Injectable({
  providedIn: 'root'
})

export class TrainingEducationOverviewService extends BaseService {
  getTrainingEducationOverviewData (
    brand: CdbDealerBrandDTO | CdbDealerBrandDTO[],
    parameters: FunctionsTrainingEducationParameters,
    type: FunctionTrainingEducationTypes
  ): Observable<JsonApiResponseList<CdbBrandQualificationDTO>> {
    const params = this.setParams(parameters, type, brand)

    return this.http.get<JsonApiResponseList<CdbBrandQualificationDTO>>(`${this.apiUrl}/cdb/brand-qualifications`, { params })
  }

  getTrainingEducationDataById (qualificationId: number): Observable<JsonApiResponse<CdbBrandQualificationDTO>> {
    return this.http.get<JsonApiResponse<CdbBrandQualificationDTO>>(`${this.apiUrl}/cdb/brand-qualifications/${qualificationId}`)
  }

  getTrainingEducationAssignedUsers (qualificationId: number, parameters: FunctionsTrainingEducationParameters): Observable<JsonApiResponseList<BrandQualificationUserCandidateDTO>> {
    const params = this.setParams(parameters)

    return this.http.get<JsonApiResponseList<BrandQualificationUserCandidateDTO>>(`${this.apiUrl}/cdb/brand-qualifications/${qualificationId}/users`, { params })
  }

  getTrainingEducationAvailableUsers (qualificationId: number, parameters: FunctionsTrainingEducationParameters): Observable<JsonApiResponseList<BrandQualificationUserCandidateDTO>> {
    const params = this.setParams(parameters)

    return this.http.get<JsonApiResponseList<BrandQualificationUserCandidateDTO>>(`${this.apiUrl}/cdb/brand-qualifications/${qualificationId}/user-candidates`, { params })
  }

  updateTrainingEducationAssignedUsers (data: object[], qualificationId: number): Observable<JsonApiResponse<void>> {
    const wrappedData = { data }
    return this.http.put<JsonApiResponse<void>>(`${this.apiUrl}/cdb/brand-qualifications/${qualificationId}/users`, wrappedData)
  }

  editTrainingFunctionsOverview (data: CdbBrandQualificationDTO): Observable<JsonApiResponseList<CdbBrandQualificationDTO>> {
    return this.http.put<JsonApiResponseList<CdbBrandQualificationDTO>>(
      `${this.apiUrl}/cdb/brand-qualifications`, (new JsonApiRequest(new JsonApiRequestData(data, data.id)))
    )
  }

  createTrainingFunctionsOverview (data: CdbBrandQualificationDTO): Observable<JsonApiResponseList<CdbBrandQualificationDTO>> {
    return this.http.post<JsonApiResponseList<CdbBrandQualificationDTO>>(
      `${this.apiUrl}/cdb/brand-qualifications`, (new JsonApiRequest(new JsonApiRequestData(data)))
    )
  }

  deleteTrainingFunctions (qualificationId: number): Observable<JsonApiResponseList<CdbBrandQualificationDTO>> {
    return this.http.delete<JsonApiResponseList<CdbBrandQualificationDTO>>(
      `${this.apiUrl}/cdb/brand-qualifications/${qualificationId}`
    )
  }

  exportTrainingEducation (brand: CdbDealerBrandDTO | CdbDealerBrandDTO[], parameters: FunctionsTrainingEducationParameters): Observable<Blob> {
    const params = this.setParams(parameters, FunctionTrainingEducationTypes.TRAINING_EDUCATION, brand)

    return this.http.get(`${this.apiUrl}/cdb/brand-qualifications/export`, {
      responseType: 'blob',
      params
    })
  }

  exportEducationUserData (qualificationId: number, parameters?: FunctionsTrainingEducationParameters): Observable<Blob> {
    const params = this.setParams(parameters)
    return this.http.get(`${this.apiUrl}/cdb/brand-qualifications/${qualificationId}/users-export`, { responseType: 'blob', params })
  }

  setParams (parameters: FunctionsTrainingEducationParameters, type?: FunctionTrainingEducationTypes, brand?: CdbDealerBrandDTO | CdbDealerBrandDTO[]): HttpParams {
    let params = new HttpParams()

    if (type) {
      params = params.append('filter[type]', type)
    }

    if (brand) {
      if (isArray(brand)) {
        brand.forEach((brand) => {
          params = params.append('filter[brandId]', brand.id)
        })
      } else {
        params = params.append('filter[brandId]', brand.id)
      }
    }

    if (parameters?.userTrainingStatus) {
      params = params.append('status', parameters.userTrainingStatus)
    }

    if (parameters?.department) {
      params = params.append('filter[departmentId]', parameters?.department?.id)
    }

    if (parameters?.pageNumber) {
      params = params.append('page[number]', parameters.pageNumber)
    }

    if (parameters?.size) {
      params = params.append('page[size]', parameters.size)
    }

    if (parameters?.search) {
      params = params.append('search', parameters.search)
    }

    if (parameters.language) {
      params = params.append('lang', parameters.language)
    }

    if (parameters?.dealerStatus) {
      params = params.append('filter[dealerStatus]', parameters.dealerStatus)
    }

    if (parameters?.mandatory !== undefined && parameters?.mandatory !== null) {
      params = params.append('filter[mandatory]', parameters.mandatory)
    }

    if (parameters.dealerId) {
      params = params.append('dealerId', parameters.dealerId)
    }

    if (parameters?.dateId) {
      params = params.append('dateId', parameters.dateId)
    }

    if (parameters?.planId) {
      params = params.append('planId', parameters.planId)
    }

    if (parameters?.sort) {
      let sortValue = parameters.sort.column
      if (parameters.sort.direction === EfDatatableSortDirection.DESCENDING) {
        sortValue = `-${parameters.sort.column}`
      }
      params = params.append('sort', sortValue)
    }

    if (parameters?.managedBy) {
      params = params.append('filter[managedBy]', parameters.managedBy)
    }

    return params
  }
}
