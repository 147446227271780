export type HttpMethod =
  | 'GET'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'OPTIONS'
  | 'HEAD'
  | 'PATCH'

export interface ExcludedUrlRegex {
  pattern: string
  methods?: HttpMethod[]
}

export interface Environment {
  domain: string
  baseUrl: string
  authorizationExcludedUrls: ExcludedUrlRegex[]
}
