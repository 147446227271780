export interface AuditParameters {
  'size': number
  'number': number
  'search': string
  'sort': string
  'status': string[]
  'area': string[]
  'dealer.id': number[]
  'dealer.name': string[]
  'dealer.city': string[]
  'brand.name': string[]
  'brand.id': number[]
  '[date][GToE]': string[]
  '[date][LToE]': string[]
  'auditStatusTab': string[]
}
