import { Component, Input, OnInit } from '@angular/core'
import { CptSharedDataService } from '../../services/kc-shared-data.service'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { SharedService } from '../../services/shared.service'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import {
  CustomerPortalConfig,
  B2CConfig
} from '@inside-hub-app/customer-portal-config'

@Component({
  selector: 'customer-portal-lib-footer-new',
  templateUrl: './footer-new.component.html',
  styleUrls: ['./footer-new.component.scss']
})
export class FooterNewComponent implements OnInit {
  language: string
  isMobilePwa
  constructor(
    private readonly sharedData: CptSharedDataService,
    private readonly translateService: TranslateService,
    private readonly sharedService: SharedService,
    private readonly remoteConfigService: EfRemoteConfigurationService<
      CustomerPortalConfig | B2CConfig
    >
  ) {
    this.isMobilePwa = this.sharedService.isMobilePwa()
    this.countryName = this.remoteConfigService.get('country.name')
    this.defaultLanguage = this.remoteConfigService.get('b2c.defaultLanguage')
  }

  @Input()
  public title: string

  public logo = 'assets/images/img_logo_white.svg'
  public logoUrl = ''
  public countryName: string
  public defaultLanguage

  ngOnInit(): void {
    this.logoUrl = `${this.sharedData.getResourcesPath()}/${this.logo}`
    this.translateService.onLangChange.subscribe(
      (langChange: LangChangeEvent) => {
        this.language = langChange.lang
      }
    )
  }

  openUrl(): void {
    const url: string = this.remoteConfigService.get('home.url')
    window.open(url, '_blank')
  }

  getImpressumLink(): void {
    const links = {
      de: this.remoteConfigService.get('footerLinks.impressumLinks.de'),
      it: this.remoteConfigService.get('footerLinks.impressumLinks.it'),
      fr: this.remoteConfigService.get('footerLinks.impressumLinks.fr')
    }
    return this.sharedData.getLink(links, this.language, this.defaultLanguage)
  }

  getDataProtectionLink(): void {
    const links = {
      de: this.remoteConfigService.get('footerLinks.dataProtectionLinks.de'),
      it: this.remoteConfigService.get('footerLinks.dataProtectionLinks.it'),
      fr: this.remoteConfigService.get('footerLinks.dataProtectionLinks.fr')
    }
    return this.sharedData.getLink(links, this.language, this.defaultLanguage)
  }
}
