import { Injectable } from '@angular/core'
import { BaseService } from './base.service'
import { type Observable } from 'rxjs'
import { type JsonApiResponse, type JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { type hr, type com } from 'efd-cdb_backend-interfaces-ts'
import { HttpParams } from '@angular/common/http'
import { type CsiParameters } from '../models/customer-satisfaction-index.model'
import { EfDatatableSortDirection } from '../enum/efDatatable.enum'
import moment from 'moment'
import { FormDataTypes } from '../enum/form-data-type.enum'

type CdbCustomerSatisfactionIndexMinDTO = hr.emilfreydigital.cdb.dto.CdbCustomerSatisfactionIndexMinDTO
type CdbBrandCodeDTO = com.mocira.inside.cdb.client.dto.CdbBrandCodeDTO

enum DocumentTypes {
  CSI = 'partner_profile_csi'
}

@Injectable({
  providedIn: 'root'
})

export class CustomerSatisfactionIndexService extends BaseService {
  getCsiData (parameters?: CsiParameters): Observable<JsonApiResponseList<CdbCustomerSatisfactionIndexMinDTO>> {
    const params = this.setParams(parameters)

    return this.http.get<JsonApiResponseList<CdbCustomerSatisfactionIndexMinDTO>>(`${this.apiUrl}/cdb/csi/list`, { params })
  }

  getCsiBrands (): Observable<JsonApiResponseList<CdbBrandCodeDTO>> {
    return this.http.get<JsonApiResponseList<CdbBrandCodeDTO>>(`${this.apiUrl}/cdb/csi/brands`)
  }

  deleteCsiRecord (id: number): Observable<JsonApiResponse<void>> {
    return this.http.delete<JsonApiResponse<void>>(`${this.apiUrl}/cdb/csi/${id}`)
  }

  uploadCsiRecord (record: File): Observable<CdbCustomerSatisfactionIndexMinDTO> {
    const formData = new FormData()

    // const nameWithoutExtension = record.name.split('.')[0]

    formData.append(FormDataTypes.FILE, record)
    formData.append(FormDataTypes.DOCUMENT_TYPE, DocumentTypes.CSI)
    formData.append(FormDataTypes.DOCUMENT_NAME, record.name)
    return this.http.post<CdbCustomerSatisfactionIndexMinDTO>(`${this.apiUrl}/cdb/csi/upload`, formData)
  }

  setParams (parameters: CsiParameters): HttpParams {
    let params = new HttpParams()

    if (parameters?.brandId) {
      if (Array.isArray(parameters.brandId)) {
        parameters.brandId.forEach((brandId) => {
          params = params.append('filter[brandId]', brandId)
        })
      } else {
        params = params.append('filter[brandId]', parameters.brandId)
      }
    }

    if (parameters?.dealerId) {
      params = params.append('filter[dealerId]', parameters.dealerId)
    }

    if (parameters?.documentName) {
      params = params.append('filter[documentName]', parameters.documentName)
    }

    if (parameters?.uploadDateFrom) {
      const isoDateString = moment(parameters?.uploadDateFrom).toISOString(true)
      params = params.append('filter[createdDt][GToE]', isoDateString)
    }

    if (parameters?.uploadDateTo) {
      const isoDateString = moment(parameters?.uploadDateTo).toISOString(true)
      params = params.append('filter[createdDt][LToE]', isoDateString)
    }

    if (parameters?.year) {
      params = params.append('filter[year]', parameters.year)
    }

    if (parameters?.quarter) {
      params = params.append('filter[quarter]', parameters.quarter)
    }

    if (parameters?.pageNumber) {
      params = params.append('page[number]', parameters.pageNumber)
    }

    if (parameters?.size) {
      params = params.append('page[size]', parameters.size)
    }

    if (parameters?.search) {
      params = params.append('search', parameters.search)
    }

    if (parameters?.sort) {
      let sortValue = parameters.sort.column
      if (parameters.sort.direction === EfDatatableSortDirection.DESCENDING) {
        sortValue = `-${parameters.sort.column}`
      }
      params = params.append('sort', sortValue)
    }

    params = params.append('filter[deletedDt][IsNULL]', '')

    return params
  }
}
