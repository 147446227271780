import { Injectable } from '@angular/core'
import { BaseService } from './base.service'
import { Observable } from 'rxjs'
import { PermissionsAdministrationParameters } from '@inside-hub-app/hub-authorization-client'
import { HttpParams } from '@angular/common/http'
import { EfDatatableSortDirection } from '../enum/efDatatable.enum'

@Injectable({
  providedIn: 'root'
})

export class PermissionsAdministrationService extends BaseService {
  exportPermissionKeys (parameters: PermissionsAdministrationParameters): Observable<Blob> {
    const params = this.setParams(parameters)
    return this.http.get(`${this.apiUrl}/cdb/hub/authorization/permissions/export`, { responseType: 'blob', params })
  }

  setParams (parameters: PermissionsAdministrationParameters): HttpParams {
    let params = new HttpParams()

    const { moduleId, search, language, pageNumber, pageSize, sort } = parameters

    if (moduleId) params = params.append('moduleId', moduleId)
    if (search) params = params.append('search', search)
    if (language) params = params.append('language', language)
    if (pageNumber) params = params.append('pageNumber', pageNumber.toString())
    if (pageSize) params = params.append('pageSize', pageSize.toString())

    if (sort) {
      const sortValue = sort.direction === EfDatatableSortDirection.DESCENDING
        ? `-${sort.column}`
        : sort.column
      params = params.append('sort', sortValue)
    }

    return params
  }
}
