import { type hr } from 'efd-cdb_backend-interfaces-ts'

export type AuditListDto = hr.emilfreydigital.cdb.audit.api.rest.v1.dto.AuditListDto

export type ChangelogParameters = {
  action?: string
  target?: number
  crud?: string
  pageSize?: number
  nextPageCursor?: string
  prevPageCursor?: string
}
