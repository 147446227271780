import { Injectable } from '@angular/core'
import { LegacyErrorStateMatcher as ErrorStateMatcher } from '@angular/material/legacy-core'
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms'

@Injectable({
  providedIn: 'root'
})
export class InstantErrorStateMatcherService implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form?.submitted === true
    return !!(
      control?.invalid === true &&
      (control.dirty || control.touched || isSubmitted)
    )
  }
}
