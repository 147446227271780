import { hr, com } from 'efd-cdb_backend-interfaces-ts'

export type CdbInspectionTemplateRequestDTO = hr.emilfreydigital.cdb.dto.CdbInspectionTemplateRequestDTO
export type CdbDealerBrandSmDTO = com.mocira.inside.cdb.client.dto.CdbDealerBrandSmDTO

export interface InspectionList {
  id: number
  templates: InspectionTemplate[]
}

export interface InspectionTemplate {
  id: number
  name: string
  timeForPlanning: number
  orderType: string
  allBrands: boolean
  brands: any[]
  inspectionCategories: InspectionCategory[]
  translations: { [key: string]: string }
}

export interface InspectionCategory {
  id: number
  name: string
  inspectionCategoryChecks: InspectionCategoryCheck[]
  userRole: string
  translations: { [key: string]: string }
}

export interface InspectionCategoryCheck {
  id: number
  name: string
  defaultSelection: boolean
  translations: { [key: string]: string }
}

export interface InspectionTemplateRemove {
  dataItem: {
    additionalProperties: {}
    attributes: InspectionTemplate
    id: number
    meta: {}
    type: string
  }
  errors: null
  meta: null
  relationships: null
}

export interface TradeInViewDialogData {
  timeForPlanning: number
  allBrands: boolean
  brandIds: number[]
  languages: { lang: string; title: string }[]
  templateId: number
}

export interface TradeInCheckDialogData {
  languages: { lang: string; title: string }[]
  defaultSelection: boolean
  categoryCheckId: number
}

export interface TradeInCategoryDialogData {
  languages: {
    lang: string
    title: string
  }[]
  userRole: 'SALESMAN' | 'TECHNICIAN'
  categoryId: number
}
