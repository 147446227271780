import { Injectable } from '@angular/core'
import { HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import {
  JsonApiResponse,
  JsonApiResponseList
} from '@inside-hub-app/ef-ng-json-api'
import { com } from 'efd-cdb_backend-interfaces-ts'
import {
  JsonApiRequest,
  JsonApiRequestData
} from '../models/jsonApiRequest.model'
import { BaseService } from './base.service'
import { PostZoneAdministrationObject } from '../models/brand-zone.model'

type CdbBrandZone = com.mocira.inside.cdb.client.dto.CdbBrandZoneDTO

@Injectable({
  providedIn: 'root'
})
export class BrandZoneService extends BaseService {
  getZoneById (id: number): Observable<JsonApiResponse<CdbBrandZone>> {
    return this.http.get<JsonApiResponse<CdbBrandZone>>(
      `${this.apiUrl}/cdb/brand_zone/${id}`
    )
  }

  getZones (id?: number, pageNumber?: number, pageSize?: number, area?: string): Observable<JsonApiResponseList<CdbBrandZone>> {
    let params = new HttpParams()
    // Default sort parameter
    params = params.append('sort', String('name'))
    // Optional parameters
    if (id) params = params.append('filter[brand.id]', String(id))
    if (pageNumber) params = params.append('page[number]', String(pageNumber))
    if (pageSize) params = params.append('page[size]', String(pageSize))
    if (area) params = params.append('filter[area]', area)

    return this.http.get<JsonApiResponseList<CdbBrandZone>>(
      `${this.apiUrl}/cdb/brand_zone/list`,
      { params }
    )
  }

  saveZone (zone: Partial<PostZoneAdministrationObject>): Observable<JsonApiResponse<CdbBrandZone>> {
    const requestData = new JsonApiRequest(new JsonApiRequestData(zone))
    return this.http.post<JsonApiResponse<CdbBrandZone>>(
      `${this.apiUrl}/cdb/brand_zone`,
      requestData
    )
  }

  updateZone (zone: Partial<PostZoneAdministrationObject>): Observable<JsonApiResponse<CdbBrandZone>> {
    const requestData = new JsonApiRequest(
      new JsonApiRequestData(zone, zone.id)
    )
    return this.http.put<JsonApiResponse<CdbBrandZone>>(
      `${this.apiUrl}/cdb/brand_zone`,
      requestData
    )
  }

  deleteZone (id: number, force?: boolean): Observable<void> {
    const config = { params: { force } }
    return this.http.delete<void>(`${this.apiUrl}/cdb/brand_zone/${id}`, config)
  }
}
