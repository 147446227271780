<div class="p-4 lg:p-10 flex-1 flex flex-col">
  <div class="welcome flex flex-col gap-6 py-6">
    <div class="font-efSans-secondary font-semibold text-sm uppercase">
      {{ 'customer-portal' | sharedTranslate }}
    </div>
    <div class="font-canelablack-primary text-4xl md:text-5xl">
      <T
        str="Account created!"
        key="customerPortal.b2c.account-created.title"
        tags="cpt-auth, 3.1"
      ></T>
    </div>
  </div>
  <div class="grid gap-6">
    <mat-card class="card-flex-rounded flex flex-row gap-6">
      <mat-icon
        class="card-flex-rounded-icon"
        svgIcon="check_circle"
      ></mat-icon>
      <p class="font-efSans text-mat-ef-revolution-primary-500">
        <T
          str="Thank you for signing up with Emil Frey. You will be redirected to your account where you can see and manage your details and preferences."
          key="customerPortal.b2c.account-created.text"
          tags="cpt-auth, 3.1"
        ></T>
      </p>
    </mat-card>
  </div>
</div>
