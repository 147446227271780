import { Injectable } from '@angular/core'
import { BaseService } from './base.service'
import { JsonApiResponse, JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { com } from 'efd-cdb_backend-interfaces-ts'
import { Observable } from 'rxjs'
import { HttpHeaders, HttpParams } from '@angular/common/http'
import _ from 'lodash'
import { AuditParameters } from '../models/audit.model'

type CdbAuditTaskTypeDTO = com.mocira.inside.cdb.client.dto.CdbAuditTaskTypeDTO
type CdbAuditDTO = com.mocira.inside.cdb.rest.dto.CdbAuditDTO

@Injectable({
  providedIn: 'root'
})

export class AuditService extends BaseService {
  getVisits (parameters, type: string) {
    let config

    parameters['filter[type]'] = type

    if (parameters) {
      config = {
        params: parameters
      }
    }

    return this.http.get(`${this.apiUrl}/cdb/audit/list`, config)
  }

  getTaskTypeList (id, type: string, onlyMandatory: boolean, params: any): Observable<JsonApiResponseList<CdbAuditTaskTypeDTO>> {
    const config = {
      params: {
        'filter[brand.id]': id,
        'filter[type]': type
      }
    }

    if (params?.['department']) {
      config.params['filter[department]'] = params['department']
    }
    if (params?.['sort']) {
      config.params['sort'] = params['sort']
    }
    if (params?.status?.value) {
      config.params['filter[dealerStatus]'] = params['status']['value']
    }
    if (params?.['pageNumber']) {
      config.params['page[number]'] = params['pageNumber']
    }
    if (params?.['size']) {
      config.params['page[size]'] = params['size']
    }

    if (params?.search) {
      config.params['search'] = params?.search
    }

    if (onlyMandatory !== undefined && onlyMandatory !== null) {
      config.params['filter[mandatory]'] = onlyMandatory
    }

    if (params?.['maxValues']) {
      config.params['meta[orderNumberSales][MAX]'] = ''
      config.params['meta[orderNumberAftersales][MAX]'] = ''
    }

    return this.http.get<JsonApiResponseList<CdbAuditTaskTypeDTO>>(`${this.apiUrl}/cdb/audit/task_type/list`, config)
  }

  updateTasksOrder (tasksList) {
    return this.http.put(`${this.apiUrl}/cdb/audit/task_type/list`, tasksList)
  }

  createNewTask (data) {
    return this.http.post(`${this.apiUrl}/cdb/audit/task_type`, data)
  }

  editTask (data) {
    return this.http.put(`${this.apiUrl}/cdb/audit/task_type`, data)
  }

  deleteTask (id: number) {
    return this.http.delete(`${this.apiUrl}/cdb/audit/task_type/${id}`)
  }

  usedTasksList (id: number) {
    return this.http.get(`${this.apiUrl}/cdb/audit/task_type/${id}/used`)
  }

  createVisit (data) {
    return this.http.post(`${this.apiUrl}/cdb/audit`, data)
  }

  editVisit (data) {
    return this.http.put(`${this.apiUrl}/cdb/audit`, data)
  }

  deleteVisit (id: number) {
    return this.http.delete(`${this.apiUrl}/cdb/audit/${id}`)
  }

  changeStatus (id, status) {
    const config = {
      params: {
        status
      }
    }

    return this.http.put(`${this.apiUrl}/cdb/audit/status/${id}?status=${status}`, config)
  }

  sendConfirmationMail (id: number) {
    return this.http.get(`${this.apiUrl}/cdb/audit/confirmation_email/${id}`)
  }

  getVisitReport (id, lang?: string) {
    return this.http.get(`${this.apiUrl}/cdb/audit_report/brand/${id}?lang=${lang}`)
  }

  createVisitReport (data) {
    return this.http.post(`${this.apiUrl}/cdb/audit_report`, data)
  }

  editVisitReport (data) {
    return this.http.put(`${this.apiUrl}/cdb/audit_report`, data)
  }

  reportGeneration (id: number) {
    return this.http.get(`${this.apiUrl}/cdb/audit_report/print/${id}`, { responseType: 'blob' })
  }

  reportPreview (id: number) {
    const headers: HttpHeaders = new HttpHeaders({ Accept: 'text/html' })

    return this.http.get(`${this.apiUrl}/cdb/audit_report/preview/${id}`, { headers, responseType: 'text' })
  }

  getAllVisitTasks (parameters) {
    const config = {
      params: parameters
    }

    return this.http.get(`${this.apiUrl}/cdb/audit/audit_task`, config)
  }

  getSingleTask (taskId): Observable<any> {
    const config = {
      params: {
        'filter[id]': taskId
      }
    }

    return this.http.get<any>(`${this.apiUrl}/cdb/audit/audit_task`, config)
  }

  updateTaskStatus (data): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/cdb/audit/audit_task/status`, data)
  }

  getLatestAudit (dealerBrandId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/cdb/audit/dealer-brands/${dealerBrandId}/latest`)
  }

  saveExternalAudits (dealerBrandId: number, data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/cdb/audit/dealer-brands/${dealerBrandId}/external`, data)
  }

  previewEmail (visitId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/cdb/audit/${visitId}/invitation-templates`)
  }

  saveEmail (visitId: number, template: object): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/cdb/audit/${visitId}/invitation-templates`, template)
  }

  getVisitReports (params: Partial<AuditParameters>): Observable<JsonApiResponse<CdbAuditDTO>> {
    const parameters = this.setParams(params)

    return this.http.get<JsonApiResponse<CdbAuditDTO>>(
      `${this.apiUrl}/cdb/audit/list`, { params: parameters }
    )
  }

  setParams (params: Partial<AuditParameters>): HttpParams {
    let parameters = new HttpParams()
    const distinctValuesParams = [
      'dealer.city',
      'brand.name',
      'status',
      'date',
      'area'
    ]
    Object.keys(params).forEach(key => {
      if (params[key] !== null) {
        switch (key) {
          case 'size':
            parameters = parameters.append('page[size]', params.size)
            break
          case 'number':
            parameters = parameters.append('page[number]', params.number)
            break
          case 'search':
            parameters = parameters.append('search', params.search)
            break
          case 'sort':
            parameters = parameters.append('sort', params.sort)
            break
          case 'auditArea':
            params[key].forEach(value => {
              parameters = parameters.append('filter[area]', value)
            })
            break
          case 'auditDate':
            params[key].forEach((value: number) => {
              const dateFrom = `${value}-01-01T00:00:00.000Z`
              parameters = parameters.append('filter[date][GToE]', dateFrom)
              const dateTo = `${value}-12-31T23:59:59.999Z`
              parameters = parameters.append('filter[date][LToE]', dateTo)
            })
            break
          case 'auditStatus':
            params[key].forEach(value => {
              parameters = parameters.append('filter[status]', value)
            })
            break
          case 'dealerCity':
            params[key].forEach(value => {
              parameters = parameters.append('filter[dealer.city]', value)
            })
            break
          case 'brandName':
            params[key].forEach(value => {
              parameters = parameters.append('filter[brand.name]', value)
            })
            break
          default:
            params[key].forEach(value => {
              parameters = parameters.append(`filter[${key}]`, value)
            })
            break
        }
      }
    })

    _.forEach(distinctValuesParams, value => {
      parameters = parameters.append(`distinct[${value}]`, '')
    })

    return parameters
  }
}
