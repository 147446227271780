import { Injectable } from '@angular/core'
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { StorageService } from '@inside-hub-app/hub-storage'
import { PpStorageEnum, PpStorageKeysEnum } from '../enum/storage.enum'
import { map, tap, mergeMap } from 'rxjs/operators'

@Injectable()

export class PpDealerIdInterceptor implements HttpInterceptor {
  constructor (
    private readonly storage: StorageService
  ) {}

  intercept (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.getCurrentDealer().pipe(
      mergeMap(currentDealer => {
        if (!currentDealer) {
          return next.handle(request)
        }
        const id = request.headers?.get('X-Dealer-Id')
        const cloneRequest = request.clone({
          setHeaders: {
            'X-Dealer-Id': id ? id : this.getCurrentDealerId(currentDealer)
          }
        })

        return next.handle(cloneRequest)
      })
    )
  }

  getCurrentDealer (): Observable<string> {
    return this.getCurrentDealerFromStorage().pipe(
      tap(currentDealer => {
        return currentDealer
      })
    )
  }

  getCurrentDealerFromStorage (): Observable<string> {
    return this.storage.get<string>(`${PpStorageEnum.PREFIX}${PpStorageKeysEnum.CURRENT_DEALER}`)
      .pipe(map(currentDealer => currentDealer))
  }

  getCurrentDealerId (currentDealer: string): string {
    return currentDealer.split('-')[1]
  }
}
