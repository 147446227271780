import { Injectable } from '@angular/core'
import { KeycloakService, KeycloakOptions, KeycloakEvent, KeycloakEventType } from '@emilfreydigital/keycloak-angular'
import { HubConfigurationService } from '@inside-hub-app/hub-configuration'
import { NGXLogger } from 'ngx-logger'
import { Subscription, BehaviorSubject } from 'rxjs'
import { ImpersonateUserService } from './impersonate-user.service'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})

export class KcInitService {
  kcReady = new BehaviorSubject<boolean>(null)
  kcReady$ = this.kcReady.asObservable()

  constructor (
    private readonly service: KeycloakService,
    private readonly hubConfigurationService: HubConfigurationService,
    private readonly logger: NGXLogger,
    private readonly impersonateService: ImpersonateUserService,
    private readonly router: Router
  ) {}

  kcInit (redirectUri?: string): void {
    const subscription: Subscription = this.service.keycloakEvents$
      .subscribe((events: KeycloakEvent) => {
        if (events.type === KeycloakEventType.OnReady) {
          subscription.unsubscribe()
          this.kcReady.next(true)
        }
      })

    if (window['cordova']) {
      document.addEventListener('deviceready', () => {
        this.initialize()
      }, false)
    } else {
      this.initialize(redirectUri)
    }
  }

  initialize (redirectUri?: string): void {
    let clientId = this.hubConfigurationService.get<string>('hub.auth.keycloak.client-id')

    if (window.location.host.includes('localhost')) {
      clientId = this.hubConfigurationService.get<string>('hub.auth.keycloak.client-id-localhost') ?? clientId
    }

    // Setup KC configuration
    const kcConfiguration: KeycloakOptions = {
      config: {
        url: this.hubConfigurationService.get<string>('hub.auth.keycloak.url'),
        realm: this.hubConfigurationService.get<string>('hub.auth.keycloak.realm'),
        clientId
      },
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false
      },
      enableBearerInterceptor: false,
      bearerPrefix: ''
    }

    if (window['cordova']) {
      kcConfiguration.initOptions['adapter'] = 'cordova'
      kcConfiguration.initOptions['responseMode'] = 'query'
      kcConfiguration.initOptions['redirectUri'] = 'ionic://localhost/login'
    }

    // Set redirect_uri
    if (redirectUri) {
      kcConfiguration.initOptions['redirectUri'] = redirectUri
    }

    this.impersonateService.isUserImpersonated().subscribe((isImpersonated: boolean) => {
      if (isImpersonated) {
        kcConfiguration.config['clientId'] = 'impersonate-client'
        kcConfiguration.initOptions.onLoad = 'check-sso'
        if (window.location.host.includes('localhost')) {
          kcConfiguration.initOptions.silentCheckSsoRedirectUri = window.location.origin + '/assets/silent-check-sso.html'
        } else {
          kcConfiguration.initOptions.silentCheckSsoRedirectUri = window.location.origin + '/app/assets/silent-check-sso.html'
        }
        kcConfiguration.initOptions.checkLoginIframe = true
      }

      // Initialize Keycloak
      this.service.init(kcConfiguration)
        .then(() => {
          this.logger.info('Keycloak initialozed - user authenticated')
        })
        .catch((error) => {
          this.logger.error(error)
        })
    })
  }
}
