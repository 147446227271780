import { Injectable } from '@angular/core'
import { BaseService } from './base.service'
import { Parameters, DealerPermissionUpdate, UserPermissionUpdate, DealerPermission, UserPermission, Tenant } from '../models/efit-permissions.model'
import { Observable } from 'rxjs'

export interface ResponseList {
  data: DealerPermission[] | UserPermission[]
  page: number
  pages: number
  size: number
  total: number
}

@Injectable({
  providedIn: 'root'
})

export class EfitPermissionManagementService extends BaseService {
  getTenants (entityId: number, importerIdQp?: number): Observable<Tenant[]> {
    return this.http.get<Tenant[]>(`${this.apiPpServiceUrl}dealers/${entityId}/tenant-identifiers${importerIdQp ? '?importerId=' + importerIdQp : ''}`)
  }

  getDealerPermissions (dealerId: number, tenantId: string, parameters: Parameters): Observable<ResponseList> {
    const params = {
      params: {
        tenantId,
        page: parameters.page,
        size: parameters.size
      }
    }

    if (parameters.search) params.params['search'] = parameters.search

    return this.http.get<ResponseList>(`${this.apiPpServiceUrl}dealer-permissions/dealer/${dealerId}`, params)
  }

  getUserPermissions (userId: number, parameters: Parameters, dealerId?: number, tenantId?: string,): Observable<ResponseList> {
    let params = {
      params: {
        page: parameters.page,
        size: parameters.size
      }
    }

    if (dealerId) params.params['dealerId'] = dealerId
    if (tenantId) params.params['tenantId'] = tenantId
    if (parameters.search) params.params['search'] = parameters.search
    if (parameters.all) params.params['all'] = parameters.all

    return this.http.get<ResponseList>(`${this.apiPpServiceUrl}users/${userId}/permissions`, params)
  }

  saveDealerPermission (data: DealerPermissionUpdate): Observable<ResponseList> {
    return this.http.post<ResponseList>(`${this.apiPpServiceUrl}dealer-permissions`, data)
  }

  saveUserPermission (data: UserPermissionUpdate): Observable<ResponseList> {
    return this.http.post<ResponseList>(`${this.apiPpServiceUrl}user-permissions`, data)
  }

  deleteDealerPermission (dealerPermissionId: number, force?: boolean): Observable<any> {
    const params = { params: {} }

    if (force) params.params['force'] = force

    return this.http.delete(`${this.apiPpServiceUrl}dealer-permissions/${dealerPermissionId}`, params)
  }

  deleteUserPermission (userPermissionId: number): Observable<any> {
    return this.http.delete(`${this.apiPpServiceUrl}user-permissions/${userPermissionId}`)
  }
}
