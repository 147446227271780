import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { B2CConfig } from '@inside-hub-app/customer-portal-config'

import { AppModule } from './app/app.module'

const remoteConfig = (window as any).ef.remoteConfig.configuration as B2CConfig

if (remoteConfig.production) {
  enableProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err))

// insert analytics script

const script = document.createElement('script')
script.src =
  'https://www.googletagmanager.com/gtag/js?id=' +
  remoteConfig['googleAnalytics.id']
script.async = true
document.head.prepend(script)
