import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { JsonApiResponse } from '@inside-hub-app/ef-ng-json-api'
import { filter, map, Observable } from 'rxjs'
import {
  JsonApiRequest,
  JsonApiRequestData
} from '../models/jsonApiRequest.model'
import {
  CdbRepairDTO,
  CdbRepairListDTO,
  CdbRepairPackageItemDTO,
  CdbRepairRequestDTO,
  WorkPackageData
} from '../models/aftersales-services.model'
import { BaseService } from './base.service'

@Injectable({
  providedIn: 'root'
})
export class AftersalesServices extends BaseService {
  getRepairList (
    serviceBrandId?: number,
    additionalServiceBrandId?: number
  ): Observable<CdbRepairListDTO> {
    let params = new HttpParams()
    if (serviceBrandId) {
      params = params.append('serviceBrandId', serviceBrandId)
    }
    if (additionalServiceBrandId) {
      params = params.append(
        'additionalServiceBrandId',
        additionalServiceBrandId
      )
    }

    return this.http
      .get<JsonApiResponse<CdbRepairListDTO>>(`${this.apiUrl}/cdb/repairs`, {
        ...this.headers,
        params
      })
      .pipe(map(res => res?.['data']?.attributes))
  }

  getRepairItem (id: number): Observable<CdbRepairDTO> {
    return this.http
      .get<JsonApiResponse<CdbRepairDTO>>(`${this.apiUrl}/cdb/repairs/${id}`)
      .pipe(map(res => res?.data?.attributes))
  }

  deleteRepairItem (id: number): Observable<null> {
    return this.http.delete<null>(`${this.apiUrl}/cdb/repairs/${id}`)
  }

  deleteWorkPackage (
    repairPackageId: number,
    repairId: number
  ): Observable<CdbRepairDTO> {
    return this.http.delete<CdbRepairDTO>(
      `${this.apiUrl}/cdb/repairs/${repairId}/package/${repairPackageId}`
    )
  }

  toggleRepairServiceState (
    id: number,
    status: boolean
  ): Observable<CdbRepairDTO> {
    return this.http
      .put<JsonApiResponse<CdbRepairDTO>>(
        `${this.apiUrl}/cdb/repairs/${id}?enabled=${status}`,
        null
      )
      .pipe(map(res => res?.data?.attributes))
  }

  patchRepairItem (data: CdbRepairRequestDTO): Observable<CdbRepairDTO> {
    const requestData = new JsonApiRequest(new JsonApiRequestData(data))

    return this.http
      .put<JsonApiResponse<CdbRepairDTO>>(
        `${this.apiUrl}/cdb/repairs`,
        requestData
      )
      .pipe(map(res => res?.data?.attributes))
  }

  createAndUpdateService (data: CdbRepairRequestDTO): Observable<CdbRepairDTO> {
    const requestData = new JsonApiRequest(new JsonApiRequestData(data))

    return this.http
      .put<JsonApiResponse<CdbRepairDTO>>(
        `${this.apiUrl}/cdb/repairs`,
        requestData
      )
      .pipe(
        filter(res => !!res?.data?.attributes),
        map(res => res?.data?.attributes)
      )
  }

  createAndUpdateWorkPackage ({
    repairId,
    translations,
    packageId
  }: WorkPackageData): Observable<CdbRepairDTO> {
    const requestData = new JsonApiRequest(
      new JsonApiRequestData({ id: packageId, translations })
    )

    return this.http
      .put<JsonApiResponse<CdbRepairDTO>>(
        `${this.apiUrl}/cdb/repairs/${repairId}/package`,
        requestData
      )
      .pipe(map(res => res?.data?.attributes))
  }

  createOrUpdateRepairPackageItem (
    repairPackageId: number,
    repairPackageItem: CdbRepairPackageItemDTO,
    repairId: number
  ): Observable<CdbRepairDTO> {
    const requestData = new JsonApiRequest(
      new JsonApiRequestData(repairPackageItem)
    )

    return this.http
      .put<JsonApiResponse<CdbRepairDTO>>(
        `${this.apiUrl}/cdb/repairs/${repairId}/package/${repairPackageId}/item`,
        requestData
      )
      .pipe(map(res => res?.data?.attributes))
  }

  deleteRepairPackageItem (
    repairPackageId: number,
    repairPackageItemId: number,
    repairId: number
  ): Observable<CdbRepairDTO> {
    return this.http
      .delete<CdbRepairDTO>(
        `${this.apiUrl}/cdb/repairs/${repairId}/package/${repairPackageId}/item/${repairPackageItemId}`
      )
      .pipe(filter(res => !!res))
  }
}
