import { Component, type OnDestroy, type OnInit } from '@angular/core'
import { UserService } from '@inside-hub-app/customer-portal-b2c-client'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'customer-portal-app-change-email-verify-view',
  templateUrl: './change-email-verify-view.component.html'
})
export class ChangeEmailVerifyViewComponent implements OnInit, OnDestroy {
  public langLoaded = false
  pendingEmail
  public submitting: boolean
  queryParamsSub
  translatedText: string

  sub = {
    onTransifexLanguageChange: null
  }

  autoredirect = true // this can be extracted to remote config
  constructor (
    private readonly userService: UserService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translate: TranslateService
  ) {}

  ngOnInit (): void {
    this.queryParamsSub = this.route.queryParams.subscribe(params => {
      this.translate.use(params.lang).subscribe(() => {
        this.langLoaded = true
      })
      if (this.autoredirect) {
        this.verify()
      }
    })
  }

  ngOnDestroy (): void {
    try {
      this.queryParamsSub.unsubscribe()
    } catch (error) {
      // no need to show log
    }
  }

  verify (): void {
    this.submitting = true
    this.userService
      .verifyEmailChange(this.route.snapshot.params.verificationId)
      .subscribe(
        res => {
          window.location.href = res.redirectUrl
        },
        errorResponse => {
          this.submitting = false
          window.history.state.code = errorResponse?.error?.code
          window.history.state.message = errorResponse?.error?.message
          this.router.navigate(['error'], { skipLocationChange: true })
        }
      )
  }
}
