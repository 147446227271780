import { type EfDatatableSortEvent } from './efDatatable.model'

export type CertificateParameters = {
  search?: string
  pageNumber?: number
  pageSize?: number
  brandIds?: number[]
  brandId?: number
  sort?: EfDatatableSortEvent
}

export type CertificateCandidatePostParams = {
  certificateId: number
  validTill: string
}

export type CertificateCandidatePutParams = {
  id: number
  validTill: string
}
