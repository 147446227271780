import type { com } from 'efd-cdb_backend-interfaces-ts'
import type { UserTrainingStatus, TrainingAdministrationRoles } from '../enum/training-administration.enum'
import type { EfDatatableSortEvent } from './efDatatable.model'

type CdbDepartmentDTO = com.mocira.inside.cdb.client.dto.CdbDepartmentDTO

export interface FunctionsTrainingEducationParameters {
  department?: CdbDepartmentDTO
  language?: string
  pageNumber?: number
  size?: number
  sort?: EfDatatableSortEvent
  search?: string
  minEmployees?: BrandQualificationsEmployeesFilter
  mandatory?: boolean
  dealerStatus?: string
  dealerId?: number
  dateId?: number
  userTrainingStatus?: UserTrainingStatus
  planId?: number
  managedBy?: TrainingAdministrationRoles
}

export interface BrandQualificationsEmployeesFilter {
  mandatory: boolean
  dealerStatus: string
}
