import { Component, OnInit } from '@angular/core'
import { CptSharedDataService } from '../../../services/kc-shared-data.service'

@Component({
  selector: 'customer-portal-app-revolution-hero',
  templateUrl: './revolution-hero.component.html'
})
export class RevolutionHeroComponent implements OnInit {
  public backgroundAsset = 'assets/images/EF_Revolution_Hero_Landingpage.jpg'
  backgroundUrl: string

  constructor(private readonly sharedData: CptSharedDataService) {}

  ngOnInit(): void {
    this.backgroundUrl = `url('${this.sharedData.getResourcesPath()}/${
      this.backgroundAsset
    }')`
  }
}
