import { com } from 'efd-cdb_backend-interfaces-ts'

type CdbUserMinDTO = com.mocira.inside.cdb.client.dto.CdbUserMinDTO
type CdbOffersAndContractsDTO = com.mocira.inside.cdb.client.dto.CdbOffersAndContractsDTO
export interface DealerEmployeesParams {
  brandId?: number | number[]
  dealerId?: number
  pageNumber?: number
  size?: number
}

export interface AdminDealerFilter {
  brands?: string[]
  groupId?: number[]
  enabled?: boolean
  relationType?: any
  relationPrimaryDealerId?: number
  zip?: string
  city?: string
  type?: string
  ef?: boolean
}

export enum DealerSettingsCard {
  WORKING_HOURS = 'working-hours',
  PAYMENT_METHODS = 'payment-methods',
  OUT_OF_OFFICE_VISIBILITY = 'out-off-office-visibility',
  OFFERS_AND_CONTRACTS = 'offers-and-contracts',
  WEB_VISIBILITY_EF = 'web-visibility-ef',
  SAME_AS_MAIN_LOCATION = 'as-main-location',
  TEMPLATE_SETTINGS = 'template-options'
}

export interface DealerSettingsModel {
  workingHours?: number
  methods?: PaymentMethodModel[]
  outOfOfficeWebVisibility?: boolean
  offersAndContracts?: CdbOffersAndContractsDTO
  webVisibilityEf?: boolean
  mainLocationSettings?: boolean
  webTitleManager?: CdbUserMinDTO
  printTemplatesInColor: boolean
  printTemplatesColorValue: string
  useNewSalesTemplates: boolean
}

export interface PaymentDetailsModel {
  id: number
  methods: PaymentMethodModel[]
  name: string
}

export interface PaymentMethodModel {
  code?: string
  en?: string
  de?: string
  it?: string
  fr?: string
}

export interface PaymentMethodModalData {
  paymentMethod?: PaymentMethodModel
  delete?: boolean
  primaryLanguage?: string
}

export interface DealerDMS {
  dmsType: string
  dmsOther: string
  insideVersion: string
}

export enum InsideResponseStatus {
  OK = 'OK',
  ERROR = 'ERROR'
}
export interface InsideResponse<T> {
  status: InsideResponseStatus
  response: boolean
  message?: string[]
  data: T[]
}

export interface CertificateInfrastructureParameters {
  size: number
  number: number
  search: string
  sort: string
  brandId: number | number[]
  lang: string
}

export interface CertificateInfrastructureLanguage {
  id: number
  name: string
  code: string
}
